import { SymptomService } from "./../../services/symptom.service";
import { UserSymptom } from "./../../models/user-symptom.model";
import { Component, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";
import { UserService } from "src/app/auth/service/user.service";
import { DateHelperService } from "src/app/services/date-helper.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-symptoms",
  templateUrl: "./symptoms.component.html",
  styleUrls: ["./symptoms.component.scss"],
})
export class SymptomsComponent implements OnInit {
  public isLoading = false;
  public userSymptoms!: UserSymptom[];
  public normalSymptoms!: UserSymptom | undefined;

  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public today = this.dateHelperService.getToday();
  public entryMonths:string[] = [];
  currentLang = 'en';

  constructor(
    private symptomService: SymptomService,
    private userService: UserService,
    private dateHelperService: DateHelperService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.isLoading = true;
    forkJoin({
      allSymptoms: this.symptomService.fetchAll(),
      normalSymptoms: this.symptomService.fetchNormal(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.userSymptoms = responseData.allSymptoms;
        this.normalSymptoms = responseData.normalSymptoms;
        this.entryMonths = this.symptomService.getMonths(this.userSymptoms);
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }
}
