import { Goal } from "./../models/goal.model";
import { map, catchError, publishReplay, refCount } from "rxjs/operators";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { DateHelperService } from "./date-helper.service";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class GoalService {
  error = new Subject<string>();
  slug = "/goals";
  resourceName = "goals";
  allGoals?: Observable<Goal[]> | null;
  areaGoals?: any;
  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchArea(area: string): Observable<Goal[]> {
    if (!this.areaGoals) {
      this.areaGoals = {};
    }
    if (!this.areaGoals[area]) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append("area", area.toString());

      this.areaGoals[area] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: Goal[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.areaGoals[area];
  }

  fetchAll(): Observable<Goal[]> {
    if (!this.allGoals) {
      this.allGoals = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: Goal[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allGoals;
  }

  clearCache() {
    this.allGoals = null;
    this.areaGoals = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + "/" + id, {
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          const target = new Goal(
            +responseData.goal_id,
            +responseData.user_id,
            responseData.area,
            +responseData.status,
            responseData.goal,
            responseData.created,
            responseData.modified
          );
          return target;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(area: string, status: number, goal: string) {
    this.clearCache();
    const payload = {
      area,
      status,
      goal,
    };
    return this.http.post<Goal>(environment.apiUrl + this.slug, payload, {
      observe: "response",
    });
  }

  update(id: number, area: string, status: number, goal: string) {
    this.clearCache();
    const payload = {
      area,
      status,
      goal,
    };
    return this.http.patch<Goal>(
      environment.apiUrl + this.slug + "/" + id,
      payload,
      {
        observe: "response",
      }
    );
  }

  delete(id: number) {
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + "/" + id
    );
  }

  getWeeklyStatus(goals: Goal[]) {
    let status: any = {
      walking: 0,
      strength: 0,
      yoga: 0,
    };
    let currentWeek = this.dateHelperService.getCurrentWeek();

    goals.forEach((goal: Goal) => {
      if (
        (moment(goal.created) >= moment(currentWeek[0]) &&
          moment(goal.created) <= moment(currentWeek[6])) ||
        +goal.status === 0
      ) {
        if (status[goal.area] < 1) {
          status[goal.area] = 1;
        }
      }
      if (
        moment(goal.modified) >= moment(currentWeek[0]) &&
        moment(goal.modified) <= moment(currentWeek[6])
      ) {
        if (status[goal.area] < 2) {
          status[goal.area] = 2;
        }
      }
    });

    return status;
  }

  getQuickestGoal(goals: Goal[]) {
    let quickest = -1;
    goals.forEach((goal) => {
      // compare days
      if (goal.status == 1) {
        let dayDiff = moment(goal.modified).diff(moment(goal.created), "days");
        if (quickest === -1 || dayDiff < quickest) {
          quickest = dayDiff;
        }
      }
    });
    return quickest;
  }
}
