import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit {

  languages = environment.languages;
  constructor(public translate:TranslateService) { }

  ngOnInit(): void {
  }

  onChangeLanguages(lang:string){

    this.translate.use(lang);
  }

}
