<div class="fh bg-light content-container">
  <app-page-header
    [title]="'Yoga Workouts' | uppercase | translate"
    [icon]="'yoga'"
    [backLink]="['/yoga']"
  ></app-page-header>

  <div class="row">
    <div class="col-md-6">
      <div class="card bg-primary mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
            </span>
            {{ "Target" | uppercase | translate }}
          </h2>

          <p class="p-0 m-0">
            {{
              "Do at least X yoga workouts this week"
                | uppercase
                | translate: { days: weeklyTarget.days } | convertDigits
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card bg-primary-accent mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
            </span>
            {{ "Summary" | uppercase | translate }}
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
            <tabset [justified]="true">
              <tab>
                <ng-template tabHeading>
                  {{ "This week" | uppercase | translate }}</ng-template
                >
                <div class="entry-days mb-3 mt-3">
                  <div class="entry-day" *ngFor="let day of currentWeekData">
                    <div class="entry-day-label">
                      <span *ngIf="currentLang == 'en'">{{ day.date | localDate: "EE" | slice: 0:1 }}</span>
                      <span *ngIf="currentLang !== 'en'">{{ day.date | localDate: "EE" }}</span>
                    </div>
                    <div
                      class="entry-day-data na"
                      *ngIf="day.date > today"
                    ></div>
                    <div
                      class="entry-day-data"
                      *ngIf="day.date <= today"
                      [ngClass]="{
                        complete: day.exercises != ''
                      }"
                    >
                      <div
                        class="entry-day-data-value"
                        *ngIf="day.exercises != ''"
                      >
                        <span class="icon-wrap text-white">
                          <fa-icon [icon]="['fas', 'check']"></fa-icon>
                        </span>
                      </div>
                      <div
                        class="entry-day-data-value"
                        *ngIf="day.exercises == '' && day.date !== today"
                      >
                        -
                      </div>

                      <div
                        class="entry-day-data-value relative"
                        *ngIf="day.exercises == '' && day.date === today"
                      >
                        <a
                          [routerLink]="['/yoga', 'add-workout']"
                          class="stretched-link"
                          ><span class="icon-wrap text-secondary-accent">
                            <fa-icon
                              [icon]="['fas', 'pencil-alt']"
                            ></fa-icon> </span
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="target-status row">
                  <div
                    class="col-6 target-status-status"
                    *ngIf="daysHit < weeklyTarget.days"
                  >
                    <span class="icon-wrap text-primary">
                      <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                    </span>
                    {{ "In Progress" | uppercase | translate }}
                  </div>

                  <div
                    class="col-6 target-status-status"
                    *ngIf="daysHit >= weeklyTarget.days"
                  >
                    <span class="icon-wrap text-primary">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </span>
                    {{ "Target Hit" | uppercase | translate }}
                  </div>

                  <div class="col-6 text-end target-status-date">
                    {{ currentWeek[0] | localDate: "EEE d MMMM" }} -
                    {{ currentWeek[6] | localDate: "EEE d MMMM" }}
                  </div>
                </div>

                <p class="subtext" *ngIf="daysHit < weeklyTarget.days">
                  <span *ngIf="currentLang == 'en'">
                    You have completed
                    <strong
                      >{{ daysHit }} day<span *ngIf="daysHit != 1"
                        >s</span
                      ></strong
                    >
                    towards your target this week.
                  </span>
                  <span *ngIf="currentLang != 'en'">
                    {{
                      "You have completed X days towards your target this week."
                        | uppercase
                        | translate: { days: daysHit } | convertDigits
                    }}
                  </span>
                </p>
                <p class="subtext" *ngIf="daysHit < weeklyTarget.days">
                  <span *ngIf="currentLang == 'en'">
                    <strong>{{ weeklyTarget.days - daysHit }} more days</strong>
                    are needed to hit your target.
                  </span>
                  <span *ngIf="currentLang != 'en'">
                    {{
                      "X more days are needed to hit your target"
                        | uppercase
                        | translate: { days: weeklyTarget.days - daysHit } | convertDigits
                    }}
                  </span>
                </p>
              </tab>
              <tab>
                <ng-template tabHeading>
                  {{ "Last 4 weeks" | uppercase | translate }}
                </ng-template>

                <div class="status-calendar" *ngIf="last4WeeksData">
                  <div class="status-calendar-row status-calendar-header">
                    <div class="status-calendar-col">

                      <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[0]?.date | localDate: "EE" | slice: 0:1 }}</span>
                      <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[0]?.date | localDate: "EE" }}</span>
                        
                      </div>
                      <div class="status-calendar-col">
                        <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[1]?.date | localDate: "EE" | slice: 0:1 }}</span>
                        <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[1]?.date | localDate: "EE" }}</span>
  
                      </div>
                      <div class="status-calendar-col">
                        <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[2]?.date | localDate: "EE" | slice: 0:1 }}</span>
                        <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[2]?.date | localDate: "EE" }}</span>  
                      </div>
                      <div class="status-calendar-col">
                        <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[3]?.date | localDate: "EE" | slice: 0:1 }}</span>
                        <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[3]?.date | localDate: "EE" }}</span>  
  
                      </div>
                      <div class="status-calendar-col">
                        <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[4]?.date | localDate: "EE" | slice: 0:1 }}</span>
                        <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[4]?.date | localDate: "EE" }}</span>  
                      </div>
                      <div class="status-calendar-col">
                        <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[5]?.date | localDate: "EE" | slice: 0:1 }}</span>
                        <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[5]?.date | localDate: "EE" }}</span>  
                      </div>
                      <div class="status-calendar-col">
                        <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[6]?.date | localDate: "EE" | slice: 0:1 }}</span>
                        <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[6]?.date | localDate: "EE" }}</span>  
                      </div>
                    <div class="status-calendar-col">
                      <span class="icon-wrap text-primary">
                        <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                      </span>
                    </div>
                  </div>

                  <div
                    class="status-calendar-row"
                    *ngFor="let weekNum of [1, 2, 3, 4]"
                  >
                    <div
                      class="status-calendar-col"
                      *ngFor="let day of last4WeeksData[weekNum].days"
                    >
                      <span
                        class="status-calendar-day"
                        [ngClass]="{
                          complete: day.exercises != '',
                          incomplete: day.exercises == '' && day.date < today
                        }"
                        >{{ day.date | localDate: "dd" }}</span
                      >
                    </div>
                    <div class="status-calendar-col">
                      <span
                        class="icon-wrap text-primary"
                        *ngIf="
                          +last4WeeksData[weekNum].daysHit < +weeklyTarget.days
                        "
                      >
                        <fa-icon [icon]="['fas', 'times']"></fa-icon>
                      </span>
                      <span
                        class="icon-wrap text-secondary-accent"
                        *ngIf="
                          +last4WeeksData[weekNum].daysHit >= +weeklyTarget.days
                        "
                      >
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="last4WeeksData">
                  <div class="col">
                    <p class="subtext">
                      {{ last4WeeksData?.total.daysActive | convertDigits }}
                      {{ "active days" | uppercase | translate }}
                    </p>
                  </div>
                  <div class="col text-end">
                    <p class="subtext">
                      {{ last4WeeksData[1].days[0].date | localDate: "EEE d MMMM" }} -
                      {{ last4WeeksData[4].days[6].date | localDate: "EEE d MMMM" }}
                    </p>
                  </div>
                </div>
              </tab>
              <tab>
                <ng-template tabHeading>
                  {{ "All time" | uppercase | translate }}
                </ng-template>

                <div class="row">
                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">
                          {{ "Total Workouts" | uppercase | translate }}
                        </div>
                        <div class="status-badge-status">
                          {{ stats?.total | convertDigits}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">
                          {{ "Active Days" | uppercase | translate }}
                        </div>
                        <div class="status-badge-status">
                          {{ stats?.activeDays | convertDigits}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">
                          {{ "Target achieved" | uppercase | translate }}
                        </div>
                        <div class="status-badge-status">
                          {{ stats?.targetHit| convertDigits
                          }}<span> {{ "times" | uppercase | translate }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'arrows-h']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">
                          {{ "Longest streak" | uppercase | translate }}
                        </div>
                        <div class="status-badge-status">
                          {{ stats?.streak| convertDigits
                          }}<span> {{ "days" | uppercase | translate }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
            </tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button
        class="btn btn-secondary rounded-pill w-100"
        [routerLink]="['/yoga', 'add-workout']"
      >
        {{ "Add Yoga Workout" | uppercase | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h2 class="inline-title">
        <span class="float-end muted"
          ><span *ngIf="currentLang == 'en'">
            {{ allWorkouts?.length }} workout<span
              *ngIf="allWorkouts?.length != 1"
              >s</span
            >
          </span>
          <span *ngIf="currentLang != 'en'">
            {{ allWorkouts?.length | convertDigits}} {{ "Workouts" | uppercase | translate }}
          </span></span
        >
        {{ "All Workouts" | uppercase | translate }}
      </h2>
      <div *ngIf="isLoading" class="text-center">
        <app-loading-spinner [inline]="true"></app-loading-spinner>
      </div>
      <div *ngIf="!isLoading">
        <div *ngFor="let month of workoutMonths">
          <h3 class="month-header">{{ month | slice: 0:-2 | uppercase | translate }}</h3>
          <ul class="task-list task-list-todo task-list-bt">
            <ng-container *ngFor="let workout of allWorkouts">
              <li *ngIf="(workout.workout_date | date: 'MMMMYY') == month">
                <span class="task">
                  <span class="date">
                    {{ workout.workout_date | localDate: "EEE d" }}
                  </span>

                  <span style="display: flex; flex-wrap: wrap">
                    <ng-container
                      *ngFor="let exercise of exercises; let index = index"
                    >
                      <ng-container *ngIf="workout.exercises">
                        <span
                          *ngIf="
                            workout.exercises['' + exercise.id]?.reps ||
                            workout.exercises['' + exercise.id]?.secs
                          "
                          class="title"
                        >
                          <strong>
                            {{
                              exercise.label | uppercase | translate
                            }} </strong
                          ><!--br>
                  <span class="mb-1">
                  
                    <span *ngIf="workout.exercises['' + exercise.id].reps">
                      {{ 'reps' | uppercase | translate }}:
                    <strong>{{  workout.exercises['' + exercise.id].reps }}</strong
                    ><br /></span>

                    <span *ngIf="workout.exercises['' + exercise.id].secs">
                      {{ 'Holding time' | uppercase | translate }}:
                    <strong>{{  workout.exercises['' + exercise.id].secs }} secs</strong
                    ><br />
                    </span>
                    {{ 'Difficulty' | uppercase | translate }}: -->
                          <app-difficulty-icon
                            [difficulty]="
                              +workout.exercises[exercise.id].difficulty
                            "
                          ></app-difficulty-icon>
                        </span>
                      </ng-container>
                    </ng-container>
                  </span>
                  <span class="status">
                    <span class="icon-wrap text-secondary-accent" dropdown>
                      <button
                        dropdownToggle
                        type="button"
                        class="btn"
                        aria-controls="dropdown-basic"
                      >
                        <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                      </button>
                      <ul
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-basic"
                      >
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            [routerLink]="[
                              '/yoga',
                              'edit-workout',
                              workout.user_yoga_workout_id
                            ]"
                            >{{ "Edit" | uppercase | translate }}</a
                          >
                        </li>
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            (click)="
                              onDeleteEntry(workout.user_yoga_workout_id)
                            "
                            >{{ "Delete" | uppercase | translate }}</a
                          >
                        </li>
                      </ul>
                    </span>
                  </span>
                </span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
