import { UserAchievement } from "./models/user-achievement.model";
import { SettingsService } from "./services/settings.service";
import { AuthService } from "./auth/service/auth.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { interval, Subscription } from "rxjs";
import { AnalyticsService } from "./services/analytics.service";
import { UpdateService } from "./services/update.service";
import { environment } from "./../environments/environment";
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from "ngx-cookieconsent";
import { ViewportScroller } from "@angular/common";
import { slideInAnimation, fadeInAnimation } from "./route-animations";
import { AchievementService } from "./services/achievement.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [slideInAnimation, fadeInAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "base-pwa"; // keep refs to subscriptions to be able to unsubscribe later
  public innerWidth!: number;
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializeSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  private checkSiteStatusInterval: any;
  private siteStatus = 1;
  public activeAchievement!: UserAchievement;
  public showAward = false;
  isLoading = false;

  notifications: UserAchievement[] = [];
  notificationsInterval: any;

  constructor(
    private sw: UpdateService,
    private router: Router,
    private titleService: Title,
    private ccService: NgcCookieConsentService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private viewportScroller: ViewportScroller,
    private settingsService: SettingsService,
    private achievementService: AchievementService,
    private translate: TranslateService
  ) {
    // check the service worker for updates
    this.sw.checkForUpdates();

    translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.checkSiteStatus();

    this.innerWidth = window.innerWidth;
    this.checkSiteStatusInterval = interval(60000).subscribe((count) => {
      this.checkSiteStatus();
    });
    // try and restpore session
    this.isLoading = true;
    this.authService.restore().subscribe(
      (restoreResponsedata) => {
        this.isLoading = false;
        if (this.router.url.split("?")[0] === "/login") {
          if (this.authService.redirect) {
            this.router.navigateByUrl(this.authService.redirect);
          } else {
            this.router.navigate(["/"]);
          }
        }
      },
      (errorMessage) => {
        this.isLoading = false;
        // try autologin here?
        this.authService.autoLogin()?.subscribe(
          (resData) => {
            if (this.router.url.split("?")[0] === "/login") {
              if (this.authService.redirect) {
                this.router.navigateByUrl(this.authService.redirect);
              } else {
                this.router.navigate(["/dashboard"]);
              }
            }
          },
          (errorMessage) => {
            this.isLoading = false;
            // console.log(errorMessage);
          }
        );
      }
    );

    // check if we have a user

    this.authService.user.subscribe((user) => {
      if (user) {
        // check for notifications
        this.notificationsInterval = null;
        this.notificationsInterval = interval(60000).subscribe((count) => {
          this.checkNotifications();
        });
        this.achievementService.updateNotifications.subscribe((data) => {
          if (data) {
            this.checkNotifications();
          }
        });
        // end notifications
        this.checkNotifications();
      }
      else{
        this.notificationsInterval = null;
      }
    });

    // scroll to top on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (this.siteStatus === 0 && this.router.url != "/offline") {
        this.router.navigate(["/offline"]);
      }
      window.scrollTo(0, 0);

      // set page title
      const title = this.getTitle(
        this.router.routerState,
        this.router.routerState.root
      ).join(" | ");
      this.titleService.setTitle(environment.title + " | " + title);
      this.analyticsService.trackVirtualPageview(
        environment.title + " | " + title
      );
    });
    // cookie consent

    if (this.ccService.hasConsented()) {
      // start tracking
      this.analyticsService.startTracking();
    }
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        if (event.status === "deny") {
          this.analyticsService.stopTracking();
        } else {
          this.analyticsService.startTracking();
        }
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
    // end cookie consent
  }

  getTitle(state: any, parent: any): any {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.pageTitle) {
      data.push(parent.snapshot.data.pageTitle);
    }

    if (parent && parent.snapshot.data && parent.snapshot.data.subTitle) {
      data.push(parent.snapshot.data.subTitle);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }
  showMobile(routerOutlet: any) {
    if (this.innerWidth < 400) {
      return (
        routerOutlet &&
        routerOutlet.activatedRouteData &&
        routerOutlet.activatedRouteData["animation"]
      );
    }
  }
  showDesktop(routerOutlet: any) {
    if (this.innerWidth > 399) {
      return (
        routerOutlet &&
        routerOutlet.activatedRouteData &&
        routerOutlet.activatedRouteData["animation"]
      );
    }
  }

  public onClick(event: any, elementId: string): void {
    event.preventDefault();
    this.viewportScroller.scrollToAnchor(elementId);
  }
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription?.unsubscribe();
    this.popupCloseSubscription?.unsubscribe();
    this.initializeSubscription?.unsubscribe();
    this.statusChangeSubscription?.unsubscribe();
    this.revokeChoiceSubscription?.unsubscribe();
    this.noCookieLawSubscription?.unsubscribe();

    this.checkSiteStatusInterval.unsubscribe();
  }

  checkSiteStatus() {
    this.settingsService.fetchAll().subscribe(
      (responseData) => {
        this.siteStatus = +responseData.online;
        if (this.siteStatus !== 1) {
          this.router.navigate(["offline"]);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  onCloseAward(event: any) {
    this.showAward = false;
    // any more to show?
    this.checkNotifications();
  }

  checkNotifications() {
    this.achievementService.getNotifications().subscribe((responseData) => {
      this.notifications = responseData;
      if (this.notifications.length > 0) {
        this.showAward = true;
      }
    });
  }
}
