import { Router, ActivatedRoute } from "@angular/router";
import { GoalService } from "./../../services/goal.service";
import { NgForm } from "@angular/forms";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { AchievementService } from "src/app/services/achievement.service";
import { ComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-add-goal",
  templateUrl: "./add-goal.component.html",
  styleUrls: ["./add-goal.component.scss"],
})
export class AddGoalComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  area = "walking";
  goalText = "";
  customGoal = false;
  currentLang = "en";

  suggestedWalkingGoalsEn = [
    "I want to be able to go for a walk 5 days this week",
   "I should be able to walk for 15 min without stopping or getting breathless",
   "In the next week, I want to be able to climb 1 more flight of stairs",
   "I want to be able to walk for 5 more minutes this week",
   "I wish to be able to walk 10 meters more this week",
   "I want to increase the speed of my walking this week",
   "I should be able to walk to the shop near my house.",
   "I should be able to go grocery shopping in the market.",
   "I should be able to carry bags of vegetables and walk without getting breathless.",
   "I want to be able to climb stairs",
   "I want to be able to climb stairs with less breathlessness",
   "I want to use the stairs to the second or third floor of my house",
   "I want to be able to climb stairs to my house more comfortably with less breathlessness",
   "I should be able to go to my farm by walking without getting breathless.",
   "I want to be able to walk at the pace that of people my age",
   "I want to go on a trek on a hill with friends",
   "I want to be able to hike to a temple on the hill",
   "I want to be able to visit the hospital or clinic without help from family members",
   "I want to reduce my hospital visits for bouts of uncontrollable breathlessness or cough",
   "I wish to contribute more to household chores to help family members",
   "I should be able to drop my grandchildren to school nearby",
   "I want to be able to play with grandchildren",
   "I want to be able to take care of my partner more actively",
   "Should be able to take pet for a walk",
   "I want to be able to be more active throughout the day",
   "I wish to be able to take care of my grandchildren",
   "I wish to visit my relative in other towns"
  ];

  suggestedWalkingGoalsHindi = [
    "मैं इस सप्ताह ५  दिन टहलने जाना चाहता /  चाहती हूं  I",
    "मुझे बिना रुके या बिना सांस फूले १५  मिनट तक चलने में सक्षम होना चाहिए I",
    "अगले सप्ताह में, मैं सीढ़ियों की एक और पड़ाव चढ़ने में सक्षम होना चाहता/ चाहती हूं I",
    "मैं इस सप्ताह मे ५  मिनट और  चलना चाहता / चाहती हूँ I",
    "मैं इस सप्ताह १०  मीटर और चलना चाहता / चाहती हूं I",
    " मुझे अपनी चलने की गती को बढाना है I",
    " मुझे अपने घर के पास की दुकान तक चलने में सक्षम होना चाहिए।",
    "मुझे दुकान  का सामान खरीदने के लिए बाजार जाना चाहिए।",
    " मुझे सब्जियों के बैग ले जाने और बिना सांस फूले  चलने में सक्षम होना चाहिए।",
    "मैं सीढ़ियाँ चढ़ने में सक्षम होना चाहता / चाहती हूँ I",
    "मैं सांस फूलने कि तकलीफ के बिना सीढ़ियां चढ़ने में सक्षम होना चाहता / चाहती हूं I",
    "मैं अपने घर की दूसरी या तीसरी मंजिल की सीढ़ियों का उपयोग करना चाहता / चाहती हूँ I",
    "मैं सांस फूलने कि तकलीफ के बिना अपने घर तक अधिक आराम से सीढ़ियां चढ़ने में सक्षम होना चाहता / चाहती हूं  I",
    "मुझे सांस फूलने कि तकलीफ के बिना अपने खेत में चलते हुए जाने में सक्षम होना चाहिए।",
    "मैं अपनी उम्र के लोगों की गति से चलना चाहता / चाहती हूं I",
    "मैं दोस्तों के साथ पहाड़ों पर ट्रेकिंग पर जाना चाहता / चाहती हूं I ",
    "मै पहाड़ी पर स्थित मंदिर में जाना चाहता / चाहती हुं I ",
    "मैं परिवार के सदस्यों की मदद के बिना अस्पताल या क्लिनिक जाना चाहता / चाहती हूं I ",
    " मैं अनियंत्रित सांस  या खाँसी के लिए अपने अस्पताल के दौरे को कम करना चाहता / चाहती हूँ I ",
    "मैं परिवार के सदस्यों की मदद करने के लिए घर के कामों में अधिक शामिल होना चाहता / चाहती हूं I ",
    "मुझे अपने पोते-पोतियों को नजदीकी स्कूल में छोड़ने में सक्षम होना चाहिए I ",
    "मैं अपने पोते-पोतियों के साथ खेलने में सक्षम होना चाहता / चाहती हूं I ",
    "मैं अपने साथी की अधिक सक्रियता से देखभाल करने में सक्षम होना चाहता / चाहती हूँ I ",
    "मुझे लगता है कि मुझे पालतू जानवरों को बाहर ले जाने  में सक्षम होना चाहिए I",
    "मैं पूरे दिन और अधिक सक्रिय रहना चाहता / चाहती हूं I",
    "मैं अपने पोते-पोतियों की देखभाल करना चाहता / चाहती हुए।",
    "मैं अपने रिश्तेदार से दूसरे गाँव में मिलने जाना चाहता/ चाहती  हूँ I",
  ];

  suggestedWalkingGoalsMarathi = [
    "मला या आठवड्यात ५ दिवस फिरायला जायचे आहे.",
    "मी न थांबता किंवा न दमता १५  मिनिटे चालण्यास सक्षम असावे.",
    "पुढच्या आठवड्यात, मला आणखी १ जिना पायऱ्या चढून जायचे आहे. ",
    "मला या आठवड्यात आणखी ५  मिनिटे चालायाचे आहे.",
    "मला या आठवड्यात १०  मीटर अधिक चालण्याची इच्छा आहे.",
    "मला या आठवड्यात माझ्या चालण्याचा वेग वाढवायचा आहे.",
    "मला माझ्या घराजवळच्या दुकानात चालत जाता यायला हवे.",
    "मला बाजारात किराणा खरेदीसाठी जाता आले पाहिजे.",
    "मला भाजीच्या पिशव्या घेऊन चालता आले पाहिजे आणि कमीतकमी दम लागता चालता आले पाहिजे.",
    "मला पायऱ्या चढायला सक्षम व्हायचे आहे.",
    "मला कमीतकमी दम लागता पायऱ्या चढण्याची इच्छा आहे.",
    "मला माझ्या घराच्या दुसऱ्या किंवा तिसऱ्या मजल्यापर्यंतच्या  पायऱ्यांचा वापर करायचा आहे.",
    "मला माझ्या घराच्या पायऱ्या कमीतकमी दम लागता आणि अधिक आरामात चढून जायचे आहे. ",
    "मला दम न लागता माझ्या शेतात चालत जाता आले पाहिजे.",
    "मला माझ्या वयाच्या लोकांच्या गतीने चालायाचे आहे.",
    "मला मित्रांसोबत डोंगरावर ट्रेकला जायचे आहे.",
    "मला टेकडीवरील मंदिरात जायचे आहे.",
    "मला कुटुंबातील सदस्यांच्या मदतीशिवाय हॉस्पिटल किंवा क्लिनिकला भेट द्यायची आहे.",
    "मला अनियंत्रित श्वासोच्छवास किंवा खोकल्यासाठी माझ्या हॉस्पिटलच्या भेटी कमी करायच्या आहेत.",
    "कुटुंबातील सदस्यांना मदत करण्यासाठी मला घरातील कामांमध्ये अधिक हातभार लावायचा आहे.",
    "मला माझ्या नातवंडांना जवळच्या शाळेत सोडता आले पाहिजे.",
    "मला नातवंडांसोबत खेळता येईल एवढे सक्षम व्हायचे आहे.",
    "मला माझ्या जोडीदाराची अधिक सक्रियपणे काळजी घेण्यास सक्षम व्हायचे आहे.",
    "पाळीव प्राण्यांना बाहेर फिरायला घेऊन जाण्यास सक्षम असावे असे मला वाटते.",
    "मला दिवसभर अधिक सक्रिय व्हायचे आहे.",
    "मला माझ्या नातवंडांची काळजी घेता यावी अशी माझी इच्छा आहे.",
    "मला इतर गावातील माझ्या नातेवाईकाला भेटायला जाण्याची इच्छा आहे.",
  ];

  suggestedStrengthGoalsEn = [
    "I want to be able to do my strength training exercises on 3 days this week",
    "I want to be able to do all the recommended strength exercises",
    "I wish to be able to complete all the recommended sets of all exercises",
    "I should be able to carry a weight of 1 kg while exercising.",
    "I want to be able to add 250 gms of weight in the next week",
    "I wish to be able to complete all the repetitions with the increased exercising weight, this week",
    "I wish to be able to bathe without being tired or breathless",
    "I wish to carry my own luggage while traveling",
    "I should be able to carry a watering can (10 L or 20 L) to my house.",
    "I wish to be more active in farming activities",
    "I wish to be able to carry my grandchildren"
  ];

  suggestedStrengthGoalsHindi = [
    "मैं इस सप्ताह ३  दिन अपने ताकत बढानेवाले व्यायाम करने में सक्षम होना चाहता / चाहती हूं I",
    "में सिफारिश किये गये सभी ताकत बढानेवाले व्यायाम करने में सक्षम होना चाहता / चाहती हूं I",
    "मैं उन सभी अभ्यासों को पूरा करने में सक्षम होना चाहता / चाहती हूं जिनकी मुझे सिफारिश कि गयी है।",
    " मुझे व्यायाम करते समय १  किलो वजन उठाने में सक्षम होना चाहिए।",
    " मैं अगले हफ्ते २५० ग्राम वजन बढाना चाहता हूं I",
    " साथ इस सप्ताह में व्यायाम के बढ़े हुए वजन के साथ सभी व्यायाम दोहराव को पूरा करना चाहता / चाहती हुं I",
    " मैं बिना थके या सांस लेने में तकलीफ के  बिना  स्नान करना चाहता / चाहती हुं I ",
    " मैं यात्रा पर अपना सामान खुद  साथ ले जाना चाहता/चाहती हूं I",
    " मैं अपने घर में  पानी की बोतल  (१० लीटर या २० लीटर) ले जाना चाहता / चाहती हूँ।",
    " मैं खेती में और अधिक सक्रिय होना चाहता / चाहती हूं I",
    "  मैं अपने पोते-पोतियों को अपने साथ ले जाना चाहता / चाहती हूं I ",
  ];

  suggestedStrengthGoalsMarathi = [
    "मला या आठवड्यात ३ दिवस ताकदीचे व्यायाम करण्यास सक्षम व्हायचे आहे.",
    "मला शिफारस केलेले ताकदीचे सर्व व्यायाम करण्यास सक्षम व्हायचे आहे.",
    "मला शिफारस केलेल्या सर्व व्यायामाचे सर्व संच पूर्ण करण्यास सक्षम व्हायचे आहे.",
    "व्यायाम करताना मला १ किलो वजन उचलता आले पाहिजे.",
    "मला पुढील आठवड्यात २५० ग्रॅम वजन वाढवायचे आहे.",
    "या आठवड्यात वाढलेल्या व्यायामाच्या वजनासह सर्व पुनरावृत्ती पूर्ण करू इच्छितो. ",
    "मला न थकता किंवा दम न लागता आंघोळ करता यावी अशी माझी इच्छा आहे.",
    "मला प्रवासात माझे सामान स्वतः घेऊन जायचे आहे.",
    "मला माझ्या घरी पाण्याचा डबा (१० लिटर  किंवा २० लिटर) घेऊन जायचा आहे.",
    "मला शेतीच्या कामात अधिक सक्रिय व्हायचे आहे.",
    "मला माझ्या नातवंडांना माझ्या सोबत घेऊन जाण्याची इच्छा आहे.",
  ];

  suggestedYogaGoalsEn = [
    "I want to be able to perform  all the recommended Yoga Asanas and Pranayamas",
    "I want to be able to attain the recommended end pose of the Asana that I am unable to perform now",
    "I wish to be able to hold the Yoga pose for 3-3.5 seconds, which I am finding difficult in the beginning",
    "I want to hold the Tadasana for 3-5 more seconds next week",
    "I want to hold the Bhujangasana pose for 3-5 more seconds next week",
    "I want to hold Marjarasana pose for 3-5 more seconds next week",
    "I want to hold Ardh Katichakrasana pose for 3-5 more seconds next week",
    "I wish to perform Anulom Vilom  Pranayama in the recommended method",
    "I wish to be able to do the recommended rounds  for the given time of Anulom Vilom Pranayama",
    "I wish to perform Ujjayi Pranayama in the recommended method",
    "I wish to be able to do the guided rounds  for the given time of Ujjayi Pranayama",
    "I want to perform Bhramari Pranayama in the recommended method",
    "I wish to be able to do the recommended rounds for the given time of Bhramari Pranayama",
    "I wish to find a suitable time for exercising",
    "I want to perform Yoga for 3-5 days this week",
    "I wish to be able to meditate for the recommended time",
    "I want to follow the recommended method of meditation",
    "I wish to have more flexibility in my joints",
    "I wish to attain more balance while performing my daily activities",
    "I wish to feel less breathless in the mornings",
  ];

  suggestedYogaGoalsHindi = [
    "मैं मुझे सिफारिश किये गये सभी योग और प्राणायाम करने में सक्षम होना चाहता / चाहती हूं I",
    "मै मुझे सिफारिश कि गयी आसन कि उस अंतिम स्थिति तक पहुंचना चाहता / चाहती हूं जो मैं अभी नहीं कर सकता I",
    "मैं ३ – ५  सेकंड तक  योग मुद्रा को  धारण करने में सक्षम होना चाहता / चाहती हूं, जो मुझे शुरुआत में मुश्किल लग रहा था I",
    " मैं अगले सप्ताह ताड़ासन मुद्रा  को और ३-५  सेकंड तक के लिए रोकना चाहता / चाहती  हूं I",
    " मैं अगले सप्ताह  और ३-५  सेकंड तक के लिए भुजंगासन मुद्रा को बढाना चाहता / चाहती हूं I",
    " मैं अगले सप्ताह और ३-५  सेकंड तक के लिए मर्जरासन मुद्रा को बढाना चाहता / चाहती हूं I",
    " मैं अगले हफ्ते और ३-५  सेकेंड तक अर्धकटीचक्रासन मुद्रा को बढाना चाहता / चाहती हूं I",
    " अनुलोम-विलोम प्राणायाम सिफारिश किये गये तरीके से करना चाहता / चाहती हूँ I",
    " मैं अनुलोम-विलोम प्राणायाम को दिए गए समय के लिए सिफारिश किये गये चक्रों को करने में सक्षम होना चाहता / चाहती हूं I",
    " मैं सिफारिश किये गये तरीके से उज्जयी प्राणायाम करना चाहता / चाहती हूं I",
    " मैं उज्जयी प्राणायाम को दिए गए समय के लिए सिफारिश किये गये चक्रों को करने में सक्षम होना चाहता / चाहती हूं I",
    " मैं सिफारिश किये गये तरीके से भ्रामरी प्राणायाम करना चाहता / चाहती हूं I",
    " मैं भ्रामरी प्राणायाम को दिए गए समय के लिए सिफारिश किये गये चक्र करना चाहता / चाहती  हुं I",
    " मैं व्यायाम करने के लिए सही समय खोजना चाहता / चाहती हूं I",
    " मैं इस सप्ताह ३ – ५  दिन योग करना चाहता / चाहती  हूं I",
    " मैं सिफारिश किये गये समय के लिए ध्यान करने में सक्षम होना चाहूंगा / चाहुंगी I",
    " मैं ध्यान की सिफारिश कि गयी विधि का पालन करना चाहता / चाहती हूं I",
    " मुझे अपने जोड़ों में अधिक लचीलापन चाहिए I",
    " मैं अपनी दैनिक गतिविधियों में अधिक संतुलित रहना चाहता / चाहती हूँ I",
    " मैं सुबह सांस लेने मे तकलीफ नही चाहता / चाहती हूं I",
  ];

  suggestedYogaGoalsMarathi = [
    "मला शिफारस केलेली सर्व योगासने आणि प्राणायाम करण्यास सक्षम व्हायचे आहे.",
    "मला आसनाची शिफारस केलेली शेवटची स्थिती गाठायची आहे जी मी आता करू शकत नाही.",
    "मला ३-३-५  सेकंदासाठी  योगास्थिती  धारण करण्यास सक्षम व्हायचे आहे, जे मला सुरुवातीला कठीण वाटायचे .",
    "मला पुढील आठवड्यात अजून  ३ – ५ सेकंद  ताडासन स्थितीत राहणे वाढवायचे आहे . ",
    "मला पुढील  आठवड्यात अजून ३ – ५ सेकंद  भुजंगासन स्थितीत राहणे वाढवायचे आहे.",
    "मला पुढील  आठवड्यात अजून  ३ – ५ सेकंद  मार्जारासन स्थितीत राहणे वाढवायचे आहे.",
    "मला पुढील  आठवड्यात अजून  ३ – ५ सेकंद अर्धकटिचक्रासन स्थितीत राहणे वाढवायचे आहे .",
    "मला शिफारस केलेल्या पद्धतीने अनुलोम विलोम प्राणायाम करायचा आहे.",
    "अनुलोम विलोम प्राणायामाच्या दिलेल्या वेळेसाठी शिफारस केलेल्या फेऱ्या करण्यास मला सक्षम व्हायचे आहे.",
    "मला शिफारस केलेल्या पद्धतीने उज्जयी  प्राणायाम करायचा आहे.",
    "उज्जयी  प्राणायामाच्या दिलेल्या वेळेसाठी शिफारस केलेल्या फेऱ्या करण्यास मला सक्षम व्हायचे आहे.",
    "मला शिफारस केलेल्या पद्धतीने भ्रामरी प्राणायाम करायचे आहे.",
    "मला भ्रामरी प्राणायामच्या दिलेल्या वेळेत शिफारस केलेल्या फेऱ्या करता याव्यात अशी माझी इच्छा आहे.",
    "मला व्यायामासाठी योग्य वेळ शोधायचा आहे.",
    "मला या आठवड्यात ३-५  दिवस योगासने करायची आहेत.",
    "मी शिफारस केलेल्या वेळेत  ध्यान करण्यास सक्षम होऊ इच्छितो.",
    "मला ध्यानाची शिफारस केलेली पद्धत अवलंबायची आहे.",
    "मला माझ्या सांध्यांमध्ये अधिक लवचिकता हवी आहे.",
    "माझी दैनंदिन कामे करताना मला अधिक संतुलन साधायचे आहे.",
    "मला सकाळी कमी दम लागावा असे वाटते . ",
  ];

  constructor(
    private goalService: GoalService,
    private router: Router,
    private route: ActivatedRoute,
    private achievementService: AchievementService,
    private translate: TranslateService
  ) {
    if (this.route.snapshot.data["area"]) {
      this.area = this.route.snapshot.data["area"];
    }
  }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: this.translate.instant("THIS FORM IS INVALID"),
        text: this.translate.instant("PLEASE CHECK THE FORM FOR ERRORS"),
        showConfirmButton: true,
      });
    } else {
      // create entry
      const resourceIds = [];
      this.goalService
        .create(this.area, 0, this.entryForm?.value.goal)
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: this.translate.instant("GOAL CREATED"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/" + this.area]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
            this.entryForm?.reset();
          }
        );
    }
  }
  getSuggestedGoals(area: string) {
    switch (area) {
      default:
      case "walking":
        return this.suggestedWalkingGoalsEn;/*
        switch (this.currentLang) {
          default:
          case "en":
            return this.suggestedWalkingGoalsEn;
            break;
          case "hindi":
            return this.suggestedWalkingGoalsHindi;
            break;
          case "marathi":
            return this.suggestedWalkingGoalsMarathi;
            break;
        }*/

        break;
      case "strength":
        return this.suggestedStrengthGoalsEn;
        /*
        switch (this.currentLang) {
          default:
          case "en":
            return this.suggestedStrengthGoalsEn;
            break;
          case "hindi":
            return this.suggestedStrengthGoalsHindi;
            break;
          case "marathi":
            return this.suggestedStrengthGoalsMarathi;
            break;
        }*/
        break;
      case "yoga":
        return this.suggestedYogaGoalsEn;
        /*
        switch (this.currentLang) {
          default:
          case "en":
            return this.suggestedYogaGoalsEn;
            break;
          case "hindi":
            return this.suggestedYogaGoalsHindi;
            break;
          case "marathi":
            return this.suggestedYogaGoalsMarathi;
            break;
        }*/
        break;
    }
  }
  onPickGoal(goal: string) {
    if (goal === "custom") {
      this.customGoal = true;
      this.goalText = "";
    } else {
      this.customGoal = false;
      this.goalText = goal;
    }
  }

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.submitted) {
      return true;
    } else if (!this.entryForm?.value.goal) {
      return true;
    }
  }
}
