export class UserWalking {
    constructor(
      public user_walking_id: number,
      public user_id: number,
      public minutes: number,
      public seconds: number,
      public difficulty: number,
      public date_walked: string,
      public created: string,
      public modified: string,
    ) {}
  }
