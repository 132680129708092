<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'SET WALKING TARGET' | translate"
    [icon]="'walking'"
    [backLink]="['/walking']"
  ></app-page-header>

  <div class="row">
    <div class="col">
      <form class="form-recharge" (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
          <label>
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'stopwatch']"></fa-icon>
            </span>
            {{ 'WALKING TARGET' | translate }}
            </label
          >

          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
            <p *ngIf="!currentTarget">
                {{'In order to set a walking target for you please let us know how long can you walk a bit faster than usual without stopping?' | uppercase | translate}}
              </p>
              <p *ngIf="currentTarget">

              {{ 'Please enter a new target below' | uppercase | translate }}
                
              </p>
              <div class="col-sm-6 mb-2">
                <div class="row mt-0">
                  <div class="col">
                    <label for="minutes" class="sub-label">{{ 'Minutes' | uppercase | translate }}</label>
                    <input
                      type="number"
                      id="minutes"
                      class="form-control"
                      ngModel
                      #minutes="ngModel"
                      name="minutes"
                      placeholder="0"
                      minutes
                    />
                    <div
                      class="alert alert-danger"
                      *ngIf="!minutes.valid && submitted"
                    >
                      Please enter a number of minutes walked
                    </div>
                  </div>
                </div>
              </div>
          </div>

          
        </div>

        <button class="btn btn-secondary rounded-pill w-100">{{ 'Confirm' | uppercase | translate }}</button>
      </form>
    </div>
  </div>
</div>
