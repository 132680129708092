import { UserTargetService } from "./../../services/user-target.service";
import { GoalService } from "./../../services/goal.service";
import { Goal } from "./../../models/goal.model";
import { UserWalking } from "./../../models/user-walking.model";
import { UserService } from "./../../auth/service/user.service";
import { WalkingService } from "./../../services/walking.service";
import { Component, OnInit } from "@angular/core";

// import Swiper core and required modules
import SwiperCore, { SwiperOptions, Pagination } from "swiper";
import { forkJoin } from "rxjs";
import { DateHelperService } from "src/app/services/date-helper.service";
import Swal from "sweetalert2/src/sweetalert2.js";
import { AchievementService } from "src/app/services/achievement.service";
import { ResourcesService } from "src/app/services/resources.service";
import { UserTarget } from "src/app/models/user-target.model";
import { TranslateService } from "@ngx-translate/core";

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
  selector: "app-walking-index",
  templateUrl: "./walking-index.component.html",
  styleUrls: ["./walking-index.component.scss"],
})
export class WalkingIndexComponent implements OnInit {
  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    pagination: { clickable: true },
    scrollbar: { draggable: false },
  };
  public isLoading = false;
  public userWalks!: UserWalking[];
  public daysHit = 0;
  public weeklyTarget = this.walkingService.weeklyTarget;
  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public today = this.dateHelperService.getToday();
  public allGoals: Goal[] = [];
  public openGoals: Goal[] = [];
  public completeGoals: Goal[] = [];
  public achievements: any[] = [];
  public resourceId = 0;
  public pages?: any[];
  public resource: any;
  public allTargets: UserTarget[] = [];
  public currentTarget?: UserTarget;
  currentLang = 'en';

  constructor(
    private walkingService: WalkingService,
    private userService: UserService,
    private dateHelperService: DateHelperService,
    private goalService: GoalService,
    private achievementService: AchievementService,
    private resourcesService: ResourcesService,
    private userTargetService: UserTargetService,
    private translate:TranslateService
  ) {
    this.resourceId = this.resourcesService.resourceIdMap["walking"];
  }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];
    forkJoin({
      userWalks: this.walkingService.fetchBetween(startDate, endDate),
      allWaks: this.walkingService.fetchAll(),
      userData: this.userService.get(),
      goals: this.goalService.fetchArea("walking"),
      achievements: this.achievementService.fetchArea("walking"),
      resource: this.resourcesService.fetchResource(this.resourceId),
      targets: this.userTargetService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;

        this.allTargets = responseData.targets;
        this.currentTarget = this.userTargetService.getLatestTarget(
          this.allTargets,
          "walking"
        );
        if (this.currentTarget) {
          this.walkingService.weeklyTarget.threshold =
            +this.currentTarget.target;
        }

        this.userWalks = responseData.userWalks;
        this.currentWeekData = this.walkingService.generateWeekArray(
          this.currentWeek,
          this.userWalks
        );
       /* let weekReport = this.walkingService.getWeekReport(
          responseData.allWaks,
          this.dateHelperService.getLastWeek(),
          this.walkingService.weeklyTarget.threshold
        );*/

        this.daysHit = this.walkingService.getDaysHit(this.currentWeekData);
        this.allGoals = responseData.goals;
        this.openGoals = this.allGoals.filter((goal) => +goal.status === 0);
        this.completeGoals = this.allGoals.filter((goal) => +goal.status === 1);
        this.achievements = this.achievementService.getUnique(
          responseData.achievements
        );
        this.resource = responseData.resource.resource;
        this.pages = responseData.resource.pages;
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }

  onCompleteGoal(goal: Goal) {
    Swal.fire({
      title: this.translate.instant("ARE YOU SURE?"),
      text: this.translate.instant("DO YOU WANT TO SET THE GOAL AS ACHIEVED?"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText : this.translate.instant("CANCEL"),
      confirmButtonText: this.translate.instant("YES"),
    }).then((result) => {
      if (result.value) {
        this.goalService
          .update(goal.goal_id, goal.area, 1, goal.goal)
          .subscribe(
            (responseData) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: this.translate.instant("GOAL ACHIEVED"),
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                toast: true,
              });
              this.allGoals.forEach((aGoal, index) => {
                if (aGoal.goal_id === goal.goal_id) {
                  this.allGoals[index].status = 1;
                }
              });

              this.openGoals = this.allGoals.filter(
                (goal) => +goal.status === 0
              );
              this.completeGoals = this.allGoals.filter(
                (goal) => +goal.status === 1
              );
              this.achievementService.refreshNotifications();
            },
            (error) => {
              console.log(error);
            }
          );
      }
    });
  }

  onSwiper(swiper: any) {}
  onSlideChange() {}
}
