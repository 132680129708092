import { NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss'],
})
export class FooterNavComponent implements OnInit, OnDestroy {

  hasFooterNav = true;
  showAside = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'has-footer-nav');


    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        this.showAside = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'has-footer-nav');
  }
  
  onToggleAsideMenu() {
    this.showAside = !this.showAside;
  }
  onCloseMenu(event:any){
    this.showAside = false;
  }
}
