import { forkJoin, Observable } from "rxjs";
import { UserYogaWorkout } from "./../../models/user-yoga-workout.model";
import { ActivatedRoute } from "@angular/router";
import { YogaWorkoutService } from "./../../services/yoga-workout.service";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import * as moment from "moment";
import { AchievementService } from "src/app/services/achievement.service";
import { ComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { TranslateService } from "@ngx-translate/core";
import { BsLocaleService } from "ngx-bootstrap/datepicker";

@Component({
  selector: "app-edit-yoga-workout",
  templateUrl: "./edit-yoga-workout.component.html",
  styleUrls: ["./edit-yoga-workout.component.scss"],
})
export class EditYogaWorkoutComponent
  implements OnInit, ComponentCanDeactivate
{
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  isLoading = false;
  public errors: any = [];
  public entryId!: number;
  public workout?: UserYogaWorkout;
  maxDate = new Date();
  workoutDate?: Date;
  exercisesPicked: any = [];
  exercisesSelected = false;
  submittedStep = [false, false, false, false];

  // tadasana, Bhujanga,Marjarasana, Ardh Katichakrasana,
  // Anuloma Vilom Pranayama, Ujjayi Pranayam, Bhramari Pranayama,
  exercises = [
    { id: "tadasana", label: "Tadasana", type: "asana" },
    { id: "bhujanga", label: "Bhujanga", type: "asana" },
    { id: "marjarasana", label: "Marjarasana", type: "asana" },
    { id: "ardhKatichakrasana", label: "Ardh Katichakrasana", type: "asana" },
    {
      id: "AnulomaVilomPranayama",
      label: "Anuloma Vilom Pranayama",
      type: "pranayama",
    },
    { id: "UjjayiPranayama", label: "Ujjayi Pranayama", type: "pranayama" },
    { id: "BhramariPranayama", label: "Bhramari Pranayama", type: "pranayama" },
    { id: "meditation", label: "meditation", type: "meditation" },
  ];
  step = 0;
  exerciseData: any;

  constructor(
    private yogaWorkoutService: YogaWorkoutService,
    private router: Router,
    private route: ActivatedRoute,
    private achievementService: AchievementService,
    private el: ElementRef,
    private translate: TranslateService,
    private localeService: BsLocaleService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.entryId = params.id;
      this.isLoading = true;
      forkJoin({
        workout: this.yogaWorkoutService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.workout = responseData.workout;
          this.exerciseData = JSON.parse(this.workout.exercises);
          if (!this.exerciseData) {
            this.exerciseData = {
              tadasana: { secs: 0, difficulty: 0 },
              bhujanga: { secs: 0, difficulty: 0 },
              marjarasana: { secs: 0, difficulty: 0 },
              ardhKatichakrasana: { secs: 0, difficulty: 0 },
              AnulomaVilomPranayama: { reps: 0, difficulty: 0 },
              UjjayiPranayama: { reps: 0, difficulty: 0 },
              BhramariPranayama: { reps: 0, difficulty: 0 },
              meditation: { secs: 0, difficulty: 0 },
            };
          }
          // fill in blank data
          this.exercises.forEach((exercise) => {
            if (!this.exerciseData[exercise.id]) {
              if (exercise.type == "asana") {
                this.exerciseData[exercise.id] = {
                  weight: null,
                  secs: 0,
                  difficulty: 0,
                };
              }
              if (exercise.type == "pranayama") {
                this.exerciseData[exercise.id] = {
                  weight: null,
                  reps: 0,
                  difficulty: 0,
                };
              }
            } else {
              this.exercisesPicked.push(exercise);
            }
          });
          this.workoutDate = moment(this.workout.workout_date).toDate();
        },
        (error) => {
          this.errors.push(error.message);
        }
      );
    });
    if(this.translate.currentLang == 'hindi'){
      this.localeService.use(this.translate.currentLang);
    }
    else{
      this.localeService.use('en');
    }
  }

  onNext() {
    if (
      (!this.exerciseData[this.exercisesPicked[this.step].id].reps &&
        !this.exerciseData[this.exercisesPicked[this.step].id].secs) ||
      !this.exerciseData[this.exercisesPicked[this.step].id].difficulty
    ) {
      Swal.fire({
        icon: "error",
        title: this.translate.instant("THIS FORM IS INVALID"),
        text: this.translate.instant("PLEASE CHECK THE FORM FOR ERRORS"),
        showConfirmButton: true,
        didClose: () => this.scrollToFirstInvalidControl(),
      });

      this.submittedStep[this.step] = true;
    } else {
      this.step++;
    }
  }
  onPrev() {
    this.step--;
  }

  onPickExercise(exercise: any) {
    if (this.exercisesPicked.indexOf(exercise) !== -1) {
      this.exercisesPicked.splice(this.exercisesPicked.indexOf(exercise), 1);
    } else {
      this.exercisesPicked.push(exercise);
    }
  }
  onPickAll() {
    this.exercisesPicked = [];
    this.exercises.forEach((exercise) => {
      this.exercisesPicked.push(exercise);
    });
  }
  onConfirmExercises() {
    this.exercisesSelected = true;
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: this.translate.instant("THIS FORM IS INVALID"),
        text: this.translate.instant("PLEASE CHECK THE FORM FOR ERRORS"),
        showConfirmButton: true,
      });
    } else {
      // create entry
      const resourceIds = [];
      this.yogaWorkoutService
        .update(
          this.entryId,
          this.entryForm?.value.workout_date,
          this.exerciseData
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: this.translate.instant("ENTRY UPDATED"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/yoga", "workouts"]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }
  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector("form .ng-invalid");

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth",
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.submitted) {
      return true;
    }
  }
}
