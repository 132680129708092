//! moment.js locale configuration
//! locale : <Marathi> [hi]
//! author : Mayank Singhal : https://github.com/mayanksinghal

import { LocaleData } from "ngx-bootstrap/chronos";

let symbolMap: { [key: string]: string } = {
    1: "१",
    2: "२",
    3: "३",
    4: "४",
    5: "५",
    6: "६",
    7: "७",
    8: "८",
    9: "९",
    0: "०",
  },
  numberMap: { [key: string]: string } = {
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "०": "0",
  };

export const mrLocale: LocaleData = {
  abbr: "mr",
  months:
    "जानेवारी_फेब्रुवारी_मार्च_एप्रिल_मे_जून_जुलै_ऑगस्ट_सप्टेंबर_ऑक्टोबर_नोव्हेंबर_डिसेंबर".split(
      "_"
    ),
  monthsShort:
    "जाने._फेब्रु._मार्च._एप्रि._मे._जून._जुलै._ऑग._सप्टें._ऑक्टो._नोव्हें._डिसें.".split(
      "_"
    ),
  monthsParseExact: true,
  weekdays: "रविवार_सोमवार_मंगळवार_बुधवार_गुरूवार_शुक्रवार_शनिवार".split("_"),
  weekdaysShort: "रवि_सोम_मंगळ_बुध_गुरू_शुक्र_शनि".split("_"),
  weekdaysMin: "र_सो_मं_बु_गु_शु_श".split("_"),
  longDateFormat: {
    LT: "A h:mm वाजता",
    LTS: "A h:mm:ss वाजता",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY, A h:mm वाजता",
    LLLL: "dddd, D MMMM YYYY, A h:mm वाजता",
  },
  calendar: {
    sameDay: "[आज] LT",
    nextDay: "[उद्या] LT",
    nextWeek: "dddd, LT",
    lastDay: "[काल] LT",
    lastWeek: "[मागील] dddd, LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "%sमध्ये",
    past: "%sपूर्वी",
    s: relativeTimeMr,
    ss: relativeTimeMr,
    m: relativeTimeMr,
    mm: relativeTimeMr,
    h: relativeTimeMr,
    hh: relativeTimeMr,
    d: relativeTimeMr,
    dd: relativeTimeMr,
    M: relativeTimeMr,
    MM: relativeTimeMr,
    y: relativeTimeMr,
    yy: relativeTimeMr,
  },
  preparse(str: string): string {
    return str.replace(/[१२३४५६७८९०]/g, function (match) {
      return numberMap[match];
    });
  },
  postformat(str: string): string {
    return str.replace(/\d/g, function (match) {
      return symbolMap[match];
    });
  },
  // Hindi notation for meridiems are quite fuzzy in practice. While there exists
  // a rigid notion of a 'Pahar' it is not used as rigidly in modern Hindi.
  meridiemParse: /पहाटे|सकाळी|दुपारी|सायंकाळी|रात्री/,
  meridiemHour(hour: any, meridiem) {
    if (hour === 12) {
      hour = 0;
    }
    if (meridiem === "पहाटे" || meridiem === "सकाळी") {
      return hour;
    } else if (
      meridiem === "दुपारी" ||
      meridiem === "सायंकाळी" ||
      meridiem === "रात्री"
    ) {
      return hour >= 12 ? hour : hour + 12;
    }
  },
  meridiem(hour, minute, isLower) {
    if (hour >= 0 && hour < 6) {
      return "पहाटे";
    } else if (hour < 12) {
      return "सकाळी";
    } else if (hour < 17) {
      return "दुपारी";
    } else if (hour < 20) {
      return "सायंकाळी";
    } else {
      return "रात्री";
    }
  },
  week: {
    dow: 0, // Sunday is the first day of the week.
    doy: 6, // The week that contains Jan 1st is the first week of the year.
  },
};

function relativeTimeMr(
  number: any,
  withoutSuffix: any,
  string: any,
  isFuture: any
) {
  var output = "";
  if (withoutSuffix) {
    switch (string) {
      case "s":
        output = "काही सेकंद";
        break;
      case "ss":
        output = "%d सेकंद";
        break;
      case "m":
        output = "एक मिनिट";
        break;
      case "mm":
        output = "%d मिनिटे";
        break;
      case "h":
        output = "एक तास";
        break;
      case "hh":
        output = "%d तास";
        break;
      case "d":
        output = "एक दिवस";
        break;
      case "dd":
        output = "%d दिवस";
        break;
      case "M":
        output = "एक महिना";
        break;
      case "MM":
        output = "%d महिने";
        break;
      case "y":
        output = "एक वर्ष";
        break;
      case "yy":
        output = "%d वर्षे";
        break;
    }
  } else {
    switch (string) {
      case "s":
        output = "काही सेकंदां";
        break;
      case "ss":
        output = "%d सेकंदां";
        break;
      case "m":
        output = "एका मिनिटा";
        break;
      case "mm":
        output = "%d मिनिटां";
        break;
      case "h":
        output = "एका तासा";
        break;
      case "hh":
        output = "%d तासां";
        break;
      case "d":
        output = "एका दिवसा";
        break;
      case "dd":
        output = "%d दिवसां";
        break;
      case "M":
        output = "एका महिन्या";
        break;
      case "MM":
        output = "%d महिन्यां";
        break;
      case "y":
        output = "एका वर्षा";
        break;
      case "yy":
        output = "%d वर्षां";
        break;
    }
  }
  return output.replace(/%d/i, number);
}
