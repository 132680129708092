export class Goal {
    constructor(
      public goal_id: number,
      public user_id: number,
      public area: string,
      public status: number,
      public goal: string,
      public created: string,
      public modified: string,
    ) {}
  }
