import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../auth/service/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() pageTitle:any;
  @Input() tallNav:any;
  @Input() home:any;
  isDashboard = false;
  showAside = false;
  constructor(
    private authService: AuthService,
    private location: Location,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      this.showAside = false;
    });

  }

  logout(): void {
    this.authService.logout();
  }
  onBack() {
    this.location.back();
  }
  onToggleAsideMenu() {
    this.showAside = !this.showAside;
  }
  onCloseMenu(event:any){
    this.showAside = false;
  }
}
