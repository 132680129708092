import { AuthResponseData } from '../service/auth.service';
import { AuthService } from './../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isLoading = false;
  error = null;
  testSite = false;
  environment = environment;
  languages = environment.languages;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    // are we logged in?
    this.testSite = environment.testSite;
    console.log(this.testSite);
    const user = this.authService.user.getValue();
    this.isLoading = true;
    // try this..
    this.authService.user.subscribe((user) => {
      if (user) {
        if (this.authService.redirect) {
          this.router.navigateByUrl(this.authService.redirect);
        } else {
          this.router.navigate(['/']);
        }
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    });
  }
  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const password = form.value.password;
    const rememberMe = form.value.rememberMe;
    let authObs: Observable<AuthResponseData>;

    this.isLoading = true;

    authObs = this.authService.login(email, password, rememberMe);

    authObs.subscribe(
      (resData) => {
        this.isLoading = false;
        // do they need to evaluate?
        this.router.navigate(['/']);
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );

    form.reset();
  }
}
