<div class="achievement-notification" [ngClass]="{'bg-primary':activeAchievement.type !== 'week-streak', 'bg-light':activeAchievement.type === 'week-streak'}">
  

  <div class="achievement-wrap">
    <a class="close-btn" (click)="onCloseAward($event)">
      <span class="icon-wrap back-link">
        <fa-icon [icon]="['fas', 'arrow-alt-left']"></fa-icon> </span
    ></a>
    <div>
      <h1>{{ 'CONGRATULATIONS' | translate }}!</h1>
      <h2>{{ 'YOU JUST EARNED A NEW BADGE' | translate }}</h2>
      <app-achievement-badge
        [achievement]="activeAchievement"
      ></app-achievement-badge>

    <h3 *ngIf="activeAchievement.type === 'badge-workouts' && activeAchievement.area === 'walking'">{{ 'Walking Workout Milestone' | uppercase | translate }}</h3>
    <h3 *ngIf="activeAchievement.type === 'badge-goals' && activeAchievement.area === 'walking'">{{ 'Walking Goal Award' | uppercase | translate }}</h3>
    <h3 *ngIf="activeAchievement.type === 'badge-workout-streak' && activeAchievement.area === 'walking'">{{ 'Walking Streak Achievement' | uppercase | translate }}</h3>
    <h3 *ngIf="activeAchievement.type === 'badge-workouts' && activeAchievement.area === 'strength'">{{ 'Strength Workout Milestone' | uppercase | translate }}</h3>
    <h3 *ngIf="activeAchievement.type === 'badge-goals' && activeAchievement.area === 'strength'">{{ 'Strength Goal Award' | uppercase | translate }}</h3>
    <h3 *ngIf="activeAchievement.type === 'badge-workout-streak' && activeAchievement.area === 'strength'">{{ 'Strength Streak Achievement' | uppercase | translate }}</h3>
    <h3 *ngIf="activeAchievement.type === 'badge-workouts' && activeAchievement.area === 'yoga'">{{ 'Yoga Workout Milestone' | uppercase | translate }}</h3>
    <h3 *ngIf="activeAchievement.type === 'badge-goals' && activeAchievement.area === 'yoga'">{{ 'Yoga Goal Award' | uppercase | translate }}</h3>
    <h3 *ngIf="activeAchievement.type === 'badge-workout-streak' && activeAchievement.area === 'yoga'">{{ 'Yoga Streak Achievement' | uppercase | translate }}</h3>
    <h3 *ngIf="activeAchievement.type === 'week-streak'">{{ 'Weekly Participation Achievement' | uppercase | translate }}</h3>


     

      <a [routerLink]="['/achievements']" class="btn btn-rounded btn-secondary w-100 mt-4 mb-2" (click)="onCloseAward($event)">{{ 'View Achievements' | uppercase | translate }}</a>
    </div>
  </div>
</div>
