import { forkJoin } from "rxjs";
import { DateHelperService } from "src/app/services/date-helper.service";
import { UserSymptom } from "./../../models/user-symptom.model";
import { SymptomService } from "./../../services/symptom.service";
import { Component, OnInit } from "@angular/core";
import { ResourcesService } from "src/app/services/resources.service";

@Component({
  selector: "app-managing-index",
  templateUrl: "./managing-index.component.html",
  styleUrls: ["./managing-index.component.scss"],
})
export class ManagingIndexComponent implements OnInit {
  public isLoading = false;
  public userSymptoms!: UserSymptom[];
  public normalSymptoms!: UserSymptom | undefined;

  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public today = this.dateHelperService.getToday();
  public areaId = 0;
  public pages?:any[];
  public resources:any;
  public patientStories:any;
  public patientStoriesId = 0;

  constructor(
    private symptomService: SymptomService,
    private dateHelperService: DateHelperService,
    private resourcesService: ResourcesService
  ) {
    this.areaId = this.resourcesService.resourceIdMap['managing'];
    this.patientStoriesId = this.resourcesService.resourceIdMap['patientStories'];}

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin({
      allSymptoms: this.symptomService.fetchAll(),
      normalSymptoms: this.symptomService.fetchNormal(),
      resources: this.resourcesService.fetchArea(this.areaId),
      patientStories: this.resourcesService.fetchResource(this.patientStoriesId)
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.userSymptoms = responseData.allSymptoms;
        this.normalSymptoms = responseData.normalSymptoms;
        //this.pages = responseData.resource.pages;
        this.resources = responseData.resources;
        
        this.patientStories = responseData.patientStories.resource;
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }

  onSwiper(swiper: any) {}
  onSlideChange() {}
}
