import { EventEmitter, Input, Output } from "@angular/core";
import { UserAchievement } from "./../../models/user-achievement.model";
import { Component, OnInit } from "@angular/core";
import { AchievementService } from "src/app/services/achievement.service";

@Component({
  selector: "app-achievement-notification",
  templateUrl: "./achievement-notification.component.html",
  styleUrls: ["./achievement-notification.component.scss"],
})
export class AchievementNotificationComponent implements OnInit {
  @Input() notifications!: UserAchievement[];
  @Output() closeAward: EventEmitter<any> = new EventEmitter();
  activeAchievement!:UserAchievement;
  isLoading = false;

  constructor(private achievementService: AchievementService) {}

  ngOnInit(): void {
    this.isLoading = true;
    if(this.notifications[0]){
      this.activeAchievement = this.notifications[0];
      // set it to read
      this.achievementService.update(this.activeAchievement.user_achievement_id, 1).subscribe((responseData) => {
        // achievement updated!
      });
    }
  }
  onCloseAward(event:any) {
    this.closeAward.emit(event);
  }
}
