import { ActivatedRoute, Router } from "@angular/router";

import {
  AfterViewInit,
  Component,
  OnInit,
  NgModule,
  Compiler,
  ViewContainerRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "./../../../environments/environment";
import { ResourcesService } from "src/app/services/resources.service";

@Component({
  selector: "app-resource-page",
  templateUrl: "./resource-page.component.html",
  styleUrls: ["./resource-page.component.scss"],
})
export class ResourcePageComponent implements OnInit {
  pages: any;
  resourceId: any;
  resource: any;
  page: any;
  prevPage: any;
  nextPage: any;
  pageId: any;
  isLoading = false;
  fragment: any;
  area = "";
  environment = environment;
  languageId = 1;
  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) {
    if (this.route.snapshot.data["area"]) {
      this.area = this.route.snapshot.data["area"];
      this.resourceId = this.resourcesService.resourceIdMap[this.area];
    }
  }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
    this.route.params.subscribe((params) => {
      this.page = null;
      this.pages = null;
      this.pageId = this.route.snapshot.params.id;
      if (this.route.snapshot.params.resourceId) {
        this.resourceId = this.route.snapshot.params.resourceId;
        this.area = "managing";
      }
      this.isLoading = true;
      // timeout added here to force the video to be removed and reloaded.. Angular bug?
      setTimeout(() => {
      this.resourcesService.fetchResource(this.resourceId).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.pages = responseData.pages;
          this.pages.forEach((page: any, pIndex: any) => {
            if (+page.content_page_id === +this.pageId) {
              this.page = page;
              if (pIndex > 0) {
                this.prevPage = this.pages[pIndex - 1];
              } else {
                this.prevPage = null;
              }
              if (pIndex + 1 <= this.pages.length) {
                this.nextPage = this.pages[pIndex + 1];
              } else {
                this.nextPage = null;
              }
            }
          });
          this.resource = responseData.resource;

          setTimeout(() => {
            this.detectVideos();
          }, 1000);
          // set custom HTML title
          this.titleService.setTitle(
            environment.title + " | Resources | " + this.resource.title
          );

          // do we need to scroll?
          setTimeout(() => {
            try {
              if (this.fragment) {
                const element = document.querySelector("#" + this.fragment);
                if (element) {
                  setTimeout(() => {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest",
                    });
                  }, 500);
                }
              }
            } catch (e) {}
          }, 500);
        },
        (error) => {}
      );
    }, 200);
    });
  }

  onPrev() {
    if (this.prevPage) {
      this.page = null;
      this.router.navigate([
        "/resources",
        this.resourceId,
        this.prevPage.content_page_id,
      ]);
      this.prevPage = null;
      this.nextPage = null;
    }
  }
  onNext() {
    if (this.nextPage) {
      this.page = null;
      this.router.navigate([
        "/resources",
        this.resourceId,
        this.nextPage.content_page_id,
      ]);

      this.prevPage = null;
      this.nextPage = null;
    }
  }

  onContentClick(e: MouseEvent) {
    if (
      e.target instanceof HTMLAnchorElement &&
      e.target.host === location.host
    ) {
      e.preventDefault();
      this.router.navigateByUrl(e.target.pathname + e.target.hash);
    }
  }

  detectVideos() {
    var videos = document.querySelectorAll("video"); // get all videos using "video" css selector

    // remove old listeners
    videos.forEach((video) => {
      let elClone = video.cloneNode(true);
      video?.parentNode?.replaceChild(elClone, video);
    });

    /*Loop*/
    videos.forEach((video) => {
      video.addEventListener("play", (event) => {
        var allVideos = document.querySelectorAll("video");
        allVideos.forEach((vid) => {
          if (
            vid.getElementsByTagName("source")[0].src !==
            video.getElementsByTagName("source")[0].src
          ) {
            vid.pause();
          }
        });
      });
    });
  }

  // pause video on scroll
  
  @HostListener("window:scroll", ["$event"])
  onScroll(event: any) {
    const fraction = 0.8;
    var videos = document.querySelectorAll("video")
  
    videos.forEach((video) => {
      var x = video.offsetLeft, y = video.offsetTop, w = video.offsetWidth, h = video.offsetHeight, r = x + w, //right
            b = y + h, //bottom
            visibleX, visibleY, visible;

            visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
            visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));

            visible = visibleX * visibleY / (w * h);

            if (visible > fraction) {
                // video.play();
            } else {
                video.pause();
            }
    });
  }
}
