import { Observable } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { catchError, publishReplay, refCount } from "rxjs/operators";
import { map } from "rxjs/operators";
import { environment } from "./../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { DateHelperService } from "./date-helper.service";
import { Injectable } from "@angular/core";
import { UserWeeklyTarget } from "../models/user-weekly-target.model";
import { Subject, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WeeklyTargetService {
  error = new Subject<string>();
  slug = "/user_weekly_targets";
  resourceName = "user_weekly_targets";
  allTargets?: Observable<UserWeeklyTarget[]> | null = null;
  weekTarget?: any;
  cacheTime = new Date();
  cacheLifetime = 5*60*1000;

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchAll(): Observable<UserWeeklyTarget[]> {
    const now = new Date();
    if (!this.allTargets || (Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime)) {
      this.cacheTime = new Date();
      this.allTargets = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserWeeklyTarget[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allTargets;
  }

  fetchWeek(week: string) : Observable<UserWeeklyTarget>{
    if (!this.weekTarget) {
      this.weekTarget = {};
    }
    const now = new Date();
    if (!this.weekTarget[week] || (Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime)) {
      this.cacheTime = new Date();
      let searchParams = new HttpParams();

      searchParams = searchParams.append("week", week.toString());
      this.weekTarget[week] =  this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserWeeklyTarget[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            if (returnArray.length > 0) {
              return returnArray[0];
            } else {
              return undefined;
            }
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.weekTarget[week];
  }

  clearCache() {
    this.weekTarget = null;
    this.allTargets = null;
  }
}
