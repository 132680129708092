<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Add Strength Workout' | uppercase | translate"
    [icon]="'strength'"
    [backLink]="['/strength']"
  ></app-page-header>

  <div class="row">
    <div class="col">
      <form class="form-recharge" (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
          <label for="date text-center">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
            </span>
            {{ 'Date' | uppercase | translate}}</label
          >

          <input
            type="text"
            id="workout_date"
            class="form-control"
            [ngModel]="bsDate"
            #workout_date="ngModel"
            name="workout_date"
            placeholder="DD/MM/YYYY"
            required
            date
            bsDatepicker
            autocomplete="off"
            [maxDate]="maxDate"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY',
              containerClass: 'theme-recharge',
              showWeekNumbers: false,
              customTodayClass: 'datepicker-today'
            }"
          />

          <div
            class="alert alert-danger"
            *ngIf="!workout_date.valid && (submitted || submittedStep[step])"
          >
          {{ 'Please enter a date above' | uppercase | translate}}
          </div>
        </div>

        <div *ngIf="!exercisesSelected">
          <p class="text-start">{{ 'What exercises did you do?' | uppercase | translate}}</p>
          <ul class="pick-list">
            <li
              *ngFor="let exercise of exercises; let index = index"
              [ngClass]="{ active: exercisesPicked.indexOf(exercise) !== -1 }"
              (click)="onPickExercise(exercise)"
            >
              <span class="pick-icon">
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'circle']"
                    *ngIf="exercisesPicked.indexOf(exercise) == -1"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'check-circle']"
                    *ngIf="exercisesPicked.indexOf(exercise) !== -1"
                  ></fa-icon>
                </span>
              </span>
              <span class="pick-item">{{ exercise.label | uppercase | translate }}</span>
            </li>
            <li
              [ngClass]="{ active: exercises.length == exercisesPicked.length }"
              (click)="onPickAll()"
            >
              <span class="pick-icon">
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'circle']"
                    *ngIf="exercises.length != exercisesPicked.length"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'check-circle']"
                    *ngIf="exercises.length == exercisesPicked.length"
                  ></fa-icon>
                </span>
              </span>
              <span class="pick-item">{{ 'All of the above' | uppercase | translate }}</span>
            </li>
          </ul>

          
          <button class="btn btn-secondary rounded-pill w-100" (click)="onConfirmExercises()" [disabled]="exercisesPicked.length == 0">
            {{ 'Confirm Exercises' | uppercase | translate }}
          </button>
        </div>

        <div *ngIf="exercisesSelected">
          <div
            *ngFor="let exercise of exercisesPicked; let index = index"
            [hidden]="step != index"
          >
            <h2 class="form-subheading">
              {{ exercise.label  | uppercase | translate }}
              <br />
              <span class="form-subheading-aside"
                >{{ 'Exercise' | uppercase | translate }} ({{ step + 1 | convertDigits  }}/{{ exercisesPicked.length | convertDigits }})</span
              >
            </h2>

            <div class="form-group">
              <label for="date text-center"> {{ 'Weight (Kg)' | uppercase | translate }}</label>

              <input
                type="number"
                id="weight_{{ exercise.id }}"
                class="form-control"
                [(ngModel)]="exerciseData[exercise.id].weight"
                name="weight_{{ exercise.id }}"
                placeholder="{{'e.g. 1.5' | convertDigits}}"
                required
                step=".5"
              />

              <div
                class="alert alert-danger"
                *ngIf="!exerciseData[exercise.id].weight && (submitted || submittedStep[step])"
              >
              {{ 'Please enter a weight above' | uppercase | translate }}
              </div>
            </div>

            <div class="form-group">
              <label>{{ 'REPETITIONS' | uppercase | translate }}</label>
              <div class="col-sm-6 mb-2">
                <label class="sub-label">{{ 'Set 1' | uppercase | translate }}</label>
                <div
                  class="radio-group radio-group-text"
                  [ngClass]="{ chosen: set1_reps.dirty || set1_reps.touched }"
                >
                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set1"
                      #set1_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set1_reps_{{ exercise.id }}"
                      id="set1_reps1_{{ exercise.id }}"
                      value="0"
                    />
                    <label
                      class="form-check-label"
                      for="set1_reps1_{{ exercise.id }}"
                      >{{'0' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set1"
                      #set1_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set1_reps_{{ exercise.id }}"
                      id="set1_reps2_{{ exercise.id }}"
                      value="1-7"
                    />
                    <label
                      class="form-check-label"
                      for="set1_reps2_{{ exercise.id }}"
                      >{{'1-7' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set1"
                      #set1_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set1_reps_{{ exercise.id }}"
                      id="set1_reps3_{{ exercise.id }}"
                      value="8-12"
                    />
                    <label
                      class="form-check-label"
                      for="set1_reps3_{{ exercise.id }}"
                      >{{'8-12' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set1"
                      #set1_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set1_reps_{{ exercise.id }}"
                      id="set1_reps4_{{ exercise.id }}"
                      value="13+"
                    />
                    <label
                      class="form-check-label"
                      for="set1_reps4_{{ exercise.id }}"
                      >{{'13+' | convertDigits}} <span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="!set1_reps.valid && (submitted || submittedStep[step])"
                >
                  {{ 'Please select a value above' | uppercase | translate }}
                </div>
              </div>

              <div class="col-sm-6 mb-2">
                <label class="sub-label">{{ 'Set 2' | uppercase | translate }}</label>
                <div
                  class="radio-group radio-group-text"
                  [ngClass]="{ chosen: set2_reps.dirty || set2_reps.touched }"
                >
                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set2"
                      #set2_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set2_reps_{{ exercise.id }}"
                      id="set2_reps1_{{ exercise.id }}"
                      value="0"
                    />
                    <label
                      class="form-check-label"
                      for="set2_reps1_{{ exercise.id }}"
                      >{{'0' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set2"
                      #set2_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set2_reps_{{ exercise.id }}"
                      id="set2_reps2_{{ exercise.id }}"
                      value="1-7"
                    />
                    <label
                      class="form-check-label"
                      for="set2_reps2_{{ exercise.id }}"
                      >{{'1-7' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set2"
                      #set2_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set2_reps_{{ exercise.id }}"
                      id="set2_reps3_{{ exercise.id }}"
                      value="8-12"
                    />
                    <label
                      class="form-check-label"
                      for="set2_reps3_{{ exercise.id }}"
                      >{{'8-12' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set2"
                      #set2_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set2_reps_{{ exercise.id }}"
                      id="set2_reps4_{{ exercise.id }}"
                      value="13+"
                    />
                    <label
                      class="form-check-label"
                      for="set2_reps4_{{ exercise.id }}"
                      >13+ <span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="!set2_reps.valid && (submitted || submittedStep[step])"
                >
                  {{ 'Please select a value above' | uppercase | translate }}
                </div>
              </div>

              <div class="col-sm-6 mb-2">
                <label class="sub-label">{{ 'Set 3' | uppercase | translate }}</label>
                <div
                  class="radio-group radio-group-text"
                  [ngClass]="{ chosen: set3_reps.dirty || set3_reps.touched }"
                >
                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set3"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set3_reps_{{ exercise.id }}"
                      id="set3_reps1_{{ exercise.id }}"
                      value="0"
                    />
                    <label
                      class="form-check-label"
                      for="set3_reps1_{{ exercise.id }}"
                      >{{'0' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set3"
                      #set3_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set3_reps_{{ exercise.id }}"
                      id="set3_reps2_{{ exercise.id }}"
                      value="1-7"
                    />
                    <label
                      class="form-check-label"
                      for="set3_reps2_{{ exercise.id }}"
                      >{{'1-7' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set3"
                      #set3_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set3_reps_{{ exercise.id }}"
                      id="set3_reps3_{{ exercise.id }}"
                      value="8-12"
                    />
                    <label
                      class="form-check-label"
                      for="set3_reps3_{{ exercise.id }}"
                      >{{'8-12' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].set3"
                      #set3_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="set3_reps_{{ exercise.id }}"
                      id="set3_reps4_{{ exercise.id }}"
                      value="13+"
                    />
                    <label
                      class="form-check-label"
                      for="set3_reps4_{{ exercise.id }}"
                      >13+ <span>{{ 'reps' | uppercase | translate }}</span></label
                    >
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  *ngIf="!set3_reps.valid && (submitted || submittedStep[step])"
                >
                  {{ 'Please select a value above' | uppercase | translate }}
                </div>
              </div>

              <div class="col-sm-6 input-difficulty">
                <label class="sub-label">{{ 'Difficulty' | uppercase | translate }}</label>

                <div
                  class="radio-group"
                  [ngClass]="{ chosen: difficulty.dirty || difficulty.touched }"
                >
                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].difficulty"
                      #difficulty="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="difficulty_{{ exercise.id }}"
                      id="difficulty1_{{ exercise.id }}"
                      value="1"
                    />
                    <label
                      class="form-check-label"
                      for="difficulty1_{{ exercise.id }}"
                      ><span class="icon-wrap">
                        <fa-icon
                          [icon]="['fas', 'grin-stars']"
                        ></fa-icon> </span
                    ></label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].difficulty"
                      #difficulty="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="difficulty_{{ exercise.id }}"
                      id="difficulty2_{{ exercise.id }}"
                      value="2"
                    />
                    <label
                      class="form-check-label"
                      for="difficulty2_{{ exercise.id }}"
                      ><span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'smile']"></fa-icon> </span
                    ></label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].difficulty"
                      #difficulty="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="difficulty_{{ exercise.id }}"
                      id="difficulty3_{{ exercise.id }}"
                      value="3"
                    />
                    <label
                      class="form-check-label"
                      for="difficulty3_{{ exercise.id }}"
                      ><span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'meh']"></fa-icon> </span
                    ></label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].difficulty"
                      #difficulty="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="difficulty_{{ exercise.id }}"
                      id="difficulty4_{{ exercise.id }}"
                      value="4"
                    />
                    <label
                      class="form-check-label"
                      for="difficulty4_{{ exercise.id }}"
                      ><span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'frown']"></fa-icon> </span
                    ></label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].difficulty"
                      #difficulty="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="difficulty_{{ exercise.id }}"
                      id="difficulty5_{{ exercise.id }}"
                      value="5"
                    />
                    <label
                      class="form-check-label"
                      for="difficulty5_{{ exercise.id }}"
                      ><span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'tired']"></fa-icon> </span
                    ></label>
                  </div>
                </div>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="!difficulty.valid && (submitted || submittedStep[step])"
              >
                {{ 'Please select a value above' | uppercase | translate }}
              </div>
            </div>
          </div>

          <div *ngIf="step === exercisesPicked.length">
            <h2 class="form-subheading">{{ 'Summary' | uppercase | translate }}</h2>
            <div class="card card-widget mt-4 mb-2 bg-primary-accent">
              <div class="card-body">
                <div class="row mt-0">
                  <ng-container
                    *ngFor="let exercise of exercisesPicked; let index = index"
                  >
                  <div
                  class="mb-4 col-6 col-md-3 text-start"
                  *ngIf="exerciseData[exercise.id]">
                    <h5 class="card-title text-start mb-1 mt-0">
                      {{ exercise.label | uppercase | translate }}
                    </h5>
                    <p class="mb-1">
                      {{ 'Weight (KG)' | uppercase | translate }}:
                      <strong>{{ exerciseData[exercise.id].weight| convertDigits }}{{ 'Kg' | uppercase | translate }}</strong
                      ><br />
                      {{ 'Set 1' | uppercase | translate }}:
                      <strong>{{ exerciseData[exercise.id].set1 | convertDigits}} {{ 'reps' | uppercase | translate }}</strong
                      ><br />
                      {{ 'Set 2' | uppercase | translate }}:
                      <strong>{{ exerciseData[exercise.id].set2| convertDigits }} {{ 'reps' | uppercase | translate }}</strong
                      ><br />
                      {{ 'Set 3' | uppercase | translate }}:
                      <strong>{{ exerciseData[exercise.id].set3 | convertDigits}} {{ 'reps' | uppercase | translate }}</strong
                      ><br />
                      {{ 'Difficulty' | uppercase | translate }}:
                      <app-difficulty-icon
                        [difficulty]="+exerciseData[exercise.id].difficulty"
                      ></app-difficulty-icon>
                    </p>
                  </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="exercisesSelected">
          <div class="text-center">
            <span *ngFor="let exercise of exercisesPicked; let index = index">
              <span
                class="icon-wrap mx-1"
                [ngClass]="{
                  'text-primary': index == step,
                  'text-secondary-accent': index != step
                }"
              >
                <fa-icon [icon]="['fas', 'circle']"></fa-icon>
              </span>
            </span>
            <span
              class="icon-wrap mx-1"
              [ngClass]="{
                'text-primary': exercisesPicked.length == step,
                'text-secondary-accent': exercisesPicked.length != step
              }"
            >
              <fa-icon [icon]="['fas', 'circle']"></fa-icon>
            </span>
            &nbsp;
          </div>

          <div class="row" *ngIf="step < exercisesPicked.length">
            <div class="col">
              <button
                class="btn btn-secondary rounded-pill w-100"
                *ngIf="step > 0"
                type="button"
                (click)="onPrev()"
              >
              {{ 'Previous' | uppercase | translate }}
              </button>
            </div>
            <div class="col">
              <button
                class="btn btn-secondary rounded-pill w-100"
                type="button"
                (click)="onNext()"
              >
              {{ 'Next' | uppercase | translate }}
              </button>
            </div>
          </div>

          <div class="row" *ngIf="step === exercisesPicked.length">
            <div class="col">
              <button
                class="btn btn-secondary rounded-pill w-100"
                *ngIf="step > 0"
                type="button"
                (click)="onPrev()"
              >
              {{ 'Previous' | uppercase | translate }}
              </button>
            </div>
            <div class="col">
              <button class="btn btn-secondary rounded-pill w-100">
                {{ 'Save Strength Workout' | uppercase | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
