import { defineLocale } from 'ngx-bootstrap/chronos';
import { YogaWorkoutService } from "./../../services/yoga-workout.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { AchievementService } from "src/app/services/achievement.service";
import * as moment from "moment";
import { Observable } from "rxjs";
import { ComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import { TranslateService } from "@ngx-translate/core";
import { BsLocaleService } from "ngx-bootstrap/datepicker";


import { hiLocale } from 'ngx-bootstrap/locale';
import { mrLocale } from 'src/app/i18n/mr';
defineLocale('hindi', hiLocale);
defineLocale('marathi', mrLocale);

@Component({
  selector: "app-add-yoga-workout",
  templateUrl: "./add-yoga-workout.component.html",
  styleUrls: ["./add-yoga-workout.component.scss"],
})
export class AddYogaWorkoutComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  maxDate = new Date();
  bsDate = new Date();
  exercisesPicked: any = [];
  exercisesSelected = false;
  submittedStep = [false, false, false, false];

  // tadasana, Bhujanga,Marjarasana, Ardh Katichakrasana,
  // Anuloma Vilom Pranayama, Ujjayi Pranayam, Bhramari Pranayama,
  exercises = [
        { id: "tadasana", label: "Tadasana", type: "asana" },
        { id: "bhujanga", label: "Bhujanga", type: "asana" },
        { id: "marjarasana", label: "Marjarasana", type: "asana" },
        { id: "ardhKatichakrasana", label: "Ardh Katichakrasana", type: "asana" },
        { id: "AnulomaVilomPranayama", label: "Anuloma Vilom Pranayama", type: "pranayama" },
        { id: "UjjayiPranayama", label: "Ujjayi Pranayama", type: "pranayama" },
        { id: "BhramariPranayama", label: "Bhramari Pranayama", type: "pranayama" },
        { id: "meditation", label: "meditation", type: "meditation" },
  ];
  step = 0;
  exerciseData: { [key: string]: any } = {
    tadasana: { secs: '',  difficulty: null },
    bhujanga: { secs: '',  difficulty: null },
    marjarasana: { secs: '',  difficulty: null },
    ardhKatichakrasana: { secs: '',  difficulty: null },
    AnulomaVilomPranayama: { reps: '',  difficulty: null },
    UjjayiPranayama: { reps: '',  difficulty: null },
    BhramariPranayama: { reps: '',  difficulty: null },
    meditation: { secs: '',  difficulty: null },

  };

  constructor(
    private yogaWorkoutService: YogaWorkoutService,
    private router: Router,
    private achievementService: AchievementService,
    private route: ActivatedRoute,
    private el: ElementRef,
    private translate: TranslateService,
    private localeService: BsLocaleService
  ) {}

  ngOnInit(): void {
    // get the date if set
    this.route.queryParams.subscribe((params: any) => {
      if (params.d) {
        this.bsDate = moment(params.d).toDate();
      }
    });
    
    if(this.translate.currentLang == 'hindi'){
      this.localeService.use(this.translate.currentLang);
    }
    else if(this.translate.currentLang == 'marathi'){
      this.localeService.use(this.translate.currentLang);
    }
    else{
      this.localeService.use('en');
    }
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: this.translate.instant("THIS FORM IS INVALID"),
        text: this.translate.instant("PLEASE CHECK THE FORM FOR ERRORS"),
        showConfirmButton: true,
      });
    } else {
      // create entry
      const resourceIds = [];
      this.yogaWorkoutService
        .create(
          this.entryForm?.value.workout_date,
          this.exerciseData
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: this.translate.instant("ENTRY CREATED"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/yoga"]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            console.log(error);
            this.error = error.message;
          }
        );
    }
  }

  onNext() {
    if (
      (!this.exerciseData[this.exercisesPicked[this.step].id].reps && !this.exerciseData[this.exercisesPicked[this.step].id].secs) ||
      !this.exerciseData[this.exercisesPicked[this.step].id].difficulty
    ) {
      
      Swal.fire({
        icon: "error",
        title: this.translate.instant("THIS FORM IS INVALID"),
        text: this.translate.instant("PLEASE CHECK THE FORM FOR ERRORS"),
        showConfirmButton: true,
        didClose: () => this.scrollToFirstInvalidControl(),
      });

      this.submittedStep[this.step] = true;
    } else {
      this.step++;
    }
  }
  onPrev() {
    this.step--;
  }


  onPickExercise(exercise:any) {
    if (this.exercisesPicked.indexOf(exercise) !== -1) {
      this.exercisesPicked.splice(this.exercisesPicked.indexOf(exercise), 1);
    } else {
      this.exercisesPicked.push(exercise);
    }
  }
  onPickAll(){
    this.exercisesPicked = [];
    this.exercises.forEach((exercise) => {
      this.exercisesPicked.push(exercise);
    });
  }
  onConfirmExercises(){
    this.exercisesSelected = true;
  }

  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector("form .ng-invalid");

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth",
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.submitted) {
      return true;
    } else if (
      !this.entryForm?.value.asana_time &&
      !this.entryForm?.value.asana_difficulty &&
      !this.entryForm?.value.pranayama_reps &&
      !this.entryForm?.value.pranayama_difficulty
    ) {
      return true;
    }
  }
}
