import { PendingChangesGuard } from './guards/pending-changes.guard';
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { HomeComponent } from "./home/home.component";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from "ngx-cookieconsent";
import { LoginComponent } from "./auth/login/login.component";

import { interceptorProviders } from "./interceptors";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";

import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
export function provideSwal() {
  return import("sweetalert2/src/sweetalert2.js"); // instead of import('sweetalert2')
}


import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
     // console.log('translation missing : ' + params.key.charAt(0).toUpperCase() + params.key.toLowerCase().slice(1));
        return params.key.charAt(0).toUpperCase() + params.key.toLowerCase().slice(1);
    }
}

import localeHi from '@angular/common/locales/hi';
registerLocaleData(localeHi);

import localeMr from '@angular/common/locales/mr';
registerLocaleData(localeMr);

import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;
import {
  FaIconLibrary,
  FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
import {
  faChevronRight as falChevronRight,
  faChevronLeft as falChevronLeft,
  faArrowAltRight as falArrowAltRight,
  faLockAlt as falLockAlt,
  faStar as falStar,
  faStopwatch as falStopwatch,
  faHeadSideBrain as falHeadSideBrain,
  faWeight as falWeight,
  faSmokingBan as falSmokingBan,
  faPaperPlane as falPaperPlane,
  faUser as falUser,
  faKey as falKey,
  faTasks as falTasks,
  faChartLine as falChartLine,
  faBookReader as falBookReader,
  faUserMdChat as falUserMdChat,
  faEllipsisH as falEllipsisH,
  faBullseyePointer as falBullseyePointer,
  faVideo as falVideo,
  faFileAlt as falFileAlt,
  faMousePointer as falMousePointer,
  faAngleDown as falAngleDown,
  faAngleUp as falAngleUp,
  faHashtag as falHashtag,
  faDumbbell as falDumbbell,
  faThermometerHalf as falThermometerHalf,
  faThermometerEmpty as falThermometerEmpty,
  faThermometerFull as falThermometerFull,
  faHeadSideCough as falHeadSideCough,
  faCouch as falCouch,
  faLungs as falLungs,
  faSnooze as falSnooze,
  faCommentAltLines as falCommentsAltLines,
  faHeadset as falHeadset,
  faTrash as falTrash,
  faSadTear as falSadTear,
  faBoxTissue as falBoxTissue,
  faHeart as falHeart,
  faSmile as falSmile,
  faBolt as falBolt,
  faComments as falComments,
  faCircle as falCircle,
  faSquare as falSquare,
  faInfoCircle as falInfoCircle,
  faUsers as falUsers,
  faHospitalAlt as falHospitalAlt,
  faBalanceScale as falBalanaceScale,
  faFingerprint as falFingerprint,
  faMobile as falMobile,
  faSpinner as falSpinner,
  faArrowRight as falArrowRight,
  faArrowLeft as falArrowLeft
  // faArrowCircleRight as falArrowCircleRight
  // faRedoAlt as falRedoAlt
  // faBars as falBars
} from "@fortawesome/pro-light-svg-icons";
import {
  faCheck as farCheck,
  faTimes as farTimes,
  faBars as farBars,
  faRedoAlt as farRedoAlt,
  faArrowCircleRight as farArrowCircleRight,
  faArrowCircleLeft as farArrowCircleLeft,
  faChevronLeft as farChevronLeft,
  faChevronDown as farChevronDown,
  faCircle as farCircle,
  faArrowAltRight as farArrowAltRight,
  faArrowAltLeft as farArrowAltLeft,
  faArrowsAltV as farArrowsAltV,
  faCalendarAlt as farCalendarAlt,
  faFrownOpen as farFrownOpen,
  faUtensils as farUtensils,
  faHeadSideBrain as farHeadSideBrain,
  faMeh as farMeh,
  faWalking as farWalking,
  faFileCertificate as farFileCertificate,
  faFileDownload as farFileDownload,
  faSearch as farSearch,
  faVideo as farVideo,
  faFileAlt as farFileAlt,
  faMousePointer as farMousePointer,
  faCameraAlt as farCameraAl,
  faMicrophoneStand as farMicrophoneStand,
  faThermometerHalf as farThermometerHalf,
  faBullseyePointer as farBullseyePointer,
  faInfoCircle as farInfoCircle,
  faUsers as farUsers,
  faHospitalAlt as farHospitalAlt,
  faBalanceScale as farBalanaceScale,
  faFingerprint as farFingerprint,
  faPenAlt as farPenAlt,
  faMobile as farMobile,
  faHeadSideCough as farHeadSideCough,
  faCouch as farCouch,
  faLungs as farLungs,
  faSnooze as farSnooze,
  faPlayCircle as farPlayCircle,
  faQuestionCircle as farQuestionCircle,
  faHeadSideMedical as farHeadSideMedical,
  faTint as farTint,
  faPlus as farPlus,
  faMinus as farMinus
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck as fasCheck,
  faArrowUp as fasArrowUp,
  faArrowDown as fasArrowDown,
  faBars as fasBars,
  faCircle as fasCircle,
  faCheckCircle as fasCheckCircle,
  faArrowAltRight as fasArrowAltRight,
  faArrowAltLeft as fasArrowAltLeft,
  faCheckSquare as fasCheckSquare,
  faStar as fasStar,
  faBullseyeArrow as fasBullseyeArrow,
  faTasks as fasTasks,
  faBadgeCheck as fasBadgeCheck,
  faHeartbeat as fasHeartbeat,
  faPencilAlt as fasPencilAlt,
  faBookReader as fasBookReader,
  faAlignLeft as fasAlignLeft,
  faTimes as fasTimes,
  faHourglassHalf as fasHourglassHalf,
  faCalendarAlt as fasCalendarAlt,
  faStopwatch as fasStopwatch,
  faArrowsH as fasArrowsH,
  faEllipsisV as fasEllipsisV,
  faThermometerHalf as fasThermometerHalf,
  faTemperatureUp as fasTemperatureUp,
  faTemperatureDown as fasTemperatureDown,
  faMeh as fasMeh,
  faComment as fasComment,
  faTired as fasTired,
  faFrown as fasFrown,
  faSmile as fasSmile,
  faGrinStars as fasGrinStars,
  faCaretDown as fasCaretDown,
  faStars as fasStars
} from "@fortawesome/pro-solid-svg-icons";
import { ForgottenPasswordComponent } from "./auth/forgotten-password/forgotten-password.component";
import { LoadingSpinnerComponent } from "./shared/loading-spinner/loading-spinner.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { RegisterComponent } from "./auth/register/register.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { AsideMenuComponent } from "./aside-menu/aside-menu.component";
import { AddToHomescreenComponent } from "./add-to-homescreen/add-to-homescreen.component";
import { FooterNavComponent } from "./footer-nav/footer-nav.component";
import { FooterComponent } from "./footer/footer.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { MoreComponent } from "./more/more.component";
import { HeaderBarComponent } from "./header-bar/header-bar.component";

// bootstrap
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TabsModule } from "ngx-bootstrap/tabs";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { UpdateDetailsComponent } from "./auth/update-details/update-details.component";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";

import { CheckPasswordDirective } from "./directives/check-password.directive";
import { UploadAvatarComponent } from "./auth/upload-avatar/upload-avatar.component";
import { OfflineComponent } from "./offline/offline.component";
import { IconComponent } from "./shared/icon/icon.component";
import { WalkingIndexComponent } from "./walking/walking-index/walking-index.component";

import { SwiperModule } from "swiper/angular";
import { WalkingWorkoutsComponent } from "./walking/walking-workouts/walking-workouts.component";
import { PageHeaderComponent } from "./shared/page-header/page-header.component";
import { AddWalkingEntryComponent } from "./walking/add-walking-entry/add-walking-entry.component";
import { StrengthIndexComponent } from "./strength/strength-index/strength-index.component";
import { YogaIndexComponent } from "./yoga/yoga-index/yoga-index.component";
import { ManagingIndexComponent } from "./managing/managing-index/managing-index.component";
import { AddGoalComponent } from "./goals/add-goal/add-goal.component";
import { AchievementBadgeComponent } from "./achievements/achievement-badge/achievement-badge.component";
import { DifficultyIconComponent } from "./shared/difficulty-icon/difficulty-icon.component";
import { AddStrengthWorkoutComponent } from "./strength/add-strength-workout/add-strength-workout.component";
import { StrengthWorkoutsComponent } from "./strength/strength-workouts/strength-workouts.component";
import { AddYogaWorkoutComponent } from "./yoga/add-yoga-workout/add-yoga-workout.component";
import { YogaWorkoutsComponent } from "./yoga/yoga-workouts/yoga-workouts.component";
import { SetNormalSymptomsComponent } from "./managing/set-normal-symptoms/set-normal-symptoms.component";
import { UpdateSymptomsComponent } from "./managing/update-symptoms/update-symptoms.component";
import { SymptomsComponent } from "./managing/symptoms/symptoms.component";
import { SymptomEntryComponent } from "./managing/symptom-entry/symptom-entry.component";
import { EditWalkingEntryComponent } from "./walking/edit-walking-entry/edit-walking-entry.component";
import { EditStrengthWorkoutComponent } from "./strength/edit-strength-workout/edit-strength-workout.component";
import { EditYogaWorkoutComponent } from "./yoga/edit-yoga-workout/edit-yoga-workout.component";
import { ResourcePageComponent } from "./resources/resource-page/resource-page.component";
import { GetThumbnailPipe } from "./pipes/get-thumbnail.pipe";
import { ResourceIndexComponent } from "./resources/resource-index/resource-index.component";
import { GoalsAreaComponent } from "./goals/goals-area/goals-area.component";
import { EditGoalComponent } from "./goals/edit-goal/edit-goal.component";
import { AchievementsIndexComponent } from "./achievements/achievements-index/achievements-index.component";
import { AchievementNotificationComponent } from "./achievements/achievement-notification/achievement-notification.component";
import { SiteContentPageComponent } from './site-content/site-content-page/site-content-page.component';
import { SearchComponent } from './search/search.component';
import { SetWalkingTargetComponent } from './walking/set-walking-target/set-walking-target.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { registerLocaleData } from '@angular/common';
import { ConvertDigitsPipe } from './pipes/convert-digits.pipe';
import { LangSwitcherComponent } from './shared/lang-switcher/lang-switcher.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  palette: {
    popup: {
      background: "#000",
    },
    button: {
      background: "#rgb(86, 170, 244)",
    },
  },
  theme: "block",
  type: "opt-in",
  content: {
    message: "This website uses cookies for analytics. ",
    dismiss: "Go away",
    deny: "No thanks",
    allow: "That's fine",
    link: "Learn more",
    href: "https://cookiesandyou.com",
  },
};

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HomeComponent,
    LoginComponent,
    ForgottenPasswordComponent,
    LoadingSpinnerComponent,
    ResetPasswordComponent,
    RegisterComponent,
    NavbarComponent,
    AsideMenuComponent,
    AddToHomescreenComponent,
    FooterNavComponent,
    FooterComponent,
    SidebarComponent,
    MoreComponent,
    HeaderBarComponent,
    DashboardComponent,
    UpdateDetailsComponent,
    ChangePasswordComponent,
    CheckPasswordDirective,
    UploadAvatarComponent,
    OfflineComponent,
    SafeHtmlPipe,
    IconComponent,
    WalkingIndexComponent,
    WalkingWorkoutsComponent,
    PageHeaderComponent,
    AddWalkingEntryComponent,
    StrengthIndexComponent,
    YogaIndexComponent,
    ManagingIndexComponent,
    AddGoalComponent,
    AchievementBadgeComponent,
    DifficultyIconComponent,
    AddStrengthWorkoutComponent,
    StrengthWorkoutsComponent,
    AddYogaWorkoutComponent,
    YogaWorkoutsComponent,
    SetNormalSymptomsComponent,
    UpdateSymptomsComponent,
    SymptomsComponent,
    SymptomEntryComponent,
    EditWalkingEntryComponent,
    EditStrengthWorkoutComponent,
    EditYogaWorkoutComponent,
    ResourcePageComponent,
    GetThumbnailPipe,
    ResourceIndexComponent,
    GoalsAreaComponent,
    EditGoalComponent,
    AchievementsIndexComponent,
    AchievementNotificationComponent,
    SiteContentPageComponent,
    SearchComponent,
    SetWalkingTargetComponent,
    LocalDatePipe,
    ConvertDigitsPipe,
    LangSwitcherComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    FontAwesomeModule,
    SweetAlert2Module.forRoot({
      provideSwal,
    }),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    SwiperModule,
    PopoverModule.forRoot(),
    TranslateModule.forRoot({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
    }
  })
  ],
  providers: [interceptorProviders, PendingChangesGuard],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      falChevronRight,
      falChevronLeft,
      falArrowAltRight,
      farCheck,
      farTimes,
      falLockAlt,
      falStar,
      falStopwatch,
      falHeadSideBrain,
      falWeight,
      falSmokingBan,
      falPaperPlane,
      falUser,
      falKey,
      falTasks,
      falChartLine,
      falBookReader,
      falUserMdChat,
      falEllipsisH,
      falBullseyePointer,
      farBars,
      falVideo,
      falFileAlt,
      falMousePointer,
      farRedoAlt,
      farArrowCircleRight,
      farArrowCircleLeft,
      farChevronLeft,
      farCircle,
      farArrowAltRight,
      fasArrowAltRight,
      fasCheck,
      farArrowsAltV,
      falAngleDown,
      falAngleUp,
      farCalendarAlt,
      falHashtag,
      falDumbbell,
      falThermometerHalf,
      falThermometerEmpty,
      falThermometerFull,
      falHeadSideCough,
      falCouch,
      falLungs,
      falSnooze,
      farFrownOpen,
      farMeh,
      farUtensils,
      farHeadSideBrain,
      falCommentsAltLines,
      falHeadset,
      falTrash,
      fasArrowUp,
      fasArrowDown,
      farWalking,
      farFileCertificate,
      farFileDownload,
      farChevronDown,
      falSadTear,
      fasCircle,
      falBoxTissue,
      falHeart,
      falSmile,
      falBolt,
      falComments,
      fasCheckCircle,
      falCircle,
      fasCheckSquare,
      falSquare,
      farSearch,
      farArrowAltLeft,
      farVideo,
      farFileAlt,
      farMousePointer,
      farCameraAl,
      farMicrophoneStand,
      farThermometerHalf,
      farBullseyePointer,
      farInfoCircle,
      farUsers,
      farHospitalAlt,
      farBalanaceScale,
      farFingerprint,
      falInfoCircle,
      falUsers,
      falHospitalAlt,
      falBalanaceScale,
      falFingerprint,
      farPenAlt,
      falMobile,
      farMobile,
      farHeadSideCough,
      farCouch,
      farLungs,
      farSnooze,
      falSpinner,
      fasBullseyeArrow,
      fasStar,
      fasTasks,
      fasBars,
      fasBadgeCheck,
      fasHeartbeat,
      fasPencilAlt,
      fasBookReader,
      fasAlignLeft,
      farPlayCircle,
      farQuestionCircle,
      fasArrowAltLeft,
      fasTimes,
      fasHourglassHalf,
      fasCalendarAlt,
      fasStopwatch,
      fasArrowsH,
      fasEllipsisV,
      fasThermometerHalf,
      fasTemperatureUp,
      fasTemperatureDown,
      fasMeh,
      fasComment,
      fasTired,
      fasFrown,
      fasMeh,
      fasSmile,
      fasGrinStars,
      fasCaretDown,
      farHeadSideMedical,
      farTint,
      fasStars,
      falArrowRight,
      falArrowLeft,
      farPlus,
      farMinus
    );
  }
}
