import { UserSymptom } from "./../models/user-symptom.model";
import { map, catchError, publishReplay, refCount } from "rxjs/operators";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { DateHelperService } from "./date-helper.service";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class SymptomService {
  error = new Subject<string>();
  slug = "/user_symptoms";
  resourceName = "user_symptoms";
  weeklyTarget = {
    threshold: 10,
    days: 5,
  };

  allSymptoms?: Observable<UserSymptom[]> | null;
  normalSymptoms?: Observable<UserSymptom> | undefined |  null;
  symptomsBetween?: any;

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchAll() : Observable<UserSymptom[]> {
    if (!this.allSymptoms) {
      this.allSymptoms = this.http
      .get<any>(environment.apiUrl + this.slug, {
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          const returnArray: UserSymptom[] = [];
          responseData["_embedded"][this.resourceName].forEach((item: any) => {
            returnArray.push(item);
          });
          return returnArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.allSymptoms;
  }


  fetchBetween(startDate: Date, endDate: Date) : Observable<UserSymptom[]> {

    if (!this.symptomsBetween) {
      this.symptomsBetween = {};
    }

    if (
      !this.symptomsBetween[
        this.dateHelperService.formatDate(startDate, "YYYY-MM-DD") +
          "_" +
          this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
      ]
    ) {


    let searchParams = new HttpParams();

    searchParams = searchParams.append(
      "startDate",
      this.dateHelperService.formatDate(startDate, "YYYY-MM-DD")
    );
    searchParams = searchParams.append(
      "endDate",
      this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
    );
    this.symptomsBetween[
      this.dateHelperService.formatDate(startDate, "YYYY-MM-DD") +
        "_" +
        this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
    ] =  this.http
      .get<any>(environment.apiUrl + this.slug, {
        params: searchParams,
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          const returnArray: UserSymptom[] = [];
          responseData["_embedded"][this.resourceName].forEach((item: any) => {
            returnArray.push(item);
          });
          return returnArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        }),
          publishReplay(1),
          refCount()
        );
    }
    return this.symptomsBetween[
      this.dateHelperService.formatDate(startDate, "YYYY-MM-DD") +
        "_" +
        this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
    ];
  }

  fetchNormal() : Observable<UserSymptom> | undefined {
    if (!this.normalSymptoms) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append("normal", "1");
    this.normalSymptoms =  this.http
      .get<any>(environment.apiUrl + this.slug, {
        params: searchParams,
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          const returnArray: UserSymptom[] = [];
          if (responseData["_embedded"][this.resourceName][0]) {
            return responseData["_embedded"][this.resourceName][0];
          } else return undefined;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.normalSymptoms;
  }

  clearCache() {
    this.allSymptoms = null;
    this.symptomsBetween = null;
    this.normalSymptoms = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + "/" + id, {
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          const item = new UserSymptom(
            +responseData.user_symptom_id,
            +responseData.user_id,
            +responseData.normal,
            responseData.symptom_date,
            +responseData.breathlessness_rest,
            +responseData.breathlessness_active,
            +responseData.sputum,
            +responseData.sputum_colour,
            +responseData.wheeze,
            +responseData.fever,
            +responseData.wellbeing,
            +responseData.total_up,
            +responseData.total_down,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    breathlessness_rest: number,
    breathlessness_active: number,
    sputum: number,
    sputum_colour: number,
    wheeze: number,
    fever: number,
    wellbeing: number
  ) {
    this.clearCache();
    const payload = {
      breathlessness_rest,
      breathlessness_active,
      sputum,
      sputum_colour,
      wheeze,
      fever,
      wellbeing,
    };
    return this.http.post<UserSymptom>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: "response",
      }
    );
  }


  setNormal(
    breathlessness_rest: number,
    breathlessness_active: number,
    sputum: number,
    sputum_colour: number,
    wheeze: number,
    fever: number
  ) {
    this.clearCache();
    const payload = {
      breathlessness_rest,
      breathlessness_active,
      sputum,
      sputum_colour,
      wheeze,
      fever,
      normal: 1
    };
    return this.http.post<UserSymptom>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: "response",
      }
    );
  }

  update(
    id: number,
    breathlessness_rest: number,
    breathlessness_active: number,
    sputum: number,
    sputum_colour: number,
    wheeze: number,
    fever: number,
    wellbeing: number
  ) {
    this.clearCache();
    const payload = {
      breathlessness_rest,
      breathlessness_active,
      sputum,
      sputum_colour,
      wheeze,
      fever,
      wellbeing,
    };
    return this.http.patch<UserSymptom>(
      environment.apiUrl + this.slug + "/" + id,
      payload,
      {
        observe: "response",
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + "/" + id
    );
  }

  getMonths(entries:UserSymptom[]){
    let months:any = [];
    entries.forEach((entry) => {
      if(months.indexOf(moment(entry.symptom_date).format('MMMMYY')) === -1){
        months.push(moment(entry.symptom_date).format('MMMMYY'));
      }
    });
    return months;
  }

}
