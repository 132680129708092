<div class="bg-section"></div>
<img
  src="/assets/img/bg-managing.jpg"
  alt="placeholder photo"
  class="img-sticky d-block d-md-none"
/>
<div class="content-box fh">
  <h1>
    <span class="icon-wrap icon-heading-managing">
      <app-icon [icon]="'symptoms'"></app-icon>
    </span>
    {{ 'Managing' | uppercase | translate }}
  </h1>

  <div class="row mb-3">
    <div class="col-12 col-md-12 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'thermometer-half']"></fa-icon>
            </span>
            <span class="float-end">
              <a [routerLink]="['/managing', 'symptoms']" *ngIf="normalSymptoms && userSymptoms.length > 0">
                <span class="icon-wrap text-secondary-accent icon-circle">
                  <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon> </span
              ></a>
            </span>
            
            {{ 'Symptoms' | uppercase | translate }}
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">

          <div *ngIf="!normalSymptoms">
            <p>{{ 'Please set your usual symptoms to begin.' | uppercase | translate }}</p>
          </div>
          <div *ngIf="normalSymptoms">

            <div *ngIf="userSymptoms.length == 0">
              <p>{{ 'YOU HAVE NOT UPDATED YOUR SYMPTOMS YET. IF YOUR SYMPTOMS CHANGE PLEASE REPORT THOSE CHANGES HERE.' | uppercase | translate }}</p>
            </div>
            <div *ngIf="userSymptoms.length > 0">
            <p>{{ 'Changes compared to your usual levels.' | uppercase | translate }}</p>
            <div class="row">
              <div class="col">
                <div class="symptom-status">
                  <div class="symptom-status-label">{{ 'Increased' | uppercase | translate }}</div>
                  <div class="symptom-status-box">
                    <div class="symptom-status-icon text-danger">
                      <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
                    </div>
                    <div class="symptom-status-value">{{userSymptoms[0].total_up | convertDigits}}</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="symptom-status">
                  <div class="symptom-status-label">{{ 'Usual' | uppercase | translate }}</div>
                  <div class="symptom-status-box">
                    <div class="symptom-status-icon text-warning">
                      <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
                    </div>
                    <div class="symptom-status-value">{{userSymptoms[0].total_down | convertDigits}}</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="symptom-status">
                  <div class="symptom-status-label">{{ 'Wellbeing' | uppercase | translate }}</div>
                  <div class="symptom-status-box">
                    <div class="symptom-status-icon">
                      <fa-icon [icon]="['far', 'head-side-medical']"></fa-icon>
                    </div>
                    <div class="symptom-status-value"><span class="wellbeing-circle bg-symptom-{{+userSymptoms[0].wellbeing+1}}"><span class="visually-hidden">{{userSymptoms[0].wellbeing | convertDigits}}</span></span></div>
                  </div>
                </div>
              </div>
            </div>
  
            <p class="subtext">{{ 'Last updated' | uppercase | translate }}: {{userSymptoms[0].symptom_date | localDate : 'EEE d MMM'}}</p>
            </div>
          </div>
        </div>
        </div>

        <div class="card-footer bg-secondary relative">
          <a
            [routerLink]="['/managing', 'update-symptoms']"
            class="card-link stretched-link" 
            *ngIf="normalSymptoms"
            >{{ 'Update Symptoms' | uppercase | translate }}</a
          ><a
          [routerLink]="['/managing', 'set-normal-symptoms']"
          class="card-link stretched-link" 
          *ngIf="!normalSymptoms"
          >{{ 'Set Usual Symptoms' | uppercase | translate }}</a
        >
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12 mb-4">
      <div class="card bg-light">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'book-reader']"></fa-icon>
            </span>
            {{ 'Resources' | uppercase | translate }}
          </h2>

          <div class="resource-link-container">
          <div *ngFor="let resource of resources" class="resource-link">
            <a
                class="task"
                [routerLink]="[
                  '/managing',
                  'resources',
                  resource.content_section_id
                ]"
              >
              <span class="resource-link-gradient"></span>
            <img
            *ngIf="resource?.image_filename"
            src="{{ resource.image_filename | getThumbnail: 'medium-landscape' }}"
            alt="Placeholder image"
            class="img-fluid rounded-lg"
          />
          <span class="resource-link-title">
            {{resource.title | uppercase | translate}}
            <span
            class="icon-wrap text-secondary-accent"
          >
            <fa-icon
              [icon]="['fas', 'arrow-alt-right']"
            ></fa-icon>
          </span></span>
          
          </a>
          </div>
          
          <ul class="task-list task-list-todo task-list-bt">
            <li *ngFor="let page of pages">
              <a
                class="task"
                [routerLink]="[
                  '/managing',
                  'resources',
                  page.content_page_id
                ]"
              >
                <span class="num">
                  <span class="icon-wrap">
                    <fa-icon
                      *ngIf="!page.icon"
                      [icon]="['fas', 'align-left']"
                      [fixedWidth]="true"
                    ></fa-icon>
                    <fa-icon
                      *ngIf="page.icon"
                      [icon]="['far', page.icon]"
                      [fixedWidth]="true"
                    ></fa-icon>
                  </span>
                </span>
                <span class="title">{{ page.title }}</span>
                <span class="status">
                  <span class="icon-wrap text-secondary-accent">
                    <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      </div>
    </div>

    <!--div class="col-12 col-md-6 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'comment']"></fa-icon>
            </span>
            <span class="float-end">
              <span class="icon-wrap text-secondary-accent icon-circle">
                <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
              </span>
            </span>
            Patient Stories
          </h2>
          <img
          *ngIf="patientStories?.image_filename"
          src="{{ patientStories.image_filename | getThumbnail: 'medium-landscape' }}"
          alt="Placeholder image"
          class="img-fluid rounded-lg mt-2 mb-3"
        />
        </div>
      </div>
    </div-->
  </div>
</div>
