<div class="fh bg-light pb-10 content-container">
  <app-page-header
    *ngIf="area === 'walking'"
    [title]="'Walking Goals' | uppercase | translate"
    [icon]="'walking'"
    [backLink]="['/' + this.area]"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'strength'"
    [title]="'Strength Goal' | uppercase | translate"
    [icon]="'strength'"
    [backLink]="['/strength']"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'yoga'"
    [title]="'Yoga Goals' | uppercase | translate"
    [icon]="'yoga'"
    [backLink]="['/yoga']"
  ></app-page-header>

  <div class="row">
    <div class="col-md-6">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'star']"></fa-icon>
            </span>
            {{ 'CURRENT GOALS' | translate }}
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
            <swiper
              [config]="config"
              (swiper)="onSwiper($event)"
              (slideChange)="onSlideChange()"
              *ngIf="openGoals.length > 0"
            >
              <ng-template swiperSlide *ngFor="let goal of openGoals">
                <div class="goal-content">
                  <a class="goal-checkbox" (click)="onCompleteGoal(goal)">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </span>
                  </a>
                  <div class="goal-text">
                    {{ goal.goal | uppercase | translate }}
                  </div>
                </div>
              </ng-template>
            </swiper>
            <div
              class="swiper-slide"
              *ngIf="openGoals.length === 0 && allGoals.length > 0"
            >
            {{ 'NO GOALS CURRENTLY OPEN. PLEASE ADD A NEW ONE.' | translate }}
            </div>
            <div class="swiper-slide" *ngIf="allGoals.length === 0">
              {{ 'PLEASE SET A GOAL TO BEGIN.' | translate }}
            </div>
          </div>
        </div>
      </div>

      <a
        [routerLink]="['/' + this.area, 'add-goal']"
        class="btn btn-secondary rounded-pill w-100 mt-3 mb-3"
        >
        {{ 'ADD A GOAL' | translate }}</a
      >
    </div>

    <div class="col-md-6">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'star']"></fa-icon>
            </span>
            {{ 'SUMMARY' | translate }}
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
            <div class="row" *ngIf="allGoals">
              <div class="col-6 mb-3">
                <div class="status-badge">
                  <div class="status-badge-icon">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'stars']"></fa-icon>
                    </span>
                  </div>
                  <div class="status-badge-text">
                    <div class="status-badge-title">
                      {{ 'GOALS CREATED' | translate }}</div>
                    <div class="status-badge-status">{{ allGoals.length| convertDigits }}</div>
                  </div>
                </div>
              </div>
              <div class="col-6 mb-3">
                <div class="status-badge">
                  <div class="status-badge-icon">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </span>
                  </div>
                  <div class="status-badge-text">
                    <div class="status-badge-title">
                      {{ 'GOALS ACHIEVED' | translate }}</div>
                    <div class="status-badge-status">
                      {{ completeGoals.length| convertDigits }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 mb-3">
                <div class="status-badge">
                  <div class="status-badge-icon">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                    </span>
                  </div>
                  <div class="status-badge-text">
                    <div class="status-badge-title">
                      {{ 'QUICKEST GOAL' | translate }}</div>
                    <div class="status-badge-status">
                      <div *ngIf="quickestGoal !== -1">
                        {{ quickestGoal| convertDigits
                        }}<span>
                         
                        {{'DAYS' | translate}}</span
                        >
                      </div>
                      <div *ngIf="quickestGoal === -1">
                       -
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 mb-3">
                <div class="status-badge">
                  <div class="status-badge-icon">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'arrows-h']"></fa-icon>
                    </span>
                  </div>
                  <div class="status-badge-text">
                    <div class="status-badge-title">
                      {{ 'LONGEST STREAK' | translate }}</div>
                    <div class="status-badge-status">
                      {{ goalStreak| convertDigits
                      }}<span>
                        {{'WEEKS' | translate}}
                        
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div *ngIf="isLoading" class="text-center">
        <app-loading-spinner [inline]="true"></app-loading-spinner>
      </div>
      <div *ngIf="!isLoading">
        <tabset [justified]="true">
          <tab>
            <ng-template tabHeading> 
              {{ 'ALL' | translate }} </ng-template>
            <p *ngIf="allGoals.length == 0" class="p-3">
              {{ 'NO GOALS' | translate }}</p>

            <ul class="task-list task-list-todo task-list-bt">
              <li *ngFor="let goal of allGoals">
                <span class="task">
                  <span class="date me-4 text-center">
                    {{ goal.created | localDate: "EEE d MMM" }}
                    <div *ngIf="goal.status == 1" class="text-center">
                      <span class="icon-wrap text-secondary-accent">
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                      </span>
                    </div>
                  </span>
                  <span class="title">{{ goal.goal  | uppercase | translate }}</span>

                  <span class="status">
                    <span class="icon-wrap text-secondary-accent" dropdown>
                      <button
                        dropdownToggle
                        type="button"
                        class="btn"
                        aria-controls="dropdown-basic"
                      >
                        <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                      </button>
                      <ul
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-basic"
                      >
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            [routerLink]="[
                              '/' + this.area,
                              'edit-goal',
                              goal.goal_id
                            ]"
                            >
                            {{ 'EDIT' | translate }}</a
                          >
                        </li>
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            (click)="onDeleteEntry(goal.goal_id)"
                            >
                            {{ 'DELETE' | translate }}</a
                          >
                        </li>
                      </ul>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
          </tab>
          <tab>
            <ng-template tabHeading> 
              {{ 'OPEN' | translate }} </ng-template>

            <p *ngIf="openGoals.length == 0" class="p-3">
              {{ 'NO GOALS OPEN' | translate }}</p>
            <ul class="task-list task-list-todo task-list-bt">
              <li *ngFor="let goal of openGoals">
                <span class="task">
                  <span class="date me-4 text-center">
                    {{ goal.created | localDate: "EEE d MMM" }}
                    <div *ngIf="goal.status == 1" class="text-center">
                      <span class="icon-wrap text-secondary-accent">
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                      </span>
                    </div>
                  </span>
                  <span class="title">{{ goal.goal  | uppercase | translate }}</span>

                  <span class="status">
                    <span class="icon-wrap text-secondary-accent" dropdown>
                      <button
                        dropdownToggle
                        type="button"
                        class="btn"
                        aria-controls="dropdown-basic"
                      >
                        <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                      </button>
                      <ul
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-basic"
                      >
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            [routerLink]="[
                              '/' + this.area,
                              'edit-goal',
                              goal.goal_id
                            ]"
                            >
                            {{ 'EDIT' | translate }}</a
                          >
                        </li>
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            (click)="onDeleteEntry(goal.goal_id)"
                            >
                            {{ 'DELETE' | translate }}</a
                          >
                        </li>
                      </ul>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
          </tab>
          <tab>
            <ng-template tabHeading> 
              {{ 'ACHIEVED' | translate }} </ng-template>
            <p *ngIf="completeGoals.length == 0" class="p-3">
              {{ 'NO ACHIEVED GOALS' | translate }}</p>

            <ul class="task-list task-list-todo task-list-bt">
              <li *ngFor="let goal of completeGoals">
                <span class="task">
                  <span class="date me-4 text-center">
                    {{ goal.created | localDate: "EEE d MMM" }}
                    <div *ngIf="goal.status == 1" class="text-center">
                      <span class="icon-wrap text-secondary-accent">
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                      </span>
                    </div>
                  </span>
                  <span class="title">{{ goal.goal  | uppercase | translate }}</span>

                  <span class="status">
                    <span class="icon-wrap text-secondary-accent" dropdown>
                      <button
                        dropdownToggle
                        type="button"
                        class="btn"
                        aria-controls="dropdown-basic"
                      >
                        <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                      </button>
                      <ul
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-basic"
                      >
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            [routerLink]="[
                              '/' + this.area,
                              'edit-goal',
                              goal.goal_id
                            ]"
                            >{{ 'EDIT' | translate }}</a
                          >
                        </li>
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            (click)="onDeleteEntry(goal.goal_id)"
                            >{{ 'DELETE' | translate }}</a
                          >
                        </li>
                      </ul>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>
