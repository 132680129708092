<div class="relative">
  <div class="page-header-spacer"></div>
  <div class="page-header" [ngClass]="{ 'page-header-sticky': stickyHeader }">
    <a [routerLink]="backLink">
      <span class="icon-wrap back-link">
        <fa-icon [icon]="['fas', 'arrow-alt-left']"></fa-icon> </span
    ></a>
    <span class="icon-wrap text-secondary-accent">
      <app-icon [icon]="icon"></app-icon>
    </span>
    <h1>{{ title }}</h1>
  </div>
</div>
