import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DigitLocaleService {

  constructor() { }

  convertDigits(number: any, from:string, to:string) {

    number = '' + number;
    const hindi = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
    const marathi = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
    const en = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    let fromArray:any;
    let toArray:any;
    switch(from){
      case 'en': fromArray = en; break;
      case 'hindi': fromArray = hindi; break;
      case 'marathi': fromArray = marathi; break;
    }

    switch(to){
      case 'en': toArray = en; break;
      case 'hindi': toArray = hindi; break;
      case 'marathi': toArray = marathi; break;
    }

    fromArray.forEach((num:any, index:any) => {
      number = number.replaceAll("" + num, toArray[index]);
    });
    return number;
  }
}
