import { UserTarget } from "./../../models/user-target.model";
import { UserTargetService } from "./../../services/user-target.service";
import { AchievementService } from "src/app/services/achievement.service";
import { Router } from "@angular/router";
import { WalkingService } from "src/app/services/walking.service";
import { NgForm } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-set-walking-target",
  templateUrl: "./set-walking-target.component.html",
  styleUrls: ["./set-walking-target.component.scss"],
})
export class SetWalkingTargetComponent implements OnInit {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  public currentTarget?: UserTarget;
  isLoading = false;

  constructor(
    private router: Router,
    private achievementService: AchievementService,
    private userTargetService: UserTargetService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userTargetService.fetchAll().subscribe((responseData) => {
      this.isLoading = false;
      this.currentTarget = this.userTargetService.getLatestTarget(
        responseData,
        "walking"
      );
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: this.translate.instant("THIS FORM IS INVALID"),
        text: this.translate.instant("PLEASE CHECK THE FORM FOR ERRORS"),
        showConfirmButton: true,
      });
    } else {
      // create entry
      const resourceIds = [];
      let targetMins = 0;

      if (this.currentTarget) {
        targetMins = +this.entryForm?.value.minutes;
      } else {
        // set it based on their input
        /*
        If 1-4, aim to get to 5
        If 5-9, aim to get to 10
        If 10-14, aim to get to 15
        And so on until
        30+, aim to maintain
        */
        const mins = +this.entryForm?.value.minutes;
        if (mins < 5) {
          targetMins = 5;
        } else if (mins > 4 && mins < 10) {
          targetMins = 10;
        } else if (mins > 9 && mins < 15) {
          targetMins = 15;
        } else if (mins > 14 && mins < 20) {
          targetMins = 20;
        } else if (mins > 19 && mins < 25) {
          targetMins = 25;
        } else if (mins > 24 && mins < 30) {
          targetMins = 30;
        } else {
          targetMins = 30;
        }
      }
      this.userTargetService.create("walking", targetMins, "min").subscribe(
        (responseData) => {
          if(this.currentTarget){
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: this.translate.instant("TARGET SET"),
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
        }
        else{
          Swal.fire({
            icon: "success",
            title: this.translate.instant("TARGET SET"),
            text: this.translate.instant("BASED ON YOUR ANSWER WE HAVE SET YOUR WALKING TARGET TO X MINUTES A DAY FOR 5 DAYS A WEEK.", {mins:targetMins.toString()}),
            showConfirmButton: true,
            toast: false,
          });
        }


          this.router.navigate(["/walking"]);
          this.entryForm?.reset();
          this.achievementService.refreshNotifications();
        },
        (error) => {
          this.error = error.message;
        }
      );
    }
  }
}
