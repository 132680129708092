

<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'More' | uppercase | translate"
    [icon]="''"
    [backLink]="['/dashboard']"
  ></app-page-header>
  <ul class="tracker-list tracker-list-no-bt">
    <li>
      <a [routerLink]="['/more', 'app']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'mobile']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >{{ 'Install Web App' | uppercase | translate }}
            <span class="icon-wrap float-end"
              ><fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon></span
          ></span>

          <span class="details">
          {{ 'How to run as an app on your device.' | uppercase | translate }}</span
          >
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/more', 'about']">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'info-circle']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >{{ 'About' | uppercase | translate }}
            <span class="icon-wrap float-end"
              ><fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon></span
          ></span>

          <span class="details"
            >{{ 'Information about the Programme' | uppercase | translate }}</span
          >
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/more', 'terms']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'balance-scale']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >{{ 'Terms and Conditions' | uppercase | translate }}
            <span class="icon-wrap float-end"
              ><fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon></span
          ></span>

          <span class="details"
            >{{ 'Our Terms and Conditions' | uppercase | translate }}.</span
          >
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/more', 'privacy']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'fingerprint']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >{{ 'Privacy Policy' | uppercase | translate }}
            <span class="icon-wrap float-end"
              ><fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon></span
          ></span>

          <span class="details"
            >{{ 'Our Privacy Policy' | uppercase | translate }}.</span
          >
        </span>
      </a>
    </li>
  </ul>
</div>
