import { SearchService } from "./../services/search.service";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { environment } from "./../../environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  query: any;
  results: any;
  avatarBase: any;
  currentLang = 'en';

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private translate:TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.avatarBase = environment.apiUrl + "/assets/";
    this.route.queryParams.subscribe((params) => {
      this.query = params["q"];
      // get results
      if (this.query) {
        this.searchService.getResults(this.query).subscribe((responseData) => {
          this.results = responseData;
        });
      }
    });
  }

  getResourceUrl(result: any) {
    let returnArray: any = [];
    switch (+result.content_section_id) {
      case 1:
        returnArray = ["/walking", "guide", result.content_page_id];
        break;
      case 4:
        returnArray = ["/strength", "guide", result.content_page_id];
        break;
      case 5:
        returnArray = ["/yoga", "guide", result.content_page_id];
        break;
      default:
        returnArray = ["/managing", "resources", result.content_section_id, result.content_page_id];
        break;
    }
    return returnArray;
  }
}
