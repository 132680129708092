<div
  [ngClass]="{
    'loading-spinner-wrapper': !inline,
    'loading-spinner-inline': inline
  }"
>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="lds-msg" *ngIf="longWait && !inline">
    <span *ngIf="currentLang == 'en'">
      This is taking longer than expected, sorry.  Please wait a little longer or  <a href="#" (click)="refresh()">refresh the page</a>.<br><br>  If the problem persists please get in touch.
    </span>
    <span *ngIf="currentLang != 'en'">
      {{"This is taking longer than expected, sorry. Please wait a little longer or If the problem persists please get in touch via" | uppercase  | translate}}
    </span>
  </div>
</div>
