import { UserWeeklyTarget } from './../../models/user-weekly-target.model';
import { YogaWorkoutService } from './../../services/yoga-workout.service';
import { UserYogaWorkout } from './../../models/user-yoga-workout.model';
import { Component, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";
import { UserService } from "src/app/auth/service/user.service";
import { DateHelperService } from "src/app/services/date-helper.service";
import { WeeklyTargetService } from 'src/app/services/weekly-target.service';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-yoga-workouts',
  templateUrl: './yoga-workouts.component.html',
  styleUrls: ['./yoga-workouts.component.scss']
})
export class YogaWorkoutsComponent implements OnInit {
  public isLoading = false;
  public allWorkouts!:UserYogaWorkout[];
  public allWeeklyTargets!:UserWeeklyTarget[];
  public daysHit = 0;
  public weeklyTarget = this.yogaWorkoutService.weeklyTarget;
  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public last4Weeks: Date[] = this.dateHelperService.getLast4tWeeks();
  public last4WeeksData!: any;
  public stats:any;

  public today = this.dateHelperService.getToday();
  public workoutMonths: string[] = []; 
  public exercises = [
    { id: "tadasana", label: "Tadasana", type: "asana" },
    { id: "bhujanga", label: "Bhujanga", type: "asana" },
    { id: "marjarasana", label: "Marjarasana", type: "asana" },
    { id: "ardhKatichakrasana", label: "Ardh Katichakrasana", type: "asana" },
    { id: "AnulomaVilomPranayama", label: "Anuloma Vilom Pranayama", type: "pranayama" },
    { id: "UjjayiPranayam", label: "Ujjayi Pranayam", type: "pranayama" },
    { id: "BhramariPranayama", label: "Bhramari Pranayama", type: "pranayama" },
    { id: "meditation", label: "meditation", type: "meditation" },
];
currentLang = 'en';

  constructor(
    private yogaWorkoutService: YogaWorkoutService,
    private userService: UserService,
    private dateHelperService: DateHelperService,
    private weeklyTargetService: WeeklyTargetService,
    private translate:TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];
    forkJoin({
     // userWalks: this.walkingService.fetchBetween(startDate, endDate),
      userData: this.userService.get(),
      allWorkouts: this.yogaWorkoutService.fetchAll(),
      allTargets: this.weeklyTargetService.fetchAll()
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        // this.userWalks = responseData.userWalks;
        this.allWorkouts = responseData.allWorkouts;
        this.allWeeklyTargets = responseData.allTargets;
        this.currentWeekData = this.yogaWorkoutService.generateWeekArray(
          this.currentWeek,
          this.allWorkouts
        );
        this.last4WeeksData = this.yogaWorkoutService.generateLast4WeeksArray(
          this.last4Weeks,
          this.allWorkouts
        );
        
        this.daysHit = this.yogaWorkoutService.getDaysHit(this.currentWeekData);
        this.stats = this.yogaWorkoutService.getStats(this.allWorkouts, this.allWeeklyTargets);
        this.workoutMonths = this.yogaWorkoutService.getMonths(this.allWorkouts);
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }



  onDeleteEntry(entryId:number){
    Swal.fire({
      title: this.translate.instant("ARE YOU SURE?"),
      text:  this.translate.instant("YOU WON'T BE ABLE TO UNDO THIS"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:this.translate.instant("CANCEL"),
      confirmButtonText:this.translate.instant("YES, DELETE IT"),
    }).then((result) => {
      if (result.value) {

        this.yogaWorkoutService.delete(entryId).subscribe((responseData) => {
          Swal.fire(this.translate.instant("DELETED"), this.translate.instant("YOUR ENTRY HAS BEEN DELETED"), "success");

          let deleteIndex = -1;
            this.allWorkouts.forEach((workout, workoutIndex)=>{
              if(+workout.user_yoga_workout_id === +entryId){
                deleteIndex = workoutIndex;
              }
            });

            if(deleteIndex != -1){
              // splice it
              this.allWorkouts.splice(deleteIndex, 1);
            }

        }, (error)=>{
          this.isLoading = false;
          this.errors.push(error.message);
          Swal.fire('Error', this.translate.instant('AN UNKNOWN ERROR OCCURRED. IF THE PROBLEM PERSISTS PLEASE CONTACT US.'), 'error');
        });
      }
    });
  }
}

