import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'convertDigits'
})
export class ConvertDigitsPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern: string = "mediumDate"): any {
    value = ''+value;
    let localeId = "en";
    if (this.translateService.currentLang == "hindi") {
      localeId = "hi";
    }
    if (this.translateService.currentLang == "marathi") {
      localeId = "mr";
    }
    if (this.translateService.currentLang == "hindi") {
      return this.convertHindi(value);
    } else if (this.translateService.currentLang == "marathi") {
      return this.convertMarathi(value);
    } else {
      return value;
    }
  }
  convertHindi(str: any) {
    const hindi = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
    const arabic = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    arabic.forEach((num, index) => {
      str = str.replaceAll("" + num, hindi[index]);
    });
    return str;
  }
  convertMarathi(str: any) {
    const marathi = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
    const arabic = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    arabic.forEach((num, index) => {
      str = str.replaceAll("" + num, marathi[index]);
    });
    return str;
  }
}
