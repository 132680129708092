import { ResourcesService } from './resources.service';
import { SymptomService } from './symptom.service';
import { YogaWorkoutService } from './yoga-workout.service';
import { StrengthWorkoutService } from './strength-workout.service';
import { WalkingService } from './walking.service';
import { Injectable } from '@angular/core';
import { GoalService } from './goal.service';
import { AchievementService } from './achievement.service';
import { SiteContentService } from './site-content.service';

@Injectable({
  providedIn: 'root'
})
export class CacheManagerService {

  constructor(
    private walkingService : WalkingService,
    private strengthWorkoutService : StrengthWorkoutService,
    private yogaWorkoutService : YogaWorkoutService,
    private symptomService : SymptomService,
    private goalService: GoalService,
    private achievementService:AchievementService,
    private resoucesService:ResourcesService,
    private siteContentService:SiteContentService) { }

    clearAllCache(){
      this.walkingService.clearCache();
      this.strengthWorkoutService.clearCache();
      this.yogaWorkoutService.clearCache();
      this.symptomService.clearCache();
      this.goalService.clearCache();
      this.achievementService.clearCache();
      this.resoucesService.clearCache();
      this.siteContentService.clearCache();

    }
}
