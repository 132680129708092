<nav
  class="navbar fixed-bottom navbar-expand navbar-light navbar-footer"
  aria-label="Main footer navigation"
>
  <div class="d-flex justify-content-center" style="margin: 0 auto">
    <ul class="navbar-nav">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/dashboard']">
          <span class="icon-wrap icon-home">
            <app-icon [icon]="'home'"></app-icon>
          </span>
          <span class="nav-label">{{ 'Dashboard' | uppercase | translate }}</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/walking']">
          <span class="icon-wrap icon-walking">
            <app-icon [icon]="'walking'"></app-icon>
          </span>
          <span class="nav-label">{{ 'Walking' | uppercase | translate }}</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/strength']">
          <span class="icon-wrap icon-strength"
            ><app-icon [icon]="'strength'"></app-icon
          ></span>
          <span class="nav-label">{{ 'Strength' | uppercase | translate }}</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/yoga']">
          <span class="icon-wrap icon-yoga"
            ><app-icon [icon]="'yoga'"></app-icon
          ></span>
          <span class="nav-label">{{ 'Yoga' | uppercase | translate }}</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/managing']">
          <span class="icon-wrap icon-symptoms"
            ><app-icon [icon]="'symptoms'"></app-icon
          ></span>
          <span class="nav-label">{{ 'Symptoms' | uppercase | translate }}</span>
        </a>
      </li>
      <li class="nav-item  d-none d-md-block" routerLinkActive="active">
        <a class="nav-link" (click)="onToggleAsideMenu()">
          <span class="icon-wrap icon-more"><fa-icon [icon]="['far', 'bars']"></fa-icon></span>
          <span class="nav-label">{{ 'More' | uppercase | translate }}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
<app-aside-menu
  [hidden]="!showAside"
  (closeMenu)="onCloseMenu($event)"
></app-aside-menu>