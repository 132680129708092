import { ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { DateHelperService } from "./../../services/date-helper.service";
import { UserService } from "./../../auth/service/user.service";
import { SymptomService } from "./../../services/symptom.service";
import { UserSymptom } from "./../../models/user-symptom.model";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-symptom-entry",
  templateUrl: "./symptom-entry.component.html",
  styleUrls: ["./symptom-entry.component.scss"],
})
export class SymptomEntryComponent implements OnInit {
  public isLoading = false;
  public userSymptoms!: UserSymptom[];
  public normalSymptoms!: UserSymptom | undefined;

  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public today = this.dateHelperService.getToday();
  public entryId!: number;
  public symptomEntry?:UserSymptom;

  constructor(
    private symptomService: SymptomService,
    private userService: UserService,
    private dateHelperService: DateHelperService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.entryId = params.id;
      this.isLoading = true;
      forkJoin({
        allSymptoms: this.symptomService.fetchAll(),
        normalSymptoms: this.symptomService.fetchNormal(),
        thisSymptom: this.symptomService.fetch(this.entryId)
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.userSymptoms = responseData.allSymptoms;
          this.normalSymptoms = responseData.normalSymptoms;
          this.symptomEntry = responseData.thisSymptom;
        },
        (error) => {
          this.errors.push(error.message);
        }
      );
    });
  }
}
