<div class="sidebar-bg"></div>
<div class="sidebar" role="navigation" aria-label="Desktop side nav">
  <div class="sidebar-content" *ngIf="userData">
    <div class="logo">
      <img src="/assets/img/logo-recharge-white.svg" alt="logo" class="logo" />
    </div>
    <form class="search-form standard-form mt-3" name="searchForm">
      <div class="form-group">
        <label for="q" class="sr-only">{{ 'SEARCH' | translate }}</label>

        <input
          type="text"
          class="form-control"
          placeholder="{{ 'SEARCH' | translate }}"
          name="q"
          id="q"
          #q="ngModel"
          ngModel
          (keyup.enter)="onSearch(q)"
        />
        <button
          (click)="onSearch(q)"
          class="btn-transparent btn btn-primary-outline"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'search']"></fa-icon
            ><span class="sr-only">Search</span></span
          >
        </button>
      </div>
    </form>

    <div class="ms-auto btn-group user-menu-group" dropdown>
      <a id="user-dropdown-btn-sv" dropdownToggle>
        <img
          src="{{ avatarBase + userData?.avatar }}"
          alt="user avatar"
          class="avatar rounded"
          width="40"
          height="40"
          *ngIf="userData?.avatar"
        />
        <span class="avatar-icon icon-wrap" *ngIf="!userData?.avatar">
          <fa-icon [icon]="['fal', 'user']"></fa-icon>
        </span>
        <span class="user-name"
          >{{ userData?.first_name + " " + userData?.last_name }}
        </span>
        <span class="icon-wrap float-end"
          ><fa-icon [icon]="['far', 'chevron-down']"></fa-icon
        ></span>
        <span class="sr-only">User Menu</span>
      </a>
      <ul
        id="dropdown-user"
        *dropdownMenu
        class="dropdown-menu"
        role="menu"
        aria-labelledby="user-dropdown-btn-sb"
      >
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/update-details']"
            >{{ 'UPDATE DETAILS' | translate }}</a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/upload-avatar']"
            >{{ 'UPLOAD AVATAR' | translate }}</a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/change-password']"
            >{{ 'CHANGE PASSWORD' | translate }}</a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['']" (click)="onLogout()"
            >{{ 'LOGOUT' | translate }}</a
          >
        </li>
      </ul>
    </div>
    <nav class="sidebar-nav">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/dashboard']"
            routerLinkActive="active"
          >
            <span class="nav-icon icon-wrap">
              <app-icon [icon]="'home'"></app-icon>
            </span>
            <span class="nav-link-title">{{ 'DASHBOARD' | translate }}</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/walking']"
            routerLinkActive="active"
          >
            <span class="nav-icon icon-wrap">
              <app-icon [icon]="'walking'"></app-icon>
            </span>
            <span class="nav-link-title">{{ 'WALKING' | translate }}</span>
          </a>
          <ul class="nav flex-column fade-in animate-250">
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/walking','workouts']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                {{ 'WORKOUTS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/walking', 'goals']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                {{ 'GOALS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/walking', 'guide']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                {{ 'GUIDE' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/walking', 'achievements']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                {{ 'ACHIEVEMENTS' | translate }}
              </a>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/strength']"
            routerLinkActive="active"
          >
            <span class="nav-icon icon-wrap">
              <app-icon [icon]="'strength'"></app-icon>
            </span>
            <span class="nav-link-title">
              {{ 'STRENGTH' | translate }}</span>
          </a>
          <ul class="nav flex-column fade-in animate-250">
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/strength','workouts']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                {{ 'WORKOUTS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/strength', 'goals']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                {{ 'GOALS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/strength', 'guide']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                {{ 'GUIDE' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/strength', 'achievements']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                
                {{ 'ACHIEVEMENTS' | translate }}
              </a>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/yoga']"
            routerLinkActive="active"
          >
            <span class="nav-icon icon-wrap">
              <app-icon [icon]="'yoga'"></app-icon>
            </span>
            <span class="nav-link-title">
              {{ 'YOGA' | translate }}</span>
          </a>
          <ul class="nav flex-column fade-in animate-250">
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/yoga','workouts']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                
                {{ 'WORKOUTS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/yoga', 'goals']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                
                {{ 'GOALS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/yoga', 'guide']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                
                {{ 'GUIDE' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/yoga', 'achievements']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                
                {{ 'ACHIEVEMENTS' | translate }}
              </a>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/managing']"
            routerLinkActive="active"
          >
            <span class="nav-icon icon-wrap">
              <app-icon [icon]="'symptoms'"></app-icon>
            </span>
            <span class="nav-link-title">
              {{ 'MANAGING' | translate }}</span>
          </a>
          <ul class="nav flex-column fade-in animate-250">
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/managing', 'symptoms']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                
                {{ 'SYMPTOMS' | translate }}
              </a>
            </li>
            <li class="nav-item" *ngFor="let resource of resources">
              <a
                class="nav-link"
                [routerLink]="[
                '/managing',
                'resources',
                resource.content_section_id
              ]"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                
                {{resource.title | uppercase | translate}}
              </a>


            </li>

            <!--li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/managing', 'patient-stories']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                Patient Stories
              </a>
            </li-->
          </ul>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/more']"
            routerLinkActive="active"
          >
            <span class="nav-icon icon-wrap">
              <fa-icon [icon]="['fal', 'ellipsis-h']"></fa-icon
            ></span>
            <span class="nav-link-title">
              {{ 'MORE' | translate }}</span>
          </a>
          <ul class="nav flex-column fade-in animate-250">
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/more', 'app']"
                routerLinkActive="active"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                {{ 'INSTALL WEB APP' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/more', 'about']"
                routerLinkActive="active"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                
                {{ 'ABOUT' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/more', 'terms']"
                routerLinkActive="active"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                
                {{ 'TERMS AND CONDITIONS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/more', 'privacy']"
                routerLinkActive="active"
              >
                <span class="icon-wrap">
                  <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                </span>
                
                {{ 'PRIVACY POLICY' | translate }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</div>
