<div class="fh bg-light content-container">
  <app-page-header
    [title]="'WALKING WORKOUTS' | translate"
    [icon]="'walking'"
    [backLink]="['/walking']"
  ></app-page-header>

  <div class="row">
    <div class="col-md-6">
      <div class="card bg-primary mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
            </span>
            {{ 'TARGET' | translate }}
          </h2>
          <p class="p-0 m-0">
            {{ 'WALK FOR X MINUTES ON Y DAYS OF THIS WEEK' | translate: { 'minutes':weeklyTarget.threshold, 'days':weeklyTarget.days} | convertDigits}}
          </p>

        <div *ngIf="weekReport?.daysWalked>0">
          <a class="feedback-link text-secondary" (click)="toggleFeedback()">
            <span *ngIf="!showFeedback" class="text-white"> <fa-icon [icon]="['far', 'plus']"></fa-icon></span>
            <span *ngIf="showFeedback" class="text-white"> <fa-icon [icon]="['far', 'minus']"></fa-icon></span>
            {{'Show weekly feedback' | uppercase | translate}}</a>
          <div *ngIf="showFeedback" class="feedback-container">
            <p class="p-0 m-0">

            {{ 'Last week you walked on X days.' | uppercase |  translate: { 'days':weekReport.daysWalked} | convertDigits}}
            </p>
            <p *ngIf="weekReport.feedback" class="pt-1">{{weekReport.feedback}}</p>
            <p *ngIf="weekReport.progress" class="pt-1">{{'We recommend increasing your walking target by 1 minute.' | uppercase | translate}}</p>
            <p *ngIf="weekReport.reduce" class="pt-1">{{'We recommend reducing your walking target by 1 minute.' | uppercase | translate}}</p>
            <p *ngIf="!weekReport.reduce && !weekReport.progress && !weekReport.feedback" class="pt-1">{{'We recommend maintaining your walking target.' | uppercase | translate}}</p>
          </div>
        </div>

        </div>
        <div class="card-footer bg-secondary relative">
          <a
            [routerLink]="['/walking', 'set-target']"
            class="card-link stretched-link"
            >{{ 'UPDATE WALKING TARGET' | translate }}</a
          >
        </div>
      </div>
      
    </div>
    <div class="col-md-6">
      <div class="card bg-primary-accent mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
            </span>
            {{ 'SUMMARY' | translate }}
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
            <tabset [justified]="true">
              <tab>
                <ng-template tabHeading> {{ 'THIS WEEK' | translate }} </ng-template>
                <div class="entry-days mb-3 mt-3">
                  <div class="entry-day" *ngFor="let day of currentWeekData">
                    <div class="entry-day-label">
                      <span *ngIf="currentLang == 'en'">{{ day.date | localDate: "EE" | slice: 0:1 }}</span>
                      <span *ngIf="currentLang !== 'en'">{{ day.date | localDate: "EE" }}</span>
                    </div>
                    <div
                      class="entry-day-data na"
                      *ngIf="day.date > today"
                    ></div>
                    <div
                      class="entry-day-data"
                      *ngIf="day.date <= today"
                      [ngClass]="{
                        complete: +day.minutes >= +weeklyTarget.threshold
                      }"
                    >
                      <div class="entry-day-data-value" *ngIf="day.minutes > 0">
                        {{ day.minutes | convertDigits}}
                      </div>
                      <div class="entry-day-data-unit" *ngIf="day.minutes > 0">
                        {{'MINS' | translate}}
                      </div>
                      <div
                        class="entry-day-data-value"
                        *ngIf="day.minutes == 0 && day.date !== today"
                      >
                        -
                      </div>

                      <div
                        class="entry-day-data-value relative"
                        *ngIf="day.minutes == 0 && day.date === today"
                      >
                        <a
                          [routerLink]="['/walking', 'add-workout']"
                          class="stretched-link"
                          ><span class="icon-wrap text-secondary-accent">
                            <fa-icon
                              [icon]="['fas', 'pencil-alt']"
                            ></fa-icon> </span
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="target-status row">
                  <div
                    class="col-6 target-status-status"
                    *ngIf="daysHit < weeklyTarget.days"
                  >
                    <span class="icon-wrap text-primary">
                      <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                    </span>
                    {{ 'IN PROGRESS' | translate }}
                  </div>

                  <div
                    class="col-6 target-status-status"
                    *ngIf="daysHit >= weeklyTarget.days"
                  >
                    <span class="icon-wrap text-primary">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </span>
                    {{ 'TARGET HIT' | translate }}
                  </div>

                  <div class="col-6 text-end target-status-date">
                    {{ currentWeek[0] | localDate: "EEE d MMMM" }} -
                    {{ currentWeek[6] | localDate: "EEE d MMMM" }}
                  </div>
                </div>

                <p class="subtext" *ngIf="daysHit < weeklyTarget.days">
                  {{ 'YOU HAVE COMPLETED X DAYS TOWARDS YOUR TARGET THIS WEEK.' | translate : {days: daysHit}  | convertDigits}}
                </p>
                <p class="subtext" *ngIf="daysHit < weeklyTarget.days">
                  {{ 'X MORE DAYS ARE NEEDED TO HIT YOUR TARGET' | translate : {days: weeklyTarget.days - daysHit} | convertDigits }}
                </p>
              </tab>
              <tab>
                <ng-template tabHeading> {{ 'LAST 4 WEEKS' | translate }}</ng-template>

                <div class="status-calendar" *ngIf="last4WeeksData">
                  <div class="status-calendar-row status-calendar-header">
                    <div class="status-calendar-col">

                    <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[0]?.date | localDate: "EE" | slice: 0:1 }}</span>
                    <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[0]?.date | localDate: "EE" }}</span>
                      
                    </div>
                    <div class="status-calendar-col">
                      <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[1]?.date | localDate: "EE" | slice: 0:1 }}</span>
                      <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[1]?.date | localDate: "EE" }}</span>

                    </div>
                    <div class="status-calendar-col">
                      <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[2]?.date | localDate: "EE" | slice: 0:1 }}</span>
                      <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[2]?.date | localDate: "EE" }}</span>  
                    </div>
                    <div class="status-calendar-col">
                      <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[3]?.date | localDate: "EE" | slice: 0:1 }}</span>
                      <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[3]?.date | localDate: "EE" }}</span>  

                    </div>
                    <div class="status-calendar-col">
                      <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[4]?.date | localDate: "EE" | slice: 0:1 }}</span>
                      <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[4]?.date | localDate: "EE" }}</span>  
                    </div>
                    <div class="status-calendar-col">
                      <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[5]?.date | localDate: "EE" | slice: 0:1 }}</span>
                      <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[5]?.date | localDate: "EE" }}</span>  
                    </div>
                    <div class="status-calendar-col">
                      <span *ngIf="currentLang == 'en'">{{last4WeeksData[1].days[6]?.date | localDate: "EE" | slice: 0:1 }}</span>
                      <span *ngIf="currentLang !== 'en'">{{last4WeeksData[1].days[6]?.date | localDate: "EE" }}</span>  
                    </div>
                    <div class="status-calendar-col">
                      <span class="icon-wrap text-primary">
                        <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                      </span>
                    </div>
                  </div>

                  <div
                    class="status-calendar-row"
                    *ngFor="let weekNum of [1, 2, 3, 4]"
                  >
                    <div
                      class="status-calendar-col"
                      *ngFor="let day of last4WeeksData[weekNum].days"
                    >
                      <span
                        class="status-calendar-day"
                        [ngClass]="{
                          complete: +day.minutes >= +weeklyTarget.threshold,
                          incomplete:
                            +day.minutes < +weeklyTarget.threshold &&
                            day.date < today
                        }"
                        >{{ day.date | localDate: "dd" }}</span
                      >
                    </div>
                    <div class="status-calendar-col">
                      <span
                        class="icon-wrap text-primary"
                        *ngIf="
                          +last4WeeksData[weekNum].daysHit < +weeklyTarget.days
                        "
                      >
                        <fa-icon [icon]="['fas', 'times']"></fa-icon>
                      </span>
                      <span
                        class="icon-wrap text-secondary-accent"
                        *ngIf="
                          +last4WeeksData[weekNum].daysHit >= +weeklyTarget.days
                        "
                      >
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="last4WeeksData">
                  <div class="col">
                    <p class="subtext">
                      {{ last4WeeksData?.total.daysActive  | convertDigits}}  {{ 'ACTIVE DAYS' | translate }}
                    </p>
                  </div>
                  <div class="col text-end">
                    <p class="subtext">
                      {{ last4WeeksData[1].days[0].date | localDate: "EEE d MMMM" }} -
                      {{ last4WeeksData[4].days[6].date | localDate: "EEE d MMMM" }}
                    </p>
                  </div>
                </div>
              </tab>
              <tab>
                <ng-template tabHeading> {{ 'ALL TIME' | translate }} </ng-template>

                <div class="row">
                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'hourglass-half']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">{{ 'TIME SPENT' | translate }}</div>
                        <div class="status-badge-status">
                          {{ stats?.timeSpentFormatted.hours  | convertDigits}}<span> {{'HOURS' | translate}}</span>
                          {{ stats?.timeSpentFormatted.minutes | convertDigits
                          }}<span> {{'MINS' | translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">{{ 'TOTAL WORKOUTS' | translate }}</div>
                        <div class="status-badge-status">
                          {{ stats?.total  | convertDigits}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">{{ 'ACTIVE DAYS' | translate }}</div>
                        <div class="status-badge-status">
                          {{ stats?.activeDays  | convertDigits}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'stopwatch']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">{{ 'LONGEST WORKOUT' | translate }}</div>
                        <div class="status-badge-status">
                          {{ stats?.longest  | convertDigits}}<span> {{'MINS' | translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">{{ 'TARGET ACHIEVED' | translate }}</div>
                        <div class="status-badge-status">
                          {{ stats?.targetHit  | convertDigits}}<span> {{ 'TIMES' | translate }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'arrows-h']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">{{ 'LONGEST STREAK' | translate }}</div>
                        <div class="status-badge-status">
                          {{ stats?.streak  | convertDigits}}<span> {{'DAYS' | translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
            </tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button
        class="btn btn-secondary rounded-pill w-100"
        [routerLink]="['/walking', 'add-workout']"
      >
      {{ 'ADD A WALKING ENTRY' | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h2 class="inline-title">
        <span class="float-end muted"
          ><span *ngIf="currentLang == 'en'">
            {{ allWalks?.length  | convertDigits}} workout<span
            *ngIf="allWalks?.length != 1"
            >s</span
          >
          </span>
          <span *ngIf="currentLang != 'en'">
            {{ allWalks?.length | convertDigits }} {{ 'Workouts' | uppercase | translate }}
          </span></span
        >{{ 'ALL WORKOUTS' | translate }}
      </h2>
      <div *ngIf="isLoading" class="text-center">
        <app-loading-spinner [inline]="true"></app-loading-spinner>
      </div>
      <div *ngIf="!isLoading">
        <div *ngFor="let month of monthsWalked">
          <h3 class="month-header">{{ month | slice: 0:-2 | uppercase | translate }}</h3>
          <ul class="task-list task-list-todo task-list-bt mb-2">
            <ng-container *ngFor="let walk of allWalks">
              <li *ngIf="(walk.date_walked | date: 'MMMMYY') == month">
                <span class="task">
                  <span class="date me-4">
                    {{ walk.date_walked | localDate: "EEE d" }}
                  </span>
                  <span class="title"
                    >{{ walk.minutes | convertDigits}} {{'MINS' | translate}}<!--<ng-container
                      *ngIf="+walk.minutes != 1"
                      >s</ng-container
                    >--> 
                    <span *ngIf="walk.seconds">
                      {{ walk.seconds  | convertDigits}} {{'SECS' | translate}}<!--<ng-container
                        *ngIf="+walk.seconds != 1"
                        >s</ng-container
                      >-->
                    </span>
                  </span>
                  <span class="title">
                    <app-difficulty-icon
                      [difficulty]="+walk.difficulty"
                    ></app-difficulty-icon
                  ></span>
                  <span class="status">
                    <span class="icon-wrap text-secondary-accent" dropdown>
                      <button
                        dropdownToggle
                        type="button"
                        class="btn"
                        aria-controls="dropdown-basic"
                      >
                        <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                      </button>
                      <ul
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-basic"
                      >
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            [routerLink]="[
                              '/walking',
                              'edit-workout',
                              walk.user_walking_id
                            ]"
                            >{{ 'EDIT' | translate }}</a
                          >
                        </li>
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            (click)="onDeleteEntry(walk.user_walking_id)"
                            >{{ 'DELETE' | translate }}</a
                          >
                        </li>
                      </ul>
                    </span>
                  </span>
                </span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
