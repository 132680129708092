<div class="fh bg-light content-container">
  <app-page-header
    [title]="'SYMPTOMS' | translate"
    [icon]="'symptoms'"
    [backLink]="['/managing']"
  ></app-page-header>
  <div *ngIf="isLoading" class="text-center">
    <app-loading-spinner></app-loading-spinner>
  </div>

  <div class="row" *ngIf="symptomEntry">
    <div class="col-md-12">
      <div class="card bg-transparent mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">
            {{ symptomEntry.symptom_date | localDate: "EEE d MMM" }}
          </h2>

          <div class="row">
            <div class="col">
              <div class="symptom-status">
                <div class="symptom-status-label">{{ 'Increased' | uppercase | translate }}</div>
                <div class="symptom-status-box bg-transparent">
                  <div class="symptom-status-icon text-danger">
                    <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
                  </div>
                  <div class="symptom-status-value">
                    {{ symptomEntry.total_up | convertDigits}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="symptom-status">
                <div class="symptom-status-label">{{ 'Usual' | uppercase | translate }}</div>
                <div class="symptom-status-box bg-transparent">
                  <div class="symptom-status-icon text-warning">
                    <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
                  </div>
                  <div class="symptom-status-value">
                    {{ symptomEntry.total_down | convertDigits}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="symptom-status">
                <div class="symptom-status-label">{{ 'Wellbeing' | uppercase | translate }}</div>
                <div class="symptom-status-box bg-transparent">
                  <div class="symptom-status-icon">
                    <fa-icon [icon]="['far', 'head-side-medical']"></fa-icon>
                  </div>
                  <div class="symptom-status-value">
                    <span
                      class="text-wellbeing-{{ +symptomEntry.wellbeing + 1 }}"
                      >{{ symptomEntry.wellbeing | convertDigits}}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- start symptoms-->

    <div class="col-md-12 mb-5" *ngIf="userSymptoms && normalSymptoms">
      <div class="card bg-primary-accent mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">{{ 'Breathlessness' | uppercase | translate }}</h2>
          <h3 class="sub-label">{{ 'At rest' | uppercase | translate }}</h3>
          <div class="symptom-status symptom-status-inline">
            <div class="symptom-status-box">
              <div
                class="icon-selection bg-symptom-{{
                  +symptomEntry.breathlessness_rest + 1
                }}"
              >
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
              </div>
              <div
                class="symptom-status-icon text-danger"
                *ngIf="
                  +symptomEntry.breathlessness_rest >
                  +normalSymptoms.breathlessness_rest
                "
              >
                <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
              </div>

              <div
                class="symptom-status-icon text-warning"
                *ngIf="
                  +symptomEntry.breathlessness_rest <=
                  +normalSymptoms.breathlessness_rest
                "
              >
                <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
              </div>
              <div class="symptom-status-value">
                <span *ngIf=" +symptomEntry.breathlessness_rest -
                +normalSymptoms.breathlessness_rest  > 0">+</span>{{
                  +symptomEntry.breathlessness_rest -
                    +normalSymptoms.breathlessness_rest | convertDigits
                }}
              </div>
            </div>
          </div>
          
          <hr class="divider" />

          <h3 class="sub-label">{{ 'During everyday activities' | uppercase | translate }}</h3>
          <div class="symptom-status symptom-status-inline">
            <div class="symptom-status-box">
              <div
                class="icon-selection bg-symptom-{{
                  +symptomEntry.breathlessness_active + 1
                }}"
              >
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
              </div>
              <div
                class="symptom-status-icon text-danger"
                *ngIf="
                  +symptomEntry.breathlessness_active >
                  +normalSymptoms.breathlessness_active
                "
              >
                <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
              </div>

              <div
                class="symptom-status-icon text-warning"
                *ngIf="
                  +symptomEntry.breathlessness_active <=
                  +normalSymptoms.breathlessness_active
                "
              >
                <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
              </div>
              <div class="symptom-status-value">
               <span *ngIf="+symptomEntry.breathlessness_active -
               +normalSymptoms.breathlessness_active > 0">+</span> {{
                  +symptomEntry.breathlessness_active -
                    +normalSymptoms.breathlessness_active | convertDigits
                }}
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <div class="card bg-primary-accent mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">{{ 'Sputum' | uppercase | translate }}</h2>
          <h3 class="sub-label">{{ 'Amount' | uppercase | translate }}</h3>
          <div class="symptom-status symptom-status-inline">
            <div class="symptom-status-box">
              <div
                class="icon-selection bg-symptom-{{ +symptomEntry.sputum + 1 }}"
              >
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
              </div>
              <div
                class="symptom-status-icon text-danger"
                *ngIf="+symptomEntry.sputum > +normalSymptoms.sputum"
              >
                <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
              </div>

              <div
                class="symptom-status-icon text-warning"
                *ngIf="+symptomEntry.sputum <= +normalSymptoms.sputum"
              >
                <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
              </div>
              <div class="symptom-status-value">
                <span *ngIf="+symptomEntry.sputum - +normalSymptoms.sputum > 0">+</span>{{ +symptomEntry.sputum - +normalSymptoms.sputum | convertDigits}}
              </div>
            </div>
          </div>
          
          <hr class="divider" />

          <h3 class="sub-label">{{ 'Colour of sputum' | uppercase | translate }}</h3>
          <div class="symptom-status symptom-status-inline">
            <div class="symptom-status-box">
              <div
                class="icon-selection bg-symptom-{{
                  +symptomEntry.sputum_colour + 1
                }}"
              >
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
              </div>
              <div
                class="symptom-status-icon text-danger"
                *ngIf="
                  +symptomEntry.sputum_colour > +normalSymptoms.sputum_colour
                "
              >
                <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
              </div>

              <div
                class="symptom-status-icon text-warning"
                *ngIf="
                  +symptomEntry.sputum_colour <= +normalSymptoms.sputum_colour
                "
              >
                <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
              </div>
              <div class="symptom-status-value">
               <span *ngIf="+symptomEntry.sputum_colour - +normalSymptoms.sputum_colour > 0">+</span> {{
                  +symptomEntry.sputum_colour - +normalSymptoms.sputum_colour| convertDigits
                }}
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <div class="card bg-primary-accent mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">{{ 'wheeze' | uppercase | translate }}</h2>
          <div class="symptom-status symptom-status-inline">
            <div class="symptom-status-box">
              <div
                class="icon-selection bg-symptom-{{ +symptomEntry.wheeze + 1 }}"
              >
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
              </div>
              <div
                class="symptom-status-icon text-danger"
                *ngIf="+symptomEntry.wheeze > +normalSymptoms.wheeze"
              >
                <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
              </div>

              <div
                class="symptom-status-icon text-warning"
                *ngIf="+symptomEntry.wheeze <= +normalSymptoms.wheeze"
              >
                <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
              </div>
              <div class="symptom-status-value">
                <span *ngIf="+symptomEntry.wheeze - +normalSymptoms.wheeze > 0">+</span>{{ +symptomEntry.wheeze - +normalSymptoms.wheeze| convertDigits }}
              </div>
            </div>
          </div>
          
        </div>
      </div>


      <div class="card bg-primary-accent mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">{{ 'Fever' | uppercase | translate }}</h2>
          <div class="symptom-status symptom-status-inline">
            <div class="symptom-status-box">
              <div
                class="icon-selection bg-symptom-{{ +symptomEntry.fever + 1 }}"
              >
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
              </div>
              <div
                class="symptom-status-icon text-danger"
                *ngIf="+symptomEntry.fever > +normalSymptoms.fever"
              >
                <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
              </div>

              <div
                class="symptom-status-icon text-warning"
                *ngIf="+symptomEntry.fever <= +normalSymptoms.fever"
              >
                <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
              </div>
              <div class="symptom-status-value">
                <span *ngIf="+symptomEntry.fever - +normalSymptoms.fever > 0">+</span>{{ +symptomEntry.fever - +normalSymptoms.fever | convertDigits}}
              </div>
            </div>
          </div>
          
        </div>
      </div>


      <div class="card bg-primary-accent mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">{{ 'Wellbeing' | uppercase | translate }}</h2>
          <div class="symptom-status symptom-status-inline">
            <div class="symptom-status-box">
              <div
                class="icon-selection bg-symptom-{{ +symptomEntry.wellbeing + 1 }}"
              >
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
              </div>
              <div
                class="symptom-status-icon text-danger"
                *ngIf="+symptomEntry.wellbeing > +normalSymptoms.wellbeing"
              >
                <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
              </div>

              <div
                class="symptom-status-icon text-warning"
                *ngIf="+symptomEntry.wellbeing <= +normalSymptoms.wellbeing"
              >
                <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
              </div>
              <div class="symptom-status-value">
                <span *ngIf="+symptomEntry.wellbeing - +normalSymptoms.wellbeing  > 0">+</span> {{ +symptomEntry.wellbeing - +normalSymptoms.wellbeing | convertDigits}}
              </div>
            </div>
          </div>
          
        </div>
      </div>


    </div>
    <!-- end symptoms -->
  </div>
</div>
