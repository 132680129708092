export class UserYogaWorkout {
  constructor(
    public user_yoga_workout_id: number,
    public user_id: number,
    public workout_date: string,
    public asana_time: string,
    public asana_difficulty: number,
    public pranayama_reps: string,
    public pranayama_difficulty: number,
    public exercises: any,
    public created: string,
    public modified: string
  ) {}
}
