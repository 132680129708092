<div class="fh bg-primary text-white pb-10 content-container">
  <app-page-header
    *ngIf="area === 'global'"
    [title]="'Achievements' | uppercase | translate"
    [icon]="'achievement'"
    [backLink]="['/dashboard']"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'walking'"
    [title]="'Achievements' | uppercase  | translate"
    [icon]="'achievement'"
    [backLink]="['/walking']"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'strength'"
    [title]="'Achievements' | uppercase  | translate"
    [icon]="'achievement'"
    [backLink]="['/strength']"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'yoga'"
    [title]="'Achievements' | uppercase  | translate"
    [icon]="'achievement'"
    [backLink]="['/yoga']"
  ></app-page-header>

  <div class="row">
    <div class="col">
      <div class="card bg-white">
        <div class="card-body">
          <h2 class="card-title text-start card-title-sm">
            {{'Weekly Participation Streak' | uppercase | translate}}
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>

          <div *ngIf="!isLoading">
            <div *ngIf="weeklyParticipationBadges.length === 0">
              <p>{{'No badges yet - keep going' | uppercase | translate}}</p>
            </div>

            <div
              *ngIf="weeklyParticipationBadges.length > 0"
              class="achievements"
            >
              <app-achievement-badge
                *ngFor="let achievement of weeklyParticipationBadges"
                [achievement]="achievement"
                [popover]="getPopoverContent(achievement)"
                popoverTitle="{{'X Week Streak Badge' | uppercase | translate: { 'total':achievement.total} | convertDigits}}"
                placement="top"
                [outsideClick]="true"
              ></app-achievement-badge>
            </div>

            <p class="card-text-sm">
              <strong>{{'Next streak' | uppercase | translate}}:</strong>
              {{ '' + nextParticipationStreak  | translate }} {{'WEEKS' | translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <tabset [justified]="true">
        <tab [active]="area ==='global' || area === 'walking'">
          <ng-template tabHeading> {{'WALKING' | translate}} </ng-template>

          <div class="card bg-primary-accent mt-4">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                
                {{'Workout Milestones' | uppercase | translate}}
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="walkingWorkoutMilestones.length === 0">
                  <p>{{'No badges yet - keep going' | uppercase | translate}}</p>
                </div>

                <div
                  *ngIf="walkingWorkoutMilestones.length > 0"
                  class="achievements achievements-left mt-3 mt-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of walkingWorkoutMilestones"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>{{'Next milestone' | uppercase | translate}}:</strong>
                  {{ '' + nextWalkingWorkoutMilestone  | translate }} {{'Workouts' | uppercase | translate}}<br />
                  <strong> {{'Current Total' | uppercase | translate}}:</strong>
                  {{ '' + current.walkingWorkouts  | translate }}  {{'Workouts' | uppercase | translate}}
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-4">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                {{'Goals Completed' | uppercase | translate}}
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="walkingGoals.length === 0">
                  <p>{{'No badges yet - keep going' | uppercase | translate}}</p>
                </div>

                <div
                  *ngIf="walkingGoals.length > 0"
                  class="achievements achievements-left mt-3 mt-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of walkingGoals"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>{{'Next milestone' | uppercase | translate}}:</strong>
                  {{ nextWalkingGoalAward | convertDigits}}  {{'GOALS' | translate}}<br />
                  <strong>{{'Current Total' | uppercase | translate}}:</strong>
                  {{ current.walkingGoals | convertDigits}}  {{'GOALS' | translate}}
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-4">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                {{'DAILY STREAKS' | translate}}</h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="walkingStreaks.length === 0">
                  <p>{{'No badges yet - keep going' | uppercase | translate}}</p>
                </div>

                <div
                  *ngIf="walkingStreaks.length > 0"
                  class="achievements achievements-left mt-3 mt-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of walkingStreaks"
                    [achievement]="achievement"
                    [popover]="getPopoverContent(achievement)"
                    popoverTitle="{{'X Day Streak Badge' | uppercase | translate: { 'total':achievement.total} | convertDigits}}"
                    placement="top"
                    [outsideClick]="true"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>{{'Next streak achievement' | uppercase | translate}} :</strong>
                  {{nextWalkingStreak  | convertDigits}}<br />
                  <strong>{{'Current streak' | uppercase | translate}}:</strong> 
                  {{ current.walkingStreak | convertDigits }} {{'DAYS' | translate}}
                </p>
              </div>
            </div>
          </div>
        </tab>
        <tab [active]="area ==='strength'">
          <ng-template tabHeading> {{'STRENGTH' | translate}} </ng-template>

          <div class="card bg-primary-accent mt-4">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                {{'WORKOUT MILESTONES' | translate}} 
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="strengthWorkoutMilestones.length === 0">
                  <p>{{'No badges yet - keep going' | uppercase | translate}}</p>
                </div>

                <div
                  *ngIf="strengthWorkoutMilestones.length > 0"
                  class="achievements achievements-left mt-4 mb-4"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of strengthWorkoutMilestones"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>{{'Next milestone' | uppercase | translate}}:</strong>
                  {{ nextStrengthWorkoutMilestone  | convertDigits}} 
                  {{'WORKOUTS' | translate}} <br />
                  <strong>{{'Current Total' | uppercase | translate}}:</strong>
                  {{ current.strengthWorkouts  | convertDigits}} 
                  {{'WORKOUTS' | translate}}
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-4">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                {{'GOALS COMPLETED' | translate}}
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="strengthGoals.length === 0">
                  <p>{{'No badges yet - keep going' | uppercase | translate}}</p>
                </div>

                <div
                  *ngIf="strengthGoals.length > 0"
                  class="achievements achievements-left mt-4 mb-4"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of strengthGoals"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>
                    {{'NEXT AWARD' | translate}}:</strong>
                  {{ nextStrengthGoalAward  | convertDigits}} {{'GOALS' | translate}}<br />
                  <strong>{{'Current Total' | uppercase | translate}}:</strong>
                  {{ current.strengthGoals  | convertDigits}}  {{'GOALS' | translate}}
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-4">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                {{'DAILY STREAKS' | translate}}</h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="strengthStreaks.length === 0">
                  <p>{{'No badges yet - keep going' | uppercase | translate}}</p>
                </div>

                <div
                  *ngIf="strengthStreaks.length > 0"
                  class="achievements achievements-left mt-4 mb-4"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of strengthStreaks"
                    [achievement]="achievement"
                    [popover]="getPopoverContent(achievement)"
                    popoverTitle="{{'X Day Streak Badge' | uppercase | translate: { 'total':achievement.total} | convertDigits}}"
                    placement="top"
                    [outsideClick]="true"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>
                    {{'NEXT STREAK ACHIEVEMENT' | translate}}:</strong>
                  {{ nextStrengthStreak  | convertDigits}}<br />
                  <strong>{{'CURRENT STREAK' | translate}}:</strong>
                  {{ current.strengthStreak  | convertDigits}} {{'DAYS' | translate}}
                </p>
              </div>
            </div>
          </div>
        </tab>
        <tab [active]="area ==='yoga'">
          <ng-template tabHeading> {{'YOGA' | translate}} </ng-template>

          <div class="card bg-primary-accent mt-4">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                {{'WORKOUT MILESTONES' | translate}}
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="yogaWorkoutMilestones.length === 0">
                  <p>{{'No badges yet - keep going' | uppercase | translate}}</p>
                </div>

                <div
                  *ngIf="yogaWorkoutMilestones.length > 0"
                  class="achievements achievements-left mt-4 mb-4"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of yogaWorkoutMilestones"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>{{'Next milestone' | uppercase | translate}}:</strong>
                  {{ nextYogaWorkoutMilestone  | convertDigits}} {{'WORKOUTS' | translate}}<br />
                  <strong>{{'Current Total' | uppercase | translate}}:</strong>
                  {{ current.yogaWorkouts  | convertDigits}} {{'WORKOUTS' | translate}}
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-4">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                {{'GOALS COMPLETED' | translate}}
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="yogaGoals.length === 0">
                  <p>{{'No badges yet - keep going' | uppercase | translate}}</p>
                </div>

                <div
                  *ngIf="yogaGoals.length > 0"
                  class="achievements achievements-left mt-4 mb-4"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of yogaGoals"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>{{'NEXT AWARD' | translate}}:</strong>
                  {{ nextYogaGoalAward  | convertDigits}}  {{'GOALS' | translate}}<br />
                  <strong>{{'Current Total' | uppercase | translate}}:</strong>
                  {{ current.yogaGoals  | convertDigits}}  {{'GOALS' | translate}}
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-4">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                {{'DAILY STREAKS' | translate}}</h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="yogaStreaks.length === 0">
                  <p>{{'No badges yet - keep going' | uppercase | translate}}</p>
                </div>

                <div
                  *ngIf="yogaStreaks.length > 0"
                  class="achievements achievements-left mt-4 mb-4"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of yogaStreaks"
                    [achievement]="achievement"
                    [popover]="getPopoverContent(achievement)"
                    popoverTitle="{{'X Day Streak Badge' | uppercase | translate: { 'total':achievement.total} | convertDigits}}"
                    placement="top"
                    [outsideClick]="true"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>{{'NEXT STREAK ACHIEVEMENT' | translate}}:</strong>
                  {{ nextYogaStreak  | convertDigits}}<br />
                  <strong>{{'CURRENT STREAK' | translate}}:</strong>
                  {{ ''+current.yogaStreak  | convertDigits}} {{'DAYS' | translate}}
                </p>
              </div>
            </div>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>
