<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Add Yoga Workout' | uppercase | translate"
    [icon]="'yoga'"
    [backLink]="['/yoga']"
  ></app-page-header>

  <div class="row">
    <div class="col">
      <form class="form-recharge" (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
          <label for="date text-center">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
            </span>
            {{ 'Date' | uppercase | translate }}</label
          >

          <input
            type="text"
            id="workout_date"
            class="form-control"
            [ngModel]="bsDate"
            #workout_date="ngModel"
            name="workout_date"
            placeholder="DD/MM/YYYY"
            required
            date
            bsDatepicker
            autocomplete="off"
            [maxDate]="maxDate"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY',
              containerClass: 'theme-recharge',
              showWeekNumbers: false
            }"
          />
          <div
            class="alert alert-danger"
            *ngIf="!workout_date.valid && submitted"
          >
          {{ 'Please enter a date above' | uppercase | translate }}
          </div>
        </div>

        <div *ngIf="!exercisesSelected">
          <p class="text-start">{{ 'What exercises did you do?' | uppercase | translate }}</p>
          <ul class="pick-list">
            <li
              *ngFor="let exercise of exercises; let index = index"
              [ngClass]="{ active: exercisesPicked.indexOf(exercise) !== -1 }"
              (click)="onPickExercise(exercise)"
            >
              <span class="pick-icon">
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'circle']"
                    *ngIf="exercisesPicked.indexOf(exercise) == -1"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'check-circle']"
                    *ngIf="exercisesPicked.indexOf(exercise) !== -1"
                  ></fa-icon>
                </span>
              </span>
              <span class="pick-item"
                ><span *ngIf="exercise.type !== 'meditation'"><strong>{{ exercise.type  | uppercase | translate }}</strong> -</span>
                {{ exercise.label  | uppercase | translate }}</span
              >
            </li>
            <li
              [ngClass]="{ active: exercises.length == exercisesPicked.length }"
              (click)="onPickAll()"
            >
              <span class="pick-icon">
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'circle']"
                    *ngIf="exercises.length != exercisesPicked.length"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'check-circle']"
                    *ngIf="exercises.length == exercisesPicked.length"
                  ></fa-icon>
                </span>
              </span>
              <span class="pick-item">{{ 'All of the above' | uppercase | translate }}</span>
            </li>
          </ul>

          <button
            class="btn btn-secondary rounded-pill w-100"
            (click)="onConfirmExercises()"
            [disabled]="exercisesPicked.length == 0"
          >
          {{ 'Confirm Exercises' | uppercase | translate }}
          </button>
        </div>

        <div *ngIf="exercisesSelected">
          <div
            *ngFor="let exercise of exercisesPicked; let index = index"
            [hidden]="step != index"
          >
            <h2 class="form-subheading">
              {{ exercise.label  | uppercase | translate }}
              <br />
              <span class="form-subheading-aside"
                >{{ 'Exercise' | uppercase | translate }} ({{ step + 1 | convertDigits}}/{{ exercisesPicked.length | convertDigits}})</span
              >
            </h2>

            <div class="form-group">
              <div *ngIf="exercise.type == 'pranayama'">
                <label>{{ 'REPETITIONS' | uppercase | translate }}</label>
                <div class="col-sm-6 mb-2">
                  <div
                    class="radio-group radio-group-text"
                    [ngClass]="{ chosen: reps.dirty || reps.touched }"
                  >
                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].reps"
                        #reps="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="reps_{{ exercise.id }}"
                        id="reps1_{{ exercise.id }}"
                        value="0"
                      />
                      <label
                        class="form-check-label"
                        for="reps1_{{ exercise.id }}"
                        >{{'0' | convertDigits}} <span>{{ 'reps' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].reps"
                        #reps="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="reps_{{ exercise.id }}"
                        id="reps2_{{ exercise.id }}"
                        value="1-7"
                      />
                      <label
                        class="form-check-label"
                        for="reps2_{{ exercise.id }}"
                        >{{'1-7' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].reps"
                        #reps="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="reps_{{ exercise.id }}"
                        id="reps3_{{ exercise.id }}"
                        value="8-12"
                      />
                      <label
                        class="form-check-label"
                        for="reps3_{{ exercise.id }}"
                        >{{'8-12' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].reps"
                        #reps="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="reps_{{ exercise.id }}"
                        id="reps4_{{ exercise.id }}"
                        value="13+"
                      />
                      <label
                        class="form-check-label"
                        for="reps4_{{ exercise.id }}"
                        >{{'13+' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="!reps.valid && (submitted || submittedStep[step])"
                  >
                    {{ 'Please select a value above' | uppercase | translate }}
                  </div>
                </div>
              </div>

              <div *ngIf="exercise.type == 'asana'">
                <label>{{ 'HOLDING TIME' | uppercase | translate }}</label>
                <div class="col-sm-6 mb-2">
                  <div
                    class="radio-group radio-group-text"
                    [ngClass]="{ chosen: secs.dirty || secs.touched }"
                  >
                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].secs"
                        #secs="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="secs_{{ exercise.id }}"
                        id="secs1_{{ exercise.id }}"
                        value="0"
                      />
                      <label
                        class="form-check-label"
                        for="secs1_{{ exercise.id }}"
                        >{{'0' | convertDigits}} <span>{{ 'secs' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].secs"
                        #secs="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="secs_{{ exercise.id }}"
                        id="secs2_{{ exercise.id }}"
                        value="1-7"
                      />
                      <label
                        class="form-check-label"
                        for="secs2_{{ exercise.id }}"
                        >{{'1-7' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].secs"
                        #secs="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="secs_{{ exercise.id }}"
                        id="secs3_{{ exercise.id }}"
                        value="8-14"
                      />
                      <label
                        class="form-check-label"
                        for="secs3_{{ exercise.id }}"
                        >{{'8-14' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].secs"
                        #secs="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="secs_{{ exercise.id }}"
                        id="secs4_{{ exercise.id }}"
                        value="15+"
                      />
                      <label
                        class="form-check-label"
                        for="secs4_{{ exercise.id }}"
                        >{{'15+' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="!secs.valid && (submitted || submittedStep[step])"
                  >
                    {{ 'Please select a value above' | uppercase | translate }}
                  </div>
                </div>
              </div>
              <div *ngIf="exercise.type == 'meditation'">
                <label>{{ 'DURATION' | uppercase | translate }}</label>
                <div class="col-sm-6 mb-2">
                  <div
                    class="radio-group radio-group-text"
                    [ngClass]="{ chosen: secs.dirty || secs.touched }"
                  >
                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].secs"
                        #secs="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="secs_{{ exercise.id }}"
                        id="secs1_{{ exercise.id }}"
                        value="<5"
                      />
                      <label
                        class="form-check-label"
                        for="secs1_{{ exercise.id }}"
                        >{{'<5' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].secs"
                        #secs="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="secs_{{ exercise.id }}"
                        id="secs2_{{ exercise.id }}"
                        value="6-10"
                      />
                      <label
                        class="form-check-label"
                        for="secs2_{{ exercise.id }}"
                        >{{'6-10' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].secs"
                        #secs="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="secs_{{ exercise.id }}"
                        id="secs3_{{ exercise.id }}"
                        value="11-15"
                      />
                      <label
                        class="form-check-label"
                        for="secs3_{{ exercise.id }}"
                        >{{'11-15' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].secs"
                        #secs="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="secs_{{ exercise.id }}"
                        id="secs4_{{ exercise.id }}"
                        value="16-20"
                      />
                      <label
                        class="form-check-label"
                        for="secs4_{{ exercise.id }}"
                        >{{'16-20' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].secs"
                        #secs="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="secs_{{ exercise.id }}"
                        id="secs5_{{ exercise.id }}"
                        value="21-25"
                      />
                      <label
                        class="form-check-label"
                        for="secs5_{{ exercise.id }}"
                        >{{'21-25' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                      >
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].secs"
                        #secs="ngModel"
                        class="form-check-input"
                        type="radio"
                        required
                        name="secs_{{ exercise.id }}"
                        id="secs6_{{ exercise.id }}"
                        value=">25"
                      />
                      <label
                        class="form-check-label"
                        for="secs6_{{ exercise.id }}"
                        >{{'>25' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-danger"
                    *ngIf="!secs.valid && (submitted || submittedStep[step])"
                  >
                    {{ 'Please select a value above' | uppercase | translate }}
                  </div>
                </div>
              </div>
              <div class="col-sm-6 input-difficulty">
                <label class="sub-label">{{ 'Difficulty' | uppercase | translate }}</label>

                <div
                  class="radio-group"
                  [ngClass]="{ chosen: difficulty.dirty || difficulty.touched }"
                >
                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].difficulty"
                      #difficulty="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="difficulty_{{ exercise.id }}"
                      id="difficulty1_{{ exercise.id }}"
                      value="1"
                    />
                    <label
                      class="form-check-label"
                      for="difficulty1_{{ exercise.id }}"
                      ><span class="icon-wrap">
                        <fa-icon
                          [icon]="['fas', 'grin-stars']"
                        ></fa-icon> </span
                    ></label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].difficulty"
                      #difficulty="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="difficulty_{{ exercise.id }}"
                      id="difficulty2_{{ exercise.id }}"
                      value="2"
                    />
                    <label
                      class="form-check-label"
                      for="difficulty2_{{ exercise.id }}"
                      ><span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'smile']"></fa-icon> </span
                    ></label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].difficulty"
                      #difficulty="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="difficulty_{{ exercise.id }}"
                      id="difficulty3_{{ exercise.id }}"
                      value="3"
                    />
                    <label
                      class="form-check-label"
                      for="difficulty3_{{ exercise.id }}"
                      ><span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'meh']"></fa-icon> </span
                    ></label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].difficulty"
                      #difficulty="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="difficulty_{{ exercise.id }}"
                      id="difficulty4_{{ exercise.id }}"
                      value="4"
                    />
                    <label
                      class="form-check-label"
                      for="difficulty4_{{ exercise.id }}"
                      ><span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'frown']"></fa-icon> </span
                    ></label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      [(ngModel)]="exerciseData[exercise.id].difficulty"
                      #difficulty="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="difficulty_{{ exercise.id }}"
                      id="difficulty5_{{ exercise.id }}"
                      value="5"
                    />
                    <label
                      class="form-check-label"
                      for="difficulty5_{{ exercise.id }}"
                      ><span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'tired']"></fa-icon> </span
                    ></label>
                  </div>
                </div>
              </div>
              <div
                class="alert alert-danger"
                *ngIf="!difficulty.valid && (submitted || submittedStep[step])"
              >
                {{ 'Please select a value above' | uppercase | translate }}
              </div>
            </div>
          </div>
        <div *ngIf="step === exercisesPicked.length">
          <h2 class="form-subheading">{{ 'Summary' | uppercase | translate }}</h2>
          <div class="card card-widget mt-4 mb-2 bg-primary-accent">
            <div class="card-body">
              <div class="row mt-0">
                <ng-container
                  *ngFor="let exercise of exercisesPicked; let index = index"
                >
                  <div
                    class="mb-4 col-6 col-md-3 text-start"
                    *ngIf="exerciseData[exercise.id]"
                  >
                    <h5 class="card-title text-start mb-1 mt-0">
                      {{ exercise.label  | uppercase | translate }}
                    </h5>
                    <p class="mb-1">
                      <span *ngIf="exerciseData[exercise.id].reps">
                        {{ 'Repetitions' | uppercase | translate }}
                        <strong
                          >{{ exerciseData[exercise.id].reps | convertDigits }} {{ 'reps' | uppercase | translate }}</strong
                        ><br
                      /></span>
                      <span *ngIf="exerciseData[exercise.id].secs">
                        {{ 'Holding time' | uppercase | translate }}:
                        <strong
                          >{{ exerciseData[exercise.id].secs | convertDigits}} {{'secs' | uppercase | translate}}</strong
                        ><br
                      /></span>
                      {{ 'Difficulty' | uppercase | translate }}:
                      <app-difficulty-icon
                        [difficulty]="+exerciseData[exercise.id].difficulty"
                      ></app-difficulty-icon>
                    </p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

      </div>
        <div *ngIf="exercisesSelected">
          <div class="text-center">
            <span *ngFor="let exercise of exercisesPicked; let index = index">
              <span
                class="icon-wrap mx-1"
                [ngClass]="{
                  'text-primary': index == step,
                  'text-secondary-accent': index != step
                }"
              >
                <fa-icon [icon]="['fas', 'circle']"></fa-icon>
              </span>
            </span>
            <span
              class="icon-wrap mx-1"
              [ngClass]="{
                'text-primary': exercisesPicked.length == step,
                'text-secondary-accent': exercisesPicked.length != step
              }"
            >
              <fa-icon [icon]="['fas', 'circle']"></fa-icon>
            </span>
            &nbsp;
          </div>

          <div class="row" *ngIf="step < exercisesPicked.length">
            <div class="col">
              <button
                class="btn btn-secondary rounded-pill w-100"
                *ngIf="step > 0"
                type="button"
                (click)="onPrev()"
              >
              {{ 'Previous' | uppercase | translate }}
              </button>
            </div>
            <div class="col">
              <button
                class="btn btn-secondary rounded-pill w-100"
                type="button"
                (click)="onNext()"
              >
              {{ 'Next' | uppercase | translate }}
              </button>
            </div>
          </div>

          <div class="row" *ngIf="step === exercisesPicked.length">
            <div class="col">
              <button
                class="btn btn-secondary rounded-pill w-100"
                *ngIf="step > 0"
                type="button"
                (click)="onPrev()"
              >
              {{ 'Previous' | uppercase | translate }}
              </button>
            </div>
            <div class="col">
              <button class="btn btn-secondary rounded-pill w-100">
                {{ 'Save Yoga Workout' | uppercase | translate }}
              </button>
            </div>
          </div>
        </div>

        <!--
        <div class="form-group">
          <label> Asana</label>
          <div class="col-sm-6 mb-2">
            <label class="sub-label">Holding time</label>
            <div class="radio-group radio-group-text"  [ngClass]="{'chosen':asana_time.dirty || asana_time.touched}">
              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_time="ngModel"
                  class="form-check-input"
                  type="radio"
                  
                  name="asana_time"
                  id="asana_time1"
                  value="0"
                />
                <label class="form-check-label" for="asana_time1">{{'0' | convertDigits}} <span>{{ 'secs' | uppercase | translate }}</span></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_time="ngModel"
                  class="form-check-input"
                  type="radio"
                  
                  name="asana_time"
                  id="asana_time2"
                  value="1-7"
                />
                <label class="form-check-label" for="asana_time2"
                  >{{'1-7' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_time="ngModel"
                  class="form-check-input"
                  type="radio"
                  
                  name="asana_time"
                  id="asana_time3"
                  value="8-14"
                />
                <label class="form-check-label" for="asana_time3"
                  >{{'8-14' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_time="ngModel"
                  class="form-check-input"
                  type="radio"
                  
                  name="asana_time"
                  id="asana_time4"
                  value="15+"
                />
                <label class="form-check-label" for="asana_time4"
                  >{{'15+' | convertDigits}}<span>{{ 'secs' | uppercase | translate }}</span></label
                >
              </div>
            </div>
            <div
            class="alert alert-danger"
            *ngIf="!asana_time.valid && submitted"
              >
                {{ 'Please select a value above' | uppercase | translate }}
            </div>
          </div>

          <div class="col-sm-6 input-difficulty">
            <label class="sub-label">Difficulty</label>

            <div class="radio-group"  [ngClass]="{'chosen':asana_difficulty.dirty || asana_difficulty.touched}">


              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  
                  name="asana_difficulty"
                  id="asana_difficulty1"
                  value="1"
                />
                <label class="form-check-label" for="asana_difficulty1"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'grin-stars']"></fa-icon> </span
                ></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  
                  name="asana_difficulty"
                  id="asana_difficulty2"
                  value="2"
                />
                <label class="form-check-label" for="asana_difficulty2"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'smile']"></fa-icon> </span
                ></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  
                  name="asana_difficulty"
                  id="asana_difficulty3"
                  value="3"
                />
                <label class="form-check-label" for="asana_difficulty3"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'meh']"></fa-icon> </span
                ></label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  
                  name="asana_difficulty"
                  id="asana_difficulty4"
                  value="4"
                />
                <label class="form-check-label" for="asana_difficulty4"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'frown']"></fa-icon> </span
                ></label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  
                  name="asana_difficulty"
                  id="asana_difficulty5"
                  value="5"
                />
                <label class="form-check-label" for="asana_difficulty5"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'tired']"></fa-icon> </span
                ></label>
              </div>

            </div>
          </div>
          <div
          class="alert alert-danger"
          *ngIf="!asana_difficulty.valid && submitted"
            >
              {{ 'Please select a value above' | uppercase | translate }}
          </div>
        </div>


        <div class="form-group">
            <label> Pranayama</label>
            <div class="col-sm-6 mb-2">
              <label class="sub-label">Repetitions</label>
              <div class="radio-group radio-group-text"  [ngClass]="{'chosen':pranayama_reps.dirty || pranayama_reps.touched}">
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_reps="ngModel"
                    class="form-check-input"
                    type="radio"
                    
                    name="pranayama_reps"
                    id="pranayama_reps1"
                    value="0"
                  />
                  <label class="form-check-label" for="pranayama_reps1">{{'0' | convertDigits}} <span>{{ 'reps' | uppercase | translate }}</span></label>
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_reps="ngModel"
                    class="form-check-input"
                    type="radio"
                    
                    name="pranayama_reps"
                    id="pranayama_reps2"
                    value="1-7"
                  />
                  <label class="form-check-label" for="pranayama_reps2"
                    >{{'1-7' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_reps="ngModel"
                    class="form-check-input"
                    type="radio"
                    
                    name="pranayama_reps"
                    id="pranayama_reps3"
                    value="8-12"
                  />
                  <label class="form-check-label" for="pranayama_reps3"
                    >{{'8-12' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_reps="ngModel"
                    class="form-check-input"
                    type="radio"
                    
                    name="pranayama_reps"
                    id="pranayama_reps4"
                    value="13+"
                  />
                  <label class="form-check-label" for="pranayama_reps4"
                    >{{'13+' | convertDigits}}<span>{{ 'reps' | uppercase | translate }}</span></label
                  >
                </div>
              </div>
              <div
              class="alert alert-danger"
              *ngIf="!pranayama_reps.valid && submitted"
                >
                  {{ 'Please select a value above' | uppercase | translate }}
              </div>
            </div>
  
            <div class="col-sm-6 input-difficulty">
              <label class="sub-label">Difficulty</label>
  
              <div class="radio-group"  [ngClass]="{'chosen':pranayama_difficulty.dirty || pranayama_difficulty.touched}">
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    
                    name="pranayama_difficulty"
                    id="pranayama_difficulty1"
                    value="1"
                  />
                  <label class="form-check-label" for="pranayama_difficulty1"
                    ><span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'grin-stars']"></fa-icon> </span
                  ></label>
                </div>


  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    
                    name="pranayama_difficulty"
                    id="pranayama_difficulty2"
                    value="2"
                  />
                  <label class="form-check-label" for="pranayama_difficulty2"
                    ><span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'smile']"></fa-icon> </span
                  ></label>
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    
                    name="pranayama_difficulty"
                    id="pranayama_difficulty3"
                    value="3"
                  />
                  <label class="form-check-label" for="pranayama_difficulty3"
                    ><span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'meh']"></fa-icon> </span
                  ></label>
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    
                    name="pranayama_difficulty"
                    id="pranayama_difficulty4"
                    value="4"
                  />
                  <label class="form-check-label" for="pranayama_difficulty4"
                    ><span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'frown']"></fa-icon> </span
                  ></label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    
                    name="pranayama_difficulty"
                    id="pranayama_difficulty5"
                    value="5"
                  />
                  <label class="form-check-label" for="pranayama_difficulty5"
                    ><span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'tired']"></fa-icon> </span
                  ></label>
                </div>


              </div>
            </div>
            <div
            class="alert alert-danger"
            *ngIf="!pranayama_difficulty.valid && submitted"
              >
                {{ 'Please select a value above' | uppercase | translate }}
            </div>
          </div>

        <button class="btn btn-secondary rounded-pill w-100">
          Save Yoga Workout
        </button> -->
      </form>
    </div>
  </div>
</div>
