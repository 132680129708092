import { UserWeeklyTarget } from "./../models/user-weekly-target.model";
import { UserYogaWorkout } from "./../models/user-yoga-workout.model";
import { map, catchError, publishReplay, refCount } from "rxjs/operators";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { DateHelperService } from "./date-helper.service";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class YogaWorkoutService {
  error = new Subject<string>();
  slug = "/user_yoga_workouts";
  resourceName = "user_yoga_workouts";
  weeklyTarget = {
    threshold: 1,
    days: 5,
  };
  allWorkouts?: Observable<UserYogaWorkout[]> | null = null;
  workoutsBetween?: any;

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchBetween(startDate: Date, endDate: Date): Observable<UserYogaWorkout[]> {
    if (!this.workoutsBetween) {
      this.workoutsBetween = {};
    }

    if (
      !this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, "YYYY-MM-DD") +
          "_" +
          this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
      ]
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append(
        "startDate",
        this.dateHelperService.formatDate(startDate, "YYYY-MM-DD")
      );
      searchParams = searchParams.append(
        "endDate",
        this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
      );
      this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, "YYYY-MM-DD") +
          "_" +
          this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
      ] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserYogaWorkout[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.workoutsBetween[
      this.dateHelperService.formatDate(startDate, "YYYY-MM-DD") +
        "_" +
        this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
    ];
  }
  fetchAll(): Observable<UserYogaWorkout[]> {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserYogaWorkout[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if(item.exercises){
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  clearCache() {
    this.allWorkouts = null;
    this.workoutsBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + "/" + id, {
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          const item = new UserYogaWorkout(
            +responseData.user_yoga_workout_id,
            +responseData.user_id,
            responseData.workout_date,
            responseData.asana_time,
            +responseData.asana_difficulty,
            responseData.pranayama_reps,
            +responseData.pranayama_difficulty,
            responseData.exercises,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    workout_date: string,
    exerciseData: any,
  ) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format("YYYY-MM-DD"),
      exercises : exerciseData
    };
    return this.http.post<UserYogaWorkout>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: "response",
      }
    );
  }

  update(
    id: number,
    workout_date: string,
    /*
    asana_time: string,
    asana_difficulty: number,
    pranayama_reps: string,
    pranayama_difficulty: number*/
    exerciseData: any
  ) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format("YYYY-MM-DD"),
      exercises: exerciseData,
    };
    return this.http.patch<UserYogaWorkout>(
      environment.apiUrl + this.slug + "/" + id,
      payload,
      {
        observe: "response",
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + "/" + id
    );
  }

  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: this.dateHelperService.formatDate(day, "YYYY-MM-DD"),
        exercises: "",
      };
      data.forEach((item: UserYogaWorkout) => {
        if (item.workout_date === currentDay.date) {
          currentDay.exercises = item.exercises;
        }
      });
      weekArray.push(currentDay);
    });
    return weekArray;
  }

  generateLast4WeeksArray(last4Weeks: any, data: any) {
    const weekArray: any = {
      1: { days: [], daysHit: 0 },
      2: { days: [], daysHit: 0 },
      3: { days: [], daysHit: 0 },
      4: { days: [], daysHit: 0 },
      total: { daysActive: 0 },
    };
    let weekcount = 1;
    let totalDaysActive = 0;
    last4Weeks.forEach((day: any, dayIndex: number) => {
      if (dayIndex > 6 && dayIndex < 14) {
        weekcount = 2;
      }
      if (dayIndex > 13 && dayIndex < 21) {
        weekcount = 3;
      }
      if (dayIndex > 20) {
        weekcount = 4;
      }
      let currentDay = {
        date: this.dateHelperService.formatDate(day, "YYYY-MM-DD"),
        asana_time: "",
        pranayama_reps: "",
      };
      data.forEach((item: UserYogaWorkout) => {
        if (item.workout_date === currentDay.date) {
          currentDay.asana_time = item.asana_time;
          currentDay.pranayama_reps = item.pranayama_reps;
        }
      });
      if (currentDay.asana_time != "" || currentDay.pranayama_reps != "") {
        totalDaysActive++;
      }
      if (currentDay.asana_time != "" || currentDay.pranayama_reps != "") {
        weekArray[weekcount].daysHit++;
      }
      weekArray[weekcount].days.push(currentDay);
    });
    weekArray.total.daysActive = totalDaysActive;
    return weekArray;
  }

  getDaysHit(weekArray: any) {
    let daysHit = 0;
    weekArray.forEach((day: any) => {
      if (day.exercises != "") {
        daysHit++;
      }
    });
    return daysHit;
  }

  getStats(
    workoutData: UserYogaWorkout[],
    weeklyTargets: UserWeeklyTarget[] | null
  ) {
    let stats = {
      total: workoutData.length,
      activeDays: 0,
      longest: "",
      targetHit: 0,
      streak: 0,
    };

    let activeDays: any = [];
    workoutData.forEach((workout) => {
      activeDays.push(workout.workout_date);
    });
    let uniqueDays = activeDays.filter(this.onlyUnique);

    stats.activeDays = uniqueDays.length;

    let uniqueDaysAsc = uniqueDays.reverse();
    let currentStreak = 0;
    uniqueDaysAsc.forEach((day: string, index: number) => {
      if (index > 0) {
        if (moment(day).diff(moment(uniqueDaysAsc[index - 1]), "days") != 1) {
          currentStreak = 0;
        }
      }
      currentStreak++;
      if (currentStreak > stats.streak) {
        stats.streak++;
      }
    });

    if (weeklyTargets != null) {
      weeklyTargets.forEach((target: UserWeeklyTarget, index: number) => {
        if (+target.walking == 1) {
          stats.targetHit++;
        }
      });
    }

    return stats;
  }

  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  getMonths(workouts: UserYogaWorkout[]) {
    let months: any = [];
    workouts.forEach((workout) => {
      if (
        months.indexOf(moment(workout.workout_date).format("MMMMYY")) === -1
      ) {
        months.push(moment(workout.workout_date).format("MMMMYY"));
      }
    });
    return months;
  }

  getCurrentStreak(walks: UserYogaWorkout[]) {
    let currentStreak = 0;
    let activeDays: any = [];
    walks.forEach((walk) => {
      activeDays.push(walk.workout_date);
    });
    let uniqueDays = activeDays.filter(this.onlyUnique);
    let streakBroken = false;
    if (moment().format("YYYY-MM-DD") == moment(uniqueDays[0]).format("YYYY-MM-DD")) {
      uniqueDays.forEach((day: string, index: number) => {
        if (index > 0) {
          if (moment(day).diff(moment(uniqueDays[index - 1]), "days") != -1) {
            streakBroken = true;
          }
        }
        if (streakBroken == false) {
          currentStreak++;
        }
      });
    }
    return currentStreak;
  }
}
