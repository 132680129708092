import { defineLocale } from 'ngx-bootstrap/chronos';
import { ComponentCanDeactivate } from './../../guards/pending-changes.guard';
import { UserWalking } from "./../../models/user-walking.model";
import { ActivatedRoute } from "@angular/router";
import { forkJoin, Observable } from "rxjs";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { WalkingService } from "./../../services/walking.service";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import * as moment from "moment";
import { AchievementService } from "src/app/services/achievement.service";
import { TranslateService } from '@ngx-translate/core';
import { DigitLocaleService } from 'src/app/services/digit-locale.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { hiLocale } from 'ngx-bootstrap/locale';
import { mrLocale } from 'src/app/i18n/mr';
defineLocale('hindi', hiLocale);
defineLocale('marathi', mrLocale);

@Component({
  selector: "app-edit-walking-entry",
  templateUrl: "./edit-walking-entry.component.html",
  styleUrls: ["./edit-walking-entry.component.scss"],
})
export class EditWalkingEntryComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error!: string;
  public errors: any = [];
  isLoading = false;
  public entryId!: number;
  public userWalk?: UserWalking;
  maxDate = new Date();
  workoutDate?: Date;

  constructor(
    private walkingService: WalkingService,
    private router: Router,
    private route: ActivatedRoute,
    private achievementService: AchievementService,
    private translate: TranslateService,
    private localeService: BsLocaleService,
    private digitLocaleService:DigitLocaleService
  ) {}

  ngOnInit(): void {
    console.log(this.translate.currentLang);

    if(this.translate.currentLang == 'hindi'){
      this.localeService.use(this.translate.currentLang);
    }
    else if(this.translate.currentLang == 'marathi'){
      this.localeService.use(this.translate.currentLang);
    }
    else{
      this.localeService.use('en');
    }

    this.route.params.subscribe((params) => {
      this.entryId = params.id;
      this.isLoading = true;
      forkJoin({
        userWalk: this.walkingService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.userWalk = responseData.userWalk;
          this.workoutDate = moment(this.userWalk.date_walked).toDate();
          if(this.translate.currentLang && this.translate.currentLang != 'en'){
            // tanslate the numbers
            this.userWalk.minutes = this.digitLocaleService.convertDigits(this.userWalk.minutes,'en',this.translate.currentLang);
            this.userWalk.seconds = this.digitLocaleService.convertDigits(this.userWalk.seconds,'en',this.translate.currentLang);
          }
        },
        (error) => {
          this.errors.push(error.message);
        }
      );
    });
    if(this.translate.currentLang == 'hindi'){
      this.localeService.use(this.translate.currentLang);
    }
    else{
      this.localeService.use('en');
    }
  }

  onSubmit() {
    this.submitted = true;
    // convert hindi / marathi characters
    if( this.entryForm?.value.minutes){
      this.entryForm.value.minutes = this.digitLocaleService.convertDigits(this.entryForm?.value.minutes, this.translate.currentLang, 'en');
    }
    if( this.entryForm?.value.seconds){
      this.entryForm.value.seconds = this.digitLocaleService.convertDigits(this.entryForm?.value.seconds, this.translate.currentLang, 'en');
    }

    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: this.translate.instant("THIS FORM IS INVALID"),
        text: this.translate.instant("PLEASE CHECK THE FORM FOR ERRORS"),
        showConfirmButton: true,
      });
    } else {
      // update entry
      const resourceIds = [];
      this.walkingService
        .update(
          this.entryId,
          +this.entryForm?.value.minutes,
          +this.entryForm?.value.seconds,
          +this.entryForm?.value.difficulty,
          this.entryForm?.value.date
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: this.translate.instant("ENTRY UPDATED"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/walking", "workouts"]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {

    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    
    if(this.submitted){
      return true;
    }
    else if(!this.entryForm?.value.minutes && !this.entryForm?.value.seconds && !this.entryForm?.value.difficulty){
      return true;
    }
    else{
      return false;
    }
  }
}
