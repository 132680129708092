import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() inline:any;
  longWait = false;
  currentLang = 'en';
  constructor(private translate:TranslateService) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    setTimeout((count:number) => {
      this.longWait = true;
    }, 10000);
  }

  refresh(): void {
    window.location.reload();
  }
}
