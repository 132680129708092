<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Walking Guide' | uppercase | translate"
    [icon]="'walking'"
    [backLink]="['/walking']"
    *ngIf="area == 'walking'"
  ></app-page-header>

  <app-page-header
    [title]="'Strength Guide' | uppercase | translate"
    [icon]="'strength'"
    [backLink]="['/strength']"
    *ngIf="area == 'strength'"
  ></app-page-header>

  <app-page-header
    [title]="'Yoga Guide' | uppercase | translate"
    [icon]="'yoga'"
    [backLink]="['/yoga']"
    *ngIf="area == 'yoga'"
  ></app-page-header>

  <app-page-header
    [title]="'Resources' | uppercase | translate"
    [icon]="'symptoms'"
    [backLink]="['/managing']"
    *ngIf="area == 'managing'"
  ></app-page-header>

  <div class="relative">
    <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
    <div>
      <img
        *ngIf="resource?.image_filename"
        src="{{ resource.image_filename | getThumbnail: 'wide' }}"
        alt="Placeholder image"
        class="rounded-lg img-fluid fade-in d-none d-sm-block mb-4"
      />

      <img
        *ngIf="resource?.image_filename"
        src="{{ resource.image_filename | getThumbnail: 'medium-landscape' }}"
        alt="Placeholder image"
        class="rounded-lg img-fluid fade-in d-block d-sm-none mb-4"
      />

      <div class="card bg-primary-accent">
        <div class="card-body">
          <div>
            <h2 class="card-title text-start">
              <span class="icon-wrap text-secondary-accent">
                <fa-icon [icon]="['fas', 'book-reader']"></fa-icon>
              </span>
             {{resource?.title | uppercase | translate}}
            </h2>
            <ul class="task-list task-list-todo task-list-bt" *ngIf="area!='managing'">
              <ng-container *ngFor="let page of pages"
                ><li>
                  <a
                    class="task"
                    [routerLink]="['/' + area, 'guide', page.content_page_id]"
                  >
                    <span class="num">
                      <span class="icon-wrap">
                        <fa-icon
                          *ngIf="!page.icon"
                          [icon]="['fas', 'align-left']"
                          [fixedWidth]="true"
                        ></fa-icon>
                        <fa-icon
                          *ngIf="page.icon"
                          [icon]="['far', page.icon]"
                          [fixedWidth]="true"
                        ></fa-icon>
                      </span>
                    </span>
                    <span class="title">{{ page.title }}</span>
                    <span class="status">
                      <span class="icon-wrap text-secondary-accent">
                        <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
                      </span>
                    </span>
                  </a>
                </li>
              </ng-container>
            </ul>
            <ul class="task-list task-list-todo task-list-bt" *ngIf="area=='managing'">
              <ng-container *ngFor="let page of pages"
                ><li>
                  <a
                    class="task"
                    [routerLink]="['/' + area, 'resources', page.content_section_id, page.content_page_id]"
                  >
                    <span class="num">
                      <span class="icon-wrap">
                        <fa-icon
                          *ngIf="!page.icon"
                          [icon]="['fas', 'align-left']"
                          [fixedWidth]="true"
                        ></fa-icon>
                        <fa-icon
                          *ngIf="page.icon"
                          [icon]="['far', page.icon]"
                          [fixedWidth]="true"
                        ></fa-icon>
                      </span>
                    </span>
                    <span class="title">{{ page.title }}</span>
                    <span class="status">
                      <span class="icon-wrap text-secondary-accent">
                        <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
                      </span>
                    </span>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
