<div
  class="achievement"
  [ngClass]="{
    'rounded-circle':
      achievement.type === 'badge-workouts' ||
      achievement.type === 'badge-goals',
      'achievement-primary' : achievement.type === 'week-streak'
  }"
>
  <div class="achievement-bg">
    <span class="icon-wrap">
      <fa-icon
        [icon]="['fas', 'heartbeat']"
        *ngIf="achievement.type !== 'badge-goals' && achievement.type !== 'week-streak'"
      ></fa-icon>
      <fa-icon
        [icon]="['fas', 'star']"
        *ngIf="achievement.type === 'badge-goals'"
      ></fa-icon>
    </span>
  </div>



  <div class="achievement-content" *ngIf="achievement.type === 'week-streak'">
    <div class="achievement-row">
      <div class="achievement-icon">
        <app-icon [icon]="'recharge'"></app-icon>
      </div>
      <div class="achievement-value">{{ ''+achievement.total | translate}}</div>
    </div>
    <div>
      <div class="achievement-label">
        <span 
          >{{ 'WEEK STREAK' | translate }}</span
        >
      </div>
    </div>
  </div>


  <div class="achievement-content" *ngIf="achievement.type !== 'week-streak'">
    <div *ngIf="achievement.type === 'badge-workout-streak'">
      <div class="achievement-row">
        <div class="achievement-icon">
          <app-icon [icon]="achievement.area"></app-icon>
        </div>
        <div class="achievement-value">{{ '' + achievement.total | translate }}</div>
      </div>
      <div>
        <div class="achievement-label">
          <span *ngIf="achievement.type === 'badge-workout-streak'"
            >{{ (achievement.unit | uppercase) + ' STREAK' | translate }}</span
          >
        </div>
      </div>
    </div>
    <div *ngIf="achievement.type === 'badge-workouts'">
      <div class="achievement-value">{{ '' + achievement.total | translate }}</div>
      <div class="achievement-label">
        <span *ngIf="achievement.type === 'badge-workouts'">{{ 'WORKOUTS' | translate }}</span>
      </div>
      <div class="achievement-icon">
        <app-icon [icon]="achievement.area"></app-icon>
      </div>
      <div></div>
    </div>
    <div *ngIf="achievement.type === 'badge-goals'">

      <div class="achievement-row">
        <div class="achievement-icon">
          <app-icon [icon]="achievement.area"></app-icon>
        </div>
        <div class="achievement-value">{{ '' + achievement.total | translate }}</div>
      </div>
      <div>
        <div class="achievement-label">
          <span>{{ 'GOALS' | translate }}</span>
        </div>
      </div>

    </div>
  </div>
</div>
