import { CanDeactivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import Swal from "sweetalert2/src/sweetalert2.js";
import { TranslateService } from "@ngx-translate/core";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<ComponentCanDeactivate>
{

  constructor(private translate:TranslateService){

  }
  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first

    if (component.canDeactivate()) {
      return true;
    } else {
      return Observable.create((observer: any) => {
        Swal.fire({
          title: this.translate.instant("UNSAVED CHANGES"),
          text: this.translate.instant("YOU HAVE UNSAVED CHANGES IN YOUR FORM. IF YOU WISH TO SAVE THEM GO BACK AND PRESS THE SAVE BUTTON."),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.translate.instant("YES, GO BACK AND SAVE THEM"),
          cancelButtonText: this.translate.instant("CONTINUE WITHOUT SAVING"),
        }).then((result) => {
          if (result.value == true) {
            observer.next(false);
          } else {
            observer.next(true);
          }
        });
      });
    }
  }
}
