<div class="fh bg-light pb-10 content-container">

  <app-page-header
  *ngIf="area === 'walking'"
    [title]="'Create Walking Goal' | uppercase | translate"
    [icon]="'walking'"
    [backLink]="['/walking']"
  ></app-page-header>


  <app-page-header
  *ngIf="area === 'strength'"
    [title]="'Create Strength Goal' | uppercase | translate"
    [icon]="'strength'"
    [backLink]="['/strength']"
  ></app-page-header>


  <app-page-header
  *ngIf="area === 'yoga'"
    [title]="'Create Yoga Goal' | uppercase | translate"
    [icon]="'yoga'"
    [backLink]="['/yoga']"
  ></app-page-header>


  <div class="row">
    <div class="col">
      <form class="form-recharge" (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
          <label for="duration text-center">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'star']"></fa-icon>
            </span>
            {{ 'YOUR GOAL' | uppercase | translate }}</label
          >
          <div class="col-12">
            <p>{{'Please select a goal from the list and press the "Create goal" button.  If you select "Custom goal" you can enter your own goal in the text box.' | uppercase | translate}}</p>

            <ul class="pick-list">
              <li
                *ngFor="let suggestedGoal of getSuggestedGoals(area); let index = index"
                [ngClass]="{ active: suggestedGoal === goalText }"
                (click)="onPickGoal(suggestedGoal)"
              >
                <span class="pick-icon">
                  <span class="icon-wrap">
                    <fa-icon
                      [icon]="['far', 'circle']"
                      *ngIf="suggestedGoal !== goalText"
                    ></fa-icon>
                    <fa-icon
                      [icon]="['fas', 'check-circle']"
                      *ngIf="suggestedGoal === goalText"
                    ></fa-icon>
                  </span>
                </span>
                <span class="pick-item">{{ suggestedGoal | uppercase | translate}}</span>
              </li>
              <li (click)="onPickGoal('custom')"><span class="pick-icon">
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'circle']"
                    *ngIf="!customGoal"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'check-circle']"
                    *ngIf="customGoal"
                  ></fa-icon>
                </span>
              </span>
              <span class="pick-item">{{ 'Custom Goal' | uppercase | translate }}</span></li>
            </ul>

            <textarea
            [hidden]="!customGoal"
              id="goal"
              class="form-control"
              [(ngModel)]="goalText"
              #goal="ngModel"
              name="goal"
              placeholder="{{ 'Enter your text' | uppercase | translate }}"
              required
              rows="6"
            ></textarea>

          <div
          class="alert alert-danger"
          *ngIf="!goal.valid && submitted"
            >
              Please enter a goal above
          </div>
          </div>
        </div>

        <button class="btn btn-secondary rounded-pill w-100">
          {{ 'Create Goal' | uppercase | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
