<div class="fh bg-light pb-10 content-container">
    <app-page-header
      [title]="'Search' | uppercase | translate"
      [icon]="'search'"
      [backLink]="['/dashboard']"
    ></app-page-header>
  
<div class="container">
  <div class="row" *ngIf="results">
    <div class="col">
      <p *ngIf="currentLang == 'en'">
        Your search for {{ query }} returned {{ results.total }} result<span
          *ngIf="+results.total != 1"
          >s</span
        >
      </p>
      <p *ngIf="currentLang != 'en'">
        {{ 'YOUR SEARCH FOR X RETURNED X RESULTS' | uppercase | translate : {query:query, results:results.total} | convertDigits}}
      </p>

      <div *ngIf="results.resources.length > 0">
        <h4>{{ 'Resources' | uppercase | translate}}</h4>
        <ul class="forum-posts">
          <li *ngFor="let result of results.resources" class="search-result">
            <a [routerLink]="getResourceUrl(result)" class="topic-title"
              >{{ result.content_section_title | uppercase | translate }} > {{ result.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

</div>