export class UserSymptom {
    constructor(
      public user_symptom_id: number,
      public user_id: number,
      public normal: number,
      public symptom_date: string,
      public breathlessness_rest: number,
      public breathlessness_active: number,
      public sputum: number,
      public sputum_colour: number,
      public wheeze: number,
      public fever: number,
      public wellbeing: number,
      public total_up: number,
      public total_down: number,
      public created: string,
      public modified: string,
    ) {}
  }
