import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AuthService } from './../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  isLoading = false;
  error?: string;
  id: number = 0;
  hash: string = '';
  password1 = '';
  password2 = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.hash = this.route.snapshot.params['hash'];

    // check to see if this hash is valid
    this.authService.checkForgottenPasswordHash(this.id, this.hash).subscribe(
      (returnData) => {},
      (error) => {
        this.isLoading = false;
        Swal.fire('Invalid link', 'This link is no longer valid.', 'error');
        this.router.navigate(['/']);
      }
    );

    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        if (this.authService.redirect) {
          this.router.navigate([this.authService.redirect]);
        } else {
          this.router.navigate(['/']);
        }
      }
    });
  }
  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const password = this.password1;
    const passwordConfirm = this.password2;
    let authObs: Observable<any>;
    this.isLoading = true;
    authObs = this.authService.resetPassword(this.id, this.hash, password);

    authObs.subscribe(
      (resData) => {
        this.isLoading = false;
        Swal.fire(
          'Password Reset',
          'Your password has been reset, please login',
          'success'
        );
        this.router.navigate(['/login']);
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );

    form.reset();
  }

  checkPassword(password: string, check: string) {
    return this.authService.checkPassword(password, check);
  }
}
