<div class="fh bg-light pb-10 content-container">
  <app-page-header
    *ngIf="!moreSection && page"
    [title]="page.title"
    [icon]="''"
    [backLink]="['/dashboard']"
  ></app-page-header>
  <app-page-header
    *ngIf="moreSection && page"
    [title]="'More' | uppercase | translate"
    [icon]="''"
    [backLink]="['/more']"
  ></app-page-header>
  <div class="row" *ngIf="page">
    <div class="col">
      <img
        src="{{ page.image_filename | getThumbnail: 'medium-landscape' }}"
        alt=""
        class="img-fluid rounded-lg mt-2 mb-3"
        *ngIf="page.image_filename"
      />

      <h2 class="inline-title" *ngIf="moreSection">{{ page.title }}</h2>
      <div (click)="onContentClick($event)">
        <div
          [innerHTML]="page?.content | safeHtml"
          *ngIf="page?.page_type_id === '1'"
        ></div>
        <div *ngIf="page?.page_type_id === '2'">
          <div [innerHTML]="page?.content.content | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>
</div>
