import { TranslateService } from '@ngx-translate/core';
import { UserTargetService } from './user-target.service';
import { UserWeeklyTarget } from "./../models/user-weekly-target.model";
import { UserWalking } from "./../models/user-walking.model";
import { map, catchError, publishReplay, refCount } from "rxjs/operators";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { DateHelperService } from "./date-helper.service";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class WalkingService {
  error = new Subject<string>();
  slug = "/user_walkings";
  resourceName = "user_walkings";
  weeklyTarget = {
    threshold: 0,
    days: 5,
  };
  allWalks?: Observable<UserWalking[]> | null = null;
  walksBetween?: any;

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService,
    private userTargetService: UserTargetService,
    private translate:TranslateService
  ) {
  }

  fetchBetween(startDate: Date, endDate: Date): Observable<UserWalking[]> {
    if (!this.walksBetween) {
      this.walksBetween = {};
    }

    if (
      !this.walksBetween[
        this.dateHelperService.formatDate(startDate, "YYYY-MM-DD") +
          "_" +
          this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
      ]
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append(
        "startDate",
        this.dateHelperService.formatDate(startDate, "YYYY-MM-DD")
      );
      searchParams = searchParams.append(
        "endDate",
        this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
      );

      this.walksBetween[
        this.dateHelperService.formatDate(startDate, "YYYY-MM-DD") +
          "_" +
          this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
      ] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserWalking[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.walksBetween[
      this.dateHelperService.formatDate(startDate, "YYYY-MM-DD") +
        "_" +
        this.dateHelperService.formatDate(endDate, "YYYY-MM-DD")
    ];
  }
  fetchAll(): Observable<UserWalking[]> {
    if (!this.allWalks) {
      this.allWalks = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserWalking[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWalks;
  }

  clearCache() {
    this.allWalks = null;
    this.walksBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + "/" + id, {
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          const item = new UserWalking(
            +responseData.user_walking_id,
            +responseData.user_id,
            +responseData.minutes,
            +responseData.seconds,
            +responseData.difficulty,
            responseData.date_walked,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    minutes: number,
    seconds: number,
    difficulty: number,
    date_walked: string
  ) {
    this.clearCache();
    const payload = {
      minutes,
      seconds,
      difficulty,
      date_walked: moment(date_walked).format("YYYY-MM-DD"),
    };
    return this.http.post<UserWalking>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: "response",
      }
    );
  }

  update(
    id: number,
    minutes: number,
    seconds: number,
    difficulty: number,
    date_walked: string
  ) {
    this.clearCache();
    const payload = {
      minutes,
      seconds,
      difficulty,
      date_walked: moment(date_walked).format("YYYY-MM-DD"),
    };
    return this.http.patch<UserWalking>(
      environment.apiUrl + this.slug + "/" + id,
      payload,
      {
        observe: "response",
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + "/" + id
    );
  }

  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: this.dateHelperService.formatDate(day, "YYYY-MM-DD"),
        minutes: 0,
        seconds: 0,
      };
      data.forEach((item: UserWalking) => {
        if (item.date_walked === currentDay.date) {
          currentDay.minutes += +item.minutes;
          currentDay.seconds += +item.seconds;
        }
      });
      weekArray.push(currentDay);
    });

    // combine the seconds into minutes
    weekArray.forEach((weekItem: any, index: number) => {
      let secondsToMins = Math.floor(weekItem.seconds / 60);
      weekItem.minutes += secondsToMins;
      weekItem.seconds = weekItem.seconds - secondsToMins * 60;
      weekArray[index] = weekItem;
    });

    return weekArray;
  }

  generateLast4WeeksArray(last4Weeks: any, data: any) {
    const weekArray: any = {
      1: { days: [], daysHit: 0 },
      2: { days: [], daysHit: 0 },
      3: { days: [], daysHit: 0 },
      4: { days: [], daysHit: 0 },
      total: { daysActive: 0 },
    };
    let weekcount = 1;
    let totalDaysActive = 0;
    last4Weeks.forEach((day: any, dayIndex: number) => {
      if (dayIndex > 6 && dayIndex < 14) {
        weekcount = 2;
      }
      if (dayIndex > 13 && dayIndex < 21) {
        weekcount = 3;
      }
      if (dayIndex > 20) {
        weekcount = 4;
      }
      let currentDay = {
        date: this.dateHelperService.formatDate(day, "YYYY-MM-DD"),
        minutes: 0,
        seconds: 0,
      };
      data.forEach((item: UserWalking) => {
        if (item.date_walked === currentDay.date) {
          currentDay.minutes += +item.minutes;
          currentDay.seconds += +item.seconds;
        }
      });

      // total the minutes
      currentDay.minutes += Math.floor(currentDay.seconds / 60);

      if (currentDay.minutes > 0 || currentDay.seconds > 0) {
        totalDaysActive++;
      }
      if (currentDay.minutes >= this.weeklyTarget.threshold) {
        weekArray[weekcount].daysHit++;
      }
      weekArray[weekcount].days.push(currentDay);
    });
    weekArray.total.daysActive = totalDaysActive;
    return weekArray;
  }

  getDaysHit(weekArray: any) {
    let daysHit = 0;
    weekArray.forEach((day: any) => {
      if (+day.minutes >= this.weeklyTarget.threshold) {
        daysHit++;
      }
    });
    return daysHit;
  }

  getStats(walkData: UserWalking[], weeklyTargets: UserWeeklyTarget[] | null) {
    let stats = {
      timeSpent: 0,
      timeSpentFormatted: { hours: 0, minutes: 0 },
      total: walkData.length,
      activeDays: 0,
      longest: 0,
      targetHit: 0,
      streak: 0,
    };

    let activeDays: any = [];
    walkData.forEach((walk) => {
      activeDays.push(walk.date_walked);
      stats.timeSpent += +walk.minutes;
      if (+walk.minutes > stats.longest) {
        stats.longest = +walk.minutes;
      }
    });
    let uniqueDays = activeDays.filter(this.onlyUnique);
    let uniqueDaysAsc = uniqueDays.reverse();
    let currentStreak = 0;
    uniqueDaysAsc.forEach((day: string, index: number) => {
      if (index > 0) {
        if (moment(day).diff(moment(uniqueDaysAsc[index - 1]), "days") != 1) {
          currentStreak = 0;
        }
      }
      currentStreak++;
      if (currentStreak > stats.streak) {
        stats.streak++;
      }
    });

    stats.activeDays = uniqueDays.length;

    // time spent
    const hours = Math.floor(stats.timeSpent / 60);
    const minutes = stats.timeSpent % 60;
    stats.timeSpentFormatted.hours = hours;
    stats.timeSpentFormatted.minutes = minutes;

    if (weeklyTargets != null) {
      weeklyTargets.forEach((target: UserWeeklyTarget, index: number) => {
        if (+target.walking == 1) {
          stats.targetHit++;
        }
      });
    }

    return stats;
  }


  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  getMonths(walks: UserWalking[]) {
    let months: any = [];
    walks.forEach((walk) => {
      if (months.indexOf(moment(walk.date_walked).format("MMMMYY")) === -1) {
        months.push(moment(walk.date_walked).format("MMMMYY"));
      }
    });
    return months;
  }

  getCurrentStreak(walks: UserWalking[]) {
    let currentStreak = 0;
    let activeDays: any = [];
    walks.forEach((walk) => {
      activeDays.push(walk.date_walked);
    });
    let uniqueDays = activeDays.filter(this.onlyUnique);

    let streakBroken = false;

    if (
      moment().format("YYYY-MM-DD") ==
      moment(uniqueDays[0]).format("YYYY-MM-DD")
    ) {
      uniqueDays.forEach((day: string, index: number) => {
        if (index > 0) {
          if (moment(day).diff(moment(uniqueDays[index - 1]), "days") != -1) {
            streakBroken = true;
          }
        }
        if (streakBroken == false) {
          currentStreak++;
        }
      });
    }
    return currentStreak;
  }

  getWeekReport(allWalks: UserWalking[], week:any, targets:any){
    let target = 45;
    let targetSet = false;
    targets.forEach((userTarget:any) => {
      if(moment(userTarget.created).toDate() < moment(week[6]).toDate() && !targetSet){
        target = userTarget.target;
        targetSet = true;
      }
    });
    const weekArray: any = [];
    week.forEach((day: any) => {
      let currentDay = {
        date: this.dateHelperService.formatDate(day, "YYYY-MM-DD"),
        minutes: 0,
        seconds: 0,
        difficulty:0,
        difficultyAvg:0,
        walks: 0,
      };
      allWalks.forEach((item: UserWalking) => {
        if (item.date_walked === currentDay.date) {
          currentDay.minutes += +item.minutes;
          currentDay.seconds += +item.seconds;
          currentDay.difficulty += +item.difficulty;
          currentDay.walks++;
        }
      });
      weekArray.push(currentDay);
    });

    // combine the seconds into minutes
    weekArray.forEach((weekItem: any, index: number) => {
      let secondsToMins = Math.floor(weekItem.seconds / 60);
      weekItem.minutes += secondsToMins;
      weekItem.seconds = weekItem.seconds - secondsToMins * 60;
      if(weekItem.walks > 0){
        weekItem.difficultyAvg = weekItem.difficulty/weekItem.walks;
      }
      weekArray[index] = weekItem;
    });

    // check for green week
    let greenDays = 0;
    let redDays = 0;
    let redRun = 0;
    let greenRun = 0;
    let redRunMax = 0;
    let greenRunMax = 0;
    let hitTarget = 0;
    let progress = false;
    let reduce = false;
    let totalWalks = 0;
    let daysWalked = 0;
    for(let i=0;i<7;i++){
      totalWalks+= weekArray[i].walks;
      if(weekArray[i].walks>0){
        daysWalked++;
      }
      if(weekArray[i].walks > 0 && weekArray[i].difficultyAvg<=2){
        greenDays++;
        greenRun++;
        redRun = 0;
      }
      if(weekArray[i].walks > 0 && weekArray[i].difficultyAvg>=4){
        redDays++;
        greenRun = 0;
        redRun++;
      }
      if(redRun>redRunMax){
        redRunMax = redRun;
      }
      if(greenRun>greenRunMax){
        greenRunMax = greenRun;
      }
      if(weekArray[i].minutes >= target){
        hitTarget++;
      }
    }

    let feedbackText = '';
    if(greenDays>6){
      feedbackText =  'Excellent work! You may consider increasing the duration of your walk by a few minutes.';
    }
    else if(greenDays>=5 && redDays==0){
      feedbackText =  'Great effort! You may want to consider increasing the duration of your walk by a few minutes.';
    }
    else if(greenDays>=5 && redDays==0 && greenRunMax>=5){
      feedbackText =  'Nice work! Keep performing the same recommended duration of walk for the upcoming week.';
    }
    else if(redRunMax>=3){
      feedbackText =  'Keep going! You may want to reduce the duration of each walk by few minutes and continue walking for 5 days / week for the next week. If you are experiencing any worsening in health condition do consult your physician and study team.';
    }
    else if(redDays>=6){
      feedbackText =  'Keep up the enthusiasm! You may want to reduce the duration of each walk by few minutes and continue walking for 5 days / week for the next week. If you are experiencing any worsening in health condition do consult your physician and study team.';
    }

    if(hitTarget>=5 && greenDays>=5 && redDays == 0){
      progress = true;
    }
    if(hitTarget<3 && redRunMax>=3){
      reduce = true;
    }
    if(feedbackText){feedbackText = this.translate.instant(feedbackText.toUpperCase());}
    return {'feedback':feedbackText, 'progress':progress, 'reduce':reduce,'totalWalks':totalWalks, 'daysWalked':daysWalked, 'weekArray': weekArray};

  }
}
