<div class="bg-section"></div>
<img
  src="/assets/img/bg-yoga.jpg"
  alt="placeholder photo"
  class="img-sticky d-block d-md-none"
/>
<div class="content-box fh">
  <h1>
    <span class="icon-wrap icon-heading-yoga">
      <app-icon [icon]="'yoga'"></app-icon>
    </span>
    {{ 'Yoga' | uppercase | translate }}
  </h1>

  <div class="row mb-3">
    <div class="col-12 col-md-6 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
            </span>
            <span class="float-end">
              <a [routerLink]="['/yoga', 'workouts']">
                <span class="icon-wrap text-secondary-accent icon-circle">
                  <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon> </span
              ></a>
            </span>
            {{ 'Workouts' | uppercase | translate }}
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
            
            {{ 'Do at least X yoga workouts this week' | uppercase | translate : {days : weeklyTarget.days}  | convertDigits}}

          <div class="entry-days mb-4">
            <div class="entry-day" *ngFor="let day of currentWeekData">
              <div class="entry-day-label">
                <span *ngIf="currentLang == 'en'">{{ day.date | localDate: "EE" | slice: 0:1 }}</span>
                <span *ngIf="currentLang !== 'en'">{{ day.date | localDate: "EE" }}</span>
              </div>
              <div class="entry-day-data na" *ngIf="day.date > today"></div>
              <div
                class="entry-day-data"
                *ngIf="day.date <= today"
                [ngClass]="{
                  complete: day.exercises!= ''
                }"
              >
                <div
                  class="entry-day-data-value"
                  *ngIf="day.exercises != ''"
                >
                  <span class="icon-wrap text-white">
                    <fa-icon [icon]="['fas', 'check']"></fa-icon>
                  </span>
                </div>
                <div
                  class="entry-day-data-value"
                  *ngIf="
                    day.exercises == '' &&
                    day.date !== today
                  "
                  [routerLink]="['/yoga', 'add-workout']"
                  [queryParams]="{d: day.date}"
                >
                  -
                </div>

                <div
                  class="entry-day-data-value relative"
                  *ngIf="
                    day.exercises == '' &&
                    day.date === today
                  "
                >
                  <a
                    [routerLink]="['/yoga', 'add-workout']"
                    class="stretched-link"
                    ><span class="icon-wrap text-secondary-accent">
                      <fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon> </span
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <p class="subtext" *ngIf="+daysHit < weeklyTarget.days">
            <span *ngIf="currentLang=='en'">
              You have completed
              <strong
                >{{ daysHit }} day<span *ngIf="daysHit != 1">s</span></strong
              >
              towards your target this week.
            </span>
            <span *ngIf="currentLang!='en'">
              {{ 'You have completed X days towards your target this week.' | uppercase | translate: {days:daysHit}  | convertDigits}}
            </span>
          </p>

          <p class="subtext" *ngIf="+daysHit >= weeklyTarget.days">
            {{ 'You have hit your target this week' | uppercase | translate }}
          </p>
        </div>
</div>
        <div class="card-footer bg-secondary relative">
          <a
            [routerLink]="['/yoga', 'add-workout']"
            class="card-link stretched-link"
            >{{ 'Add a yoga entry' | uppercase | translate }}</a
          >
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'star']"></fa-icon>
            </span>
            <span class="float-end">
              <a [routerLink]="['/yoga', 'goals']">
                <span class="icon-wrap text-secondary-accent icon-circle">
                  <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
                </span>
              </a>
            </span>
            {{ 'Goals' | uppercase | translate }}
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
          <swiper
            [config]="config"
            (swiper)="onSwiper($event)"
            (slideChange)="onSlideChange()"
            *ngIf="openGoals.length > 0"
          >
            <ng-template swiperSlide *ngFor="let goal of openGoals">
              <div class="goal-content">
                <a class="goal-checkbox" (click)="onCompleteGoal(goal)">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'check']"></fa-icon>
                  </span>
                </a>
                <div class="goal-text">
                  {{ goal.goal | uppercase | translate }}
                </div>
              </div>
            </ng-template>
          </swiper>
          <div
            class="swiper-slide"
            *ngIf="openGoals.length === 0 && allGoals.length > 0"
          >
          {{ 'No goals currently open. Please add a new one.' | uppercase | translate }}
          
          </div>
          <div class="swiper-slide" *ngIf="allGoals.length === 0">
            {{ 'Please set a goal to begin.' | uppercase | translate }}
          </div>
        </div>
        </div>
        <div class="bg-white text-center" *ngIf="allGoals.length > 0">
          <div class="row goal-status-row">
            <div class="col">
              <div class="goal-status-label">{{ 'Open' | uppercase | translate }}</div>
              <div class="goal-status-value">{{ openGoals.length | convertDigits }}</div>
            </div>
            <div class="col">
              <div class="goal-status-label">{{ 'Achieved' | uppercase | translate }}</div>
              <div class="goal-status-value">{{ completeGoals.length | convertDigits }}</div>
            </div>
          </div>
        </div>
        <div class="card-footer bg-secondary relative">
          <a
            [routerLink]="['/yoga', 'add-goal']"
            class="card-link stretched-link"
            >{{ 'Add a Goal' | uppercase | translate }}</a
          >
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 mb-4">
      <div class="card bg-light">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'book-reader']"></fa-icon>
            </span>
            {{ 'Yoga Guide' | uppercase | translate }}
          </h2>

          <img
          *ngIf="resource?.image_filename"
          src="{{ resource.image_filename | getThumbnail: 'medium-landscape' }}"
          alt="Placeholder image"
          class="img-fluid rounded-lg mt-2 mb-3"
        />
          <ul class="task-list task-list-todo task-list-bt">
            <li *ngFor="let page of pages">
              <a
                class="task"
                [routerLink]="['/yoga', 'guide', page.content_page_id]"
              >
                <span class="num">
                  <span class="icon-wrap">
                    <fa-icon
                      *ngIf="!page.icon"
                      [icon]="['fas', 'align-left']"
                      [fixedWidth]="true"
                    ></fa-icon>
                    <fa-icon
                      *ngIf="page.icon"
                      [icon]="['far', page.icon]"
                      [fixedWidth]="true"
                    ></fa-icon>
                  </span>
                </span>
                <span class="title">{{ page.title }}</span>
                <span class="status">
                  <span class="icon-wrap text-secondary-accent">
                    <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'badge-check']"></fa-icon>
            </span>
            <span class="float-end">
              <a [routerLink]="['/yoga', 'achievements']">
              <span class="icon-wrap text-secondary-accent icon-circle">
                <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
              </span>
              </a>
            </span>
            {{ 'Achievements' | uppercase | translate }}
          </h2>

          <p *ngIf="achievements.length == 0">{{ 'No achievements yet' | uppercase | translate }}.</p>
          <div class="achievements">
            <app-achievement-badge
              *ngFor="let achievement of achievements"
              [achievement]="achievement"
            ></app-achievement-badge>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
