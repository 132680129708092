import { UserService } from './../auth/service/user.service';
import { UserDataInterface } from './../auth/service/userData.interface';
import { Router } from '@angular/router';
// import { UserDataInterface } from './../services/userData.interface';
import { AuthService } from './../auth/service/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ResourcesService } from '../services/resources.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  viewHeight!: number;
  minBarHeight!: number;
  avatarBase!: any;
  userData: UserDataInterface | null = null;
  resources!: any;
  public areaId = 0;

  @Input() section: any;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private resourcesService: ResourcesService
  ) {
    this.areaId = this.resourcesService.resourceIdMap['managing'];
  }

  ngOnInit(): void {
    this.avatarBase = environment.apiUrl + environment.avatarPath;

    // are we logged in?
    this.authService.user.subscribe((user) => {
      if(user){
      this.userService.userData.subscribe(
        (userData: UserDataInterface | null) => {
          if (userData != null) {
            this.userData = userData;
          }
        },
        (error: HttpErrorResponse) => {
          // console.log(error);
        }
      );
      }
    });
    this.resourcesService.fetchArea(this.areaId).subscribe((responseData) => {
      this.resources = responseData;
    });

  }

  onLogout(): void {
    this.authService.logout();
  }

  onSearch(query: any) {
    this.router.navigate(['/search'], { queryParams: { q: query.viewModel } });
  }
}
