import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'getThumbnail'
})
export class GetThumbnailPipe implements PipeTransform {
  transform(filename: string, thumbName: string): string {
    if (!filename) return '';
    return (
      environment.staticUrl +
      '/resized/' +
      filename.replace('.', '-' + thumbName + '.')
    );
  }
}
