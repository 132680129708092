<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Account' | uppercase | translate"
    [icon]="''"
    [backLink]="['/dashboard']"
  ></app-page-header>
  <div class="row">
    <div class="col-md-12">
      <h3 class="mb-3 sm-title">{{ 'CHANGE YOUR PASSWORD' | translate }}</h3>
      <div class="alert alert-danger" *ngIf="error">
        <p>{{ error }}</p>
      </div>
      <div *ngIf="isLoading" class="text-center">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <form
        class="standard-form"
        #authForm="ngForm"
        (ngSubmit)="onSubmit(authForm)"
        *ngIf="!isLoading && !changed"
      >
        <div class="form-group">
          <label for="currentPassword"
            >{{ 'Current Password' | uppercase | translate }} <span class="required">*</span></label
          >

          <input
            type="password"
            id="currentPassword"
            class="form-control"
            ngModel
            name="currentPassword"
            required
            minlength="6"
            placeholder="{{'Your Current Password' | uppercase | translate }}"
            #currentPassword="ngModel"
          />
          <hr />
        </div>

        <div
          ngModelGroup="passwords"
          #passwords="ngModelGroup"
          appCheckPassword
        >
          <div class="form-group">
            <label for="password"
              >{{ 'New Password' | uppercase | translate }}: <span class="required">*</span></label
            >
            <input
              type="password"
              id="password"
              class="form-control"
              name="password"
              placeholder="{{'Please enter your password' | uppercase | translate }}"
              required
              pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}"
              [ngClass]="{ 'is-invalid': password.dirty && !password.valid }"
              #password="ngModel"
              [(ngModel)]="password1"
            />
            <hr />
            <label for="passwordConfirm"
              >{{ 'Confirm New Password' | uppercase | translate }}: <span class="required">*</span></label
            >
            <input
              type="password"
              id="passwordConfirm"
              class="form-control"
              name="passwordConfirm"
              placeholder="{{'Please confirm your password' | uppercase | translate }}"
              required
              [ngClass]="{
                'is-invalid': passwordConfirm.dirty && !passwordConfirm.valid
              }"
              #passwordConfirm="ngModel"
              [(ngModel)]="password2"
            />

            <div
              *ngIf="
                passwords.errors?.passwordCheck &&
                (passwordConfirm.dirty || passwordConfirm.touched)
              "
              class="alert alert-danger"
            >
            {{ 'Passwords do not match' | uppercase | translate }}.
            </div>
            <hr />

            <div *ngIf="currentLang !='en'">

            <p>{{'Your password must contain the following' | uppercase | translate}}</p>
</div>
<div *ngIf="currentLang =='en'">
            <p>{{ 'Your password must contain the following' | uppercase | translate }}</p>
            <ul>
              <li
                [ngClass]="{
                  'text-danger':
                    password.dirty && password.viewModel.length < 8,
                  'text-success':
                    password.dirty && password.viewModel.length > 7
                }"
              >
                
                {{ 'At least 8 characters' | uppercase | translate }}
                <span class="icon-wrap" *ngIf="password.viewModel.length > 7"
                  ><fa-icon
                    [icon]="['far', 'check']"
                    [fixedWidth]="true"
                  ></fa-icon></span
                ><span
                  class="icon-wrap"
                  *ngIf="password.dirty && password.viewModel.length < 8"
                  ><fa-icon
                    [icon]="['far', 'times']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
              </li>
              <li
                [ngClass]="{
                  'text-danger':
                    password.dirty &&
                    !checkPassword(password.viewModel, 'upper'),
                  'text-success':
                    password.dirty && checkPassword(password.viewModel, 'upper')
                }"
              >
                
              {{ 'An uppercase character (A-Z)' | uppercase | translate }}
                <span
                  class="icon-wrap"
                  *ngIf="checkPassword(password.viewModel, 'upper')"
                  ><fa-icon
                    [icon]="['far', 'check']"
                    [fixedWidth]="true"
                  ></fa-icon></span
                ><span
                  class="icon-wrap"
                  *ngIf="
                    password.dirty &&
                    !checkPassword(password.viewModel, 'upper')
                  "
                  ><fa-icon
                    [icon]="['far', 'times']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
              </li>
              <li
                [ngClass]="{
                  'text-danger':
                    password.dirty &&
                    !checkPassword(password.viewModel, 'lower'),
                  'text-success':
                    password.dirty && checkPassword(password.viewModel, 'lower')
                }"
              >
              {{ 'A lowercase character (a-z)' | uppercase | translate }}
                
                <span
                  class="icon-wrap"
                  *ngIf="
                    password.dirty && checkPassword(password.viewModel, 'lower')
                  "
                  ><fa-icon
                    [icon]="['far', 'check']"
                    [fixedWidth]="true"
                  ></fa-icon></span
                ><span
                  class="icon-wrap"
                  *ngIf="
                    password.dirty &&
                    !checkPassword(password.viewModel, 'lower')
                  "
                  ><fa-icon
                    [icon]="['far', 'times']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
              </li>
              <li
                [ngClass]="{
                  'text-danger':
                    password.dirty && !checkPassword(password.viewModel, 'num'),
                  'text-success':
                    password.dirty && checkPassword(password.viewModel, 'num')
                }"
              >
              {{ 'A digit (0-9)' | uppercase | translate }}<span
                  class="icon-wrap"
                  *ngIf="
                    password.dirty && checkPassword(password.viewModel, 'num')
                  "
                  ><fa-icon
                    [icon]="['far', 'check']"
                    [fixedWidth]="true"
                  ></fa-icon></span
                ><span
                  class="icon-wrap"
                  *ngIf="
                    password.dirty && !checkPassword(password.viewModel, 'num')
                  "
                  ><fa-icon
                    [icon]="['far', 'times']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
              </li>
              <li
                [ngClass]="{
                  'text-danger':
                    password.dirty &&
                    !checkPassword(password.viewModel, 'special'),
                  'text-success':
                    password.dirty &&
                    checkPassword(password.viewModel, 'special')
                }"
              >
              {{ 'A special character ($@!%*?)' | uppercase | translate }}<span
                  class="icon-wrap"
                  *ngIf="
                    password.dirty &&
                    checkPassword(password.viewModel, 'special')
                  "
                  ><fa-icon
                    [icon]="['far', 'check']"
                    [fixedWidth]="true"
                  ></fa-icon></span
                ><span
                  class="icon-wrap"
                  *ngIf="
                    password.dirty &&
                    !checkPassword(password.viewModel, 'special')
                  "
                  ><fa-icon
                    [icon]="['far', 'times']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
              </li>
            </ul>
            <div
              class="alert alert-danger"
              *ngIf="!password.valid && password.touched"
            >
            {{ 'Your password is invalid' | uppercase | translate }}
              
            </div>
          </div>
        </div>
        </div>
        <div class="form-group">
          <button
            type="submit"
            [disabled]="!authForm.valid"
            class="btn btn-primary btn-block"
          >
          {{ 'SUBMIT' | translate | titlecase }}
          </button>
        </div>
      </form>

      <p *ngIf="changed">
        {{ 'Your password has been changed.' | uppercase | translate }}
        
        <a [routerLink]="['/dashboard']">{{ 'Back to dashboard' | uppercase | translate }}</a>
      </p>
    </div>
  </div>
</div>
