import { ComponentCanDeactivate } from "./../../guards/pending-changes.guard";
import { UserSymptom } from "./../../models/user-symptom.model";
import { forkJoin, Observable } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { SymptomService } from "./../../services/symptom.service";
import { NgForm } from "@angular/forms";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-update-symptoms",
  templateUrl: "./update-symptoms.component.html",
  styleUrls: ["./update-symptoms.component.scss"],
})
export class UpdateSymptomsComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  isLoading = false;
  public normalSymptoms!: UserSymptom | undefined;
  public errors: any = [];
  public error?: any;
  currentLang = 'en';

  constructor(
    private symptomService: SymptomService,
    private router: Router,
    private route: ActivatedRoute,
    private el: ElementRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.isLoading = true;
    forkJoin({
      normalSymptoms: this.symptomService.fetchNormal(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.normalSymptoms = responseData.normalSymptoms;
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }

  onSubmit() {
    this.submitted = true;

    if (!this.entryForm?.valid) {
      Swal.fire({
        heightAuto: false,
        icon: "error",
        title: this.translate.instant("THIS FORM IS INVALID"),
        text: this.translate.instant("PLEASE CHECK THE FORM FOR ERRORS"),
        didClose: () => this.scrollToFirstInvalidControl(),
      });
    } else {
      // create entry
      const resourceIds = [];
      this.symptomService
        .create(
          this.entryForm?.value.breathlessness_rest,
          this.entryForm?.value.breathlessness_active,
          this.entryForm?.value.sputum,
          this.entryForm?.value.sputum_colour,
          this.entryForm?.value.wheeze,
          this.entryForm?.value.fever,
          this.entryForm?.value.wellbeing
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: this.translate.instant("SYMPTOMS UPDATED"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/managing"]);
            this.entryForm?.reset();
          },
          (error) => {
            this.error = error.message;
            this.entryForm?.reset();
          }
        );
    }
  }

  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector("form .ng-invalid");

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth",
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

 

  @HostListener("window:beforeunload")
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.submitted) {
      return true;
    }
  }
}
