import { UserWeeklyTarget } from "./../../models/user-weekly-target.model";
import { StrengthWorkoutService } from "./../../services/strength-workout.service";
import { UserStrengthWorkout } from "./../../models/user-strength-workout.model";
import { Component, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";
import { UserService } from "src/app/auth/service/user.service";
import { DateHelperService } from "src/app/services/date-helper.service";
import { WeeklyTargetService } from "src/app/services/weekly-target.service";
import Swal from "sweetalert2/src/sweetalert2.js";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-strength-workouts",
  templateUrl: "./strength-workouts.component.html",
  styleUrls: ["./strength-workouts.component.scss"],
})
export class StrengthWorkoutsComponent implements OnInit {
  public isLoading = false;
  public allWorkouts!: UserStrengthWorkout[];
  public allWeeklyTargets!: UserWeeklyTarget[];
  public daysHit = 0;
  public weeklyTarget = this.strengthWorkoutService.weeklyTarget;
  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public last4Weeks: Date[] = this.dateHelperService.getLast4tWeeks();
  public last4WeeksData!: any;
  public stats: any;
  public bicepCurlsWeekReport:any;
  public pullUpsWeekReport:any;
  public sitToStandWeekReport:any;
  public stepUpsWeekReport:any;
  currentLang = "en";
  showFeedback = false;

  public today = this.dateHelperService.getToday();
  public workoutMonths: string[] = [];

  exercises = [
    { id: "bicepCurl", label: "Bicep Curls" },
    { id: "pullUps", label: "Pull ups" },
    { id: "sitToStand", label: "Sit to Stand" },
    { id: "stepUps", label: "Step ups" },
  ];

  constructor(
    private strengthWorkoutService: StrengthWorkoutService,
    private userService: UserService,
    private dateHelperService: DateHelperService,
    private weeklyTargetService: WeeklyTargetService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];
    forkJoin({
      // userWalks: this.walkingService.fetchBetween(startDate, endDate),
      userData: this.userService.get(),
      allWorkouts: this.strengthWorkoutService.fetchAll(),
      allTargets: this.weeklyTargetService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        // this.userWalks = responseData.userWalks;
        this.allWorkouts = responseData.allWorkouts;
        this.allWeeklyTargets = responseData.allTargets;
        this.currentWeekData = this.strengthWorkoutService.generateWeekArray(
          this.currentWeek,
          this.allWorkouts
        );
        this.last4WeeksData =
          this.strengthWorkoutService.generateLast4WeeksArray(
            this.last4Weeks,
            this.allWorkouts
          );

        this.bicepCurlsWeekReport = this.strengthWorkoutService.getWeekReport(
          responseData.allWorkouts,
          this.dateHelperService.getLastWeek(),
          'bicepCurl'
        );
        this.pullUpsWeekReport = this.strengthWorkoutService.getWeekReport(
          responseData.allWorkouts,
          this.dateHelperService.getLastWeek(),
          'pullUps'
        );
        this.sitToStandWeekReport = this.strengthWorkoutService.getWeekReport(
          responseData.allWorkouts,
          this.dateHelperService.getLastWeek(),
          'sitToStand'
        );
        this.stepUpsWeekReport = this.strengthWorkoutService.getWeekReport(
          responseData.allWorkouts,
          this.dateHelperService.getLastWeek(),
          'stepUps'
        );

        this.daysHit = this.strengthWorkoutService.getDaysHit(
          this.currentWeekData
        );
        this.stats = this.strengthWorkoutService.getStats(
          this.allWorkouts,
          this.allWeeklyTargets
        );
        this.workoutMonths = this.strengthWorkoutService.getMonths(
          this.allWorkouts
        );
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }

  onDeleteEntry(entryId: number) {
    Swal.fire({
      title: this.translate.instant("ARE YOU SURE?"),
      text:  this.translate.instant("YOU WON'T BE ABLE TO UNDO THIS"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText:this.translate.instant("CANCEL"),
      confirmButtonText:this.translate.instant("YES, DELETE IT"),
    }).then((result) => {
      if (result.value) {
        this.strengthWorkoutService.delete(entryId).subscribe(
          (responseData) => {
            Swal.fire(this.translate.instant("DELETED"), this.translate.instant("YOUR ENTRY HAS BEEN DELETED"), "success");

            let deleteIndex = -1;
            this.allWorkouts.forEach((workout, workoutIndex) => {
              if (+workout.user_strength_workout_id === +entryId) {
                deleteIndex = workoutIndex;
              }
            });

            if (deleteIndex != -1) {
              // splice it
              this.allWorkouts.splice(deleteIndex, 1);
            }
          },
          (error) => {
            this.isLoading = false;
            this.errors.push(error.message);
            Swal.fire('Error', this.translate.instant('AN UNKNOWN ERROR OCCURRED. IF THE PROBLEM PERSISTS PLEASE CONTACT US.'), 'error');
          }
        );
      }
    });
  }

  toggleFeedback(){
    this.showFeedback = !this.showFeedback;
  }
}
