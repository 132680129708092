<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Account' | uppercase | translate"
    [icon]="''"
    [backLink]="['/dashboard']"
  ></app-page-header>
  <div class="row" *ngIf="userData">
    <div class="col-md-12">
      <h3 class="mb-3 sm-title">{{ 'Update your details' | uppercase | translate }}</h3>
      <div class="alert alert-danger" *ngIf="error">
        <p>{{ error }}</p>
      </div>
      <div *ngIf="isLoading" class="text-center">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <form
        #authForm="ngForm"
        (ngSubmit)="onSubmit(authForm)"
        *ngIf="!updated"
        class="standard-form"
        name="update-details"
      >
        <div class="form-group">
          <label for="firstName">{{ 'First Name' | uppercase | translate }}</label>
          <input
            type="text"
            id="firstName"
            class="form-control"
            [ngModel]="userData.first_name"
            name="firstName"
            placeholder="{{'First Name'| uppercase | translate}}"
            required
          />
        </div>
        <div class="form-group">
          <label for="lastName">{{ 'Last Name' | uppercase | translate }}</label>
          <input
            type="text"
            id="lastName"
            class="form-control"
            [ngModel]="userData.last_name ? userData.last_name : ''"
            name="lastName"
            placeholder="{{'Last Name'| uppercase | translate}}"
            required
          />
        </div>

        <!--div class="form-group">
            <label for="screenName">Screen Name</label>
              
              <input
                type="text"
                id="screenName"
                class="form-control"
                [ngModel]="userData.screen_name"
                name="screenName"
                placeholder="Screen Name"
                required
                pattern="[a-zA-Z0-9]*"
                #screenNameInput="ngModel"

              [ngClass]="{ 'is-invalid': screenNameInput.touched && !screenNameInput.valid }"
              />
              <span *ngIf="!screenNameInput.valid">Please use numbers letters only</span>
          </div-->
        <div class="form-group">
          <label for="email">{{ 'Email' | uppercase | translate }}</label>
          <input
            type="email"
            id="email"
            class="form-control"
            [ngModel]="userData.email"
            name="email"
            placeholder="{{'Email'| uppercase | translate}}"
            required
            email
          />
        </div>


        <div class="form-group">
          <label for="language">{{ 'Language' | uppercase | translate }}</label>
          <select
            id="language"
            class="form-select"
            [ngModel]="userData.language_id"
            name="language"
            placeholder="language"
            required
          >
          <option [value]="undefined">{{ 'Select a language' | uppercase | translate }}</option>
          <option [value]="''+language.id" *ngFor="let language of environment.languages">{{language.languageNative}}</option>
          </select>
        </div>


        <div class="form-group">
          <label for="week_start">{{ 'Week Start' | uppercase | translate }}</label>
          <select
            id="week_start"
            class="form-select"
            [ngModel]="userData.week_start"
            name="week_start"
            required
          >
          <option [value]="undefined">{{ 'Select a day' | uppercase | translate }}</option>
          <option [value]="'mon'">{{'Monday' | uppercase | translate}}</option>
          <option [value]="'tue'">{{'Tuesday' | uppercase | translate}}</option>
          <option [value]="'wed'">{{'Wednesday' | uppercase | translate}}</option>
          <option [value]="'thu'">{{'Thursday' | uppercase | translate}}</option>
          <option [value]="'fri'">{{'Friday' | uppercase | translate}}</option>
          <option [value]="'sat'">{{'Saturday' | uppercase | translate}}</option>
          <option [value]="'sun'">{{'Sunday' | uppercase | translate}}</option>
          </select>
        </div>


        <div class="form-group">
        <button
          type="submit"
          [disabled]="!authForm.valid || isLoading"
          class="btn btn-primary btn-block"
        >
        {{ 'Update' | uppercase | translate }}
        </button>
        </div>
      </form>
      <hr />
    </div>
  </div>
</div>
