<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Set Usual symptoms' | uppercase | translate"
    [icon]="'symptoms'"
    [backLink]="['/managing']"
  ></app-page-header>

  <div class="row">
    <div class="col">
      <p>{{ 'Please set your usual symptom levels' | uppercase | translate }}</p>
      <p *ngIf="normalSymptoms"><span class="icon-wrap text-secondary-accent"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span>= {{ 'Current usual symptoms' | uppercase | translate }}</p>
      <form class="form-recharge form-symptoms" (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
          <label>{{ 'Breathlessness' | uppercase | translate }}</label>
          <div class="col-12 mb-2">
            <label class="sub-label">{{ 'At rest' | uppercase | translate }}</label>


            <div class="normal-indicator-container" *ngIf="normalSymptoms">
              <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.breathlessness_rest == 0"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.breathlessness_rest == 1"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.breathlessness_rest == 2"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.breathlessness_rest == 3"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.breathlessness_rest == 4"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
            </div>

            <div class="radio-group" [ngClass]="{'chosen':breathlessness_rest.dirty || breathlessness_rest.touched}">
              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #breathlessness_rest="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="breathlessness_rest"
                  id="breathlessness_rest1"
                  value="0"
                />
                <label class="form-check-label" for="breathlessness_rest1"
                  ><span class="visually-hidden">0</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #breathlessness_rest="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="breathlessness_rest"
                  id="breathlessness_rest2"
                  value="1"
                />
                <label class="form-check-label" for="breathlessness_rest2"
                  ><span class="visually-hidden">1</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #breathlessness_rest="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="breathlessness_rest"
                  id="breathlessness_rest3"
                  value="2"
                />
                <label class="form-check-label" for="breathlessness_rest3"
                  ><span class="visually-hidden">2</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #breathlessness_rest="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="breathlessness_rest"
                  id="breathlessness_rest4"
                  value="3"
                />
                <label class="form-check-label" for="breathlessness_rest4"
                  ><span class="visually-hidden">3</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #breathlessness_rest="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="breathlessness_rest"
                  id="breathlessness_rest5"
                  value="4"
                />
                <label class="form-check-label" for="breathlessness_rest5"
                  ><span class="visually-hidden">4</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                >
              </div>
            </div>
            <div class="scale">
              <div>
                <span class="arrow-left"></span>
              </div>
              <div><span class="line"></span></div>
              <div><span class="line"></span></div>
              <div><span class="line"></span></div>
              <div class="text-end">
                <span class="arrow-right"></span>
              </div>
            </div>
            <div class="scale-labels row mt-0">
              <div class="col">
                <span *ngIf="currentLang=='en'">I am <strong><u>NOT</u><br /></strong>breathless</span>
                <span *ngIf="currentLang!='en'">{{ 'I am NOT breathless' | uppercase | translate }}</span>
              </div>
              <div class="col text-end">
                <span *ngIf="currentLang=='en'">I am <strong><u>VERY</u><br /></strong>breathless</span>
                <span *ngIf="currentLang!='en'">{{ 'I am VERY breathless' | uppercase | translate }}</span>
              </div>
            </div>
          </div>
          <div
          class="alert alert-danger"
          *ngIf="!breathlessness_rest.valid && submitted"
            >
            {{ 'Please select a value on the scale above' | uppercase | translate }}
              
          </div>
          <hr class="divider">
          <div class="col-12 mb-2">
            <label class="sub-label">{{ 'During everyday activities' | uppercase | translate }}</label>
            <div class="normal-indicator-container" *ngIf="normalSymptoms">
              <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.breathlessness_active == 0"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.breathlessness_active == 1"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.breathlessness_active == 2"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.breathlessness_active == 3"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.breathlessness_active == 4"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
            </div>
            <div class="radio-group" [ngClass]="{'chosen':breathlessness_active.dirty || breathlessness_active.touched}">
              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #breathlessness_active="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="breathlessness_active"
                  id="breathlessness_active1"
                  value="0"
                />
                <label class="form-check-label" for="breathlessness_active1"
                  ><span class="visually-hidden">0</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #breathlessness_active="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="breathlessness_active"
                  id="breathlessness_active2"
                  value="1"
                />
                <label class="form-check-label" for="breathlessness_active2"
                  ><span class="visually-hidden">1</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #breathlessness_active="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="breathlessness_active"
                  id="breathlessness_active3"
                  value="2"
                />
                <label class="form-check-label" for="breathlessness_active3"
                  ><span class="visually-hidden">2</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #breathlessness_active="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="breathlessness_active"
                  id="breathlessness_active4"
                  value="3"
                />
                <label class="form-check-label" for="breathlessness_active4"
                  ><span class="visually-hidden">3</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #breathlessness_active="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="breathlessness_active"
                  id="breathlessness_active5"
                  value="4"
                />
                <label class="form-check-label" for="breathlessness_active5"
                  ><span class="visually-hidden">4</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                >
              </div>
            </div>
            <div class="scale">
              <div>
                <span class="arrow-left"></span>
              </div>
              <div><span class="line"></span></div>
              <div><span class="line"></span></div>
              <div><span class="line"></span></div>
              <div class="text-end">
                <span class="arrow-right"></span>
              </div>
            </div>
            <div class="scale-labels row mt-0">
              <div class="col">
                <span *ngIf="currentLang=='en'">I am <strong><u>NOT</u><br /></strong>breathless</span>
                <span *ngIf="currentLang!='en'">{{ 'I am NOT breathless' | uppercase | translate }}</span>
              </div>
              <div class="col text-end">
                <span *ngIf="currentLang=='en'">I am <strong><u>VERY</u><br /></strong>breathless</span>
                <span *ngIf="currentLang!='en'">{{ 'I am VERY breathless' | uppercase | translate }}</span>
              </div>
            </div>
          </div>

          <div
          class="alert alert-danger"
          *ngIf="!breathlessness_active.valid && submitted"
            >
            {{ 'Please select a value on the scale above' | uppercase | translate }}
          </div>
        </div>



        <div class="form-group">
            <label>{{ 'Sputum' | uppercase | translate }}</label>
            <div class="col-12 mb-2">
              <label class="sub-label"> {{ 'Amount' | uppercase | translate }}</label>
              <div class="normal-indicator-container" *ngIf="normalSymptoms">
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.sputum == 0"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.sputum == 1"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.sputum == 2"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.sputum == 3"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.sputum == 4"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              </div>
              <div class="radio-group" [ngClass]="{'chosen':sputum.dirty || sputum.touched}">
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #sputum="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sputum"
                    id="sputum1"
                    value="0"
                  />
                  <label class="form-check-label" for="sputum1"
                    ><span class="visually-hidden">0</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #sputum="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sputum"
                    id="sputum2"
                    value="1"
                  />
                  <label class="form-check-label" for="sputum2"
                    ><span class="visually-hidden">1</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #sputum="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sputum"
                    id="sputum3"
                    value="2"
                  />
                  <label class="form-check-label" for="sputum3"
                    ><span class="visually-hidden">2</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #sputum="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sputum"
                    id="sputum4"
                    value="3"
                  />
                  <label class="form-check-label" for="sputum4"
                    ><span class="visually-hidden">3</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #sputum="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sputum"
                    id="sputum5"
                    value="4"
                  />
                  <label class="form-check-label" for="sputum5"
                    ><span class="visually-hidden">4</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
              </div>
              <div class="scale">
                <div>
                  <span class="arrow-left"></span>
                </div>
                <div><span class="line"></span></div>
                <div><span class="line"></span></div>
                <div><span class="line"></span></div>
                <div class="text-end">
                  <span class="arrow-right"></span>
                </div>
              </div>
              <div class="scale-labels row mt-0">
                <div class="col">
                  <span *ngIf="currentLang=='en'">I have <strong><u>NONE</u></strong></span>
                  <span *ngIf="currentLang!='en'">{{ 'I have NONE' | uppercase | translate }}</span>
                </div>
                <div class="col text-end">
                  <span *ngIf="currentLang=='en'">I have a  <strong><u>CUPFUL</u></strong></span>
                  <span *ngIf="currentLang!='en'">{{ 'I have a CUPFUL' | uppercase | translate }}</span>
                </div>
                
              </div>
            </div>

          <div
          class="alert alert-danger"
          *ngIf="!sputum.valid && submitted"
            >
            {{ 'Please select a value on the scale above' | uppercase | translate }}
          </div>
  
            <hr class="divider">
            <div class="col-12 mb-2">
              <label class="sub-label">{{ 'Colour of sputum' | uppercase | translate }}</label>
              <div class="normal-indicator-container" *ngIf="normalSymptoms">
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.sputum_colour == 0"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.sputum_colour == 1"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.sputum_colour == 2"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.sputum_colour == 3"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.sputum_colour == 4"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              </div>
              <div class="radio-group radio-group-sputum" [ngClass]="{'chosen':sputum_colour.dirty || sputum_colour.touched}">
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #sputum_colour="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sputum_colour"
                    id="sputum_colour1"
                    value="0"
                  />
                  <label class="form-check-label" for="sputum_colour1"
                    >
                    <span class="icon-wrap text-white sputum-icon"><fa-icon [icon]="['far', 'tint']"></fa-icon></span>
                    <span class="visually-hidden">0</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #sputum_colour="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sputum_colour"
                    id="sputum_colour2"
                    value="1"
                  />
                  <label class="form-check-label" for="sputum_colour2"
                    >
                    <span class="icon-wrap text-white sputum-icon"><fa-icon [icon]="['far', 'tint']"></fa-icon></span>
                    <span class="visually-hidden">1</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #sputum_colour="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sputum_colour"
                    id="sputum_colour3"
                    value="2"
                  />
                  <label class="form-check-label" for="sputum_colour3"
                    >
                    <span class="icon-wrap text-white sputum-icon"><fa-icon [icon]="['far', 'tint']"></fa-icon></span>
                    <span class="visually-hidden">2</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #sputum_colour="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sputum_colour"
                    id="sputum_colour4"
                    value="3"
                  />
                  <label class="form-check-label" for="sputum_colour4"
                    >
                    <span class="icon-wrap text-white sputum-icon"><fa-icon [icon]="['far', 'tint']"></fa-icon></span>
                    <span class="visually-hidden">3</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #sputum_colour="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sputum_colour"
                    id="sputum_colour5"
                    value="4"
                  />
                  <label class="form-check-label" for="sputum_colour5"
                    >
                    <span class="icon-wrap text-white sputum-icon"><fa-icon [icon]="['far', 'tint']"></fa-icon></span>
                    <span class="visually-hidden">4</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
              </div>
              <div class="scale">
                <div>
                  <span class="arrow-left"></span>
                </div>
                <div><span class="line"></span></div>
                <div><span class="line"></span></div>
                <div><span class="line"></span></div>
                <div class="text-end">
                  <span class="arrow-right"></span>
                </div>
              </div>
              
            </div>

          <div
          class="alert alert-danger"
          *ngIf="!sputum_colour.valid && submitted"
            >
            {{ 'Please select a value on the scale above' | uppercase | translate }}
          </div>
          </div>


          <div class="form-group">
            <label>{{ 'Wheeze' | uppercase | translate }}</label>
            <div class="col-12 mb-2">
              <div class="normal-indicator-container" *ngIf="normalSymptoms">
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.wheeze == 0"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.wheeze == 1"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.wheeze == 2"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.wheeze == 3"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.wheeze == 4"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              </div>
              <div class="radio-group" [ngClass]="{'chosen':wheeze.dirty || wheeze.touched}">
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #wheeze="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="wheeze"
                    id="wheeze1"
                    value="0"
                  />
                  <label class="form-check-label" for="wheeze1"
                    ><span class="visually-hidden">0</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #wheeze="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="wheeze"
                    id="wheeze2"
                    value="1"
                  />
                  <label class="form-check-label" for="wheeze2"
                    ><span class="visually-hidden">1</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #wheeze="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="wheeze"
                    id="wheeze3"
                    value="2"
                  />
                  <label class="form-check-label" for="wheeze3"
                    ><span class="visually-hidden">2</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #wheeze="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="wheeze"
                    id="wheeze4"
                    value="3"
                  />
                  <label class="form-check-label" for="wheeze4"
                    ><span class="visually-hidden">3</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #wheeze="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="wheeze"
                    id="wheeze5"
                    value="4"
                  />
                  <label class="form-check-label" for="wheeze5"
                    ><span class="visually-hidden">4</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
              </div>
              <div class="scale">
                <div>
                  <span class="arrow-left"></span>
                </div>
                <div><span class="line"></span></div>
                <div><span class="line"></span></div>
                <div><span class="line"></span></div>
                <div class="text-end">
                  <span class="arrow-right"></span>
                </div>
              </div>
              <div class="scale-labels row mt-0">
                <div class="col">
                  <span *ngIf="currentLang=='en'">I am <strong><u>NOT</u></strong><br>wheezy</span>
                  <span *ngIf="currentLang!='en'">{{ 'I am Not wheezy' | uppercase | translate }}</span>
                </div>
                <div class="col text-end">
                  <span *ngIf="currentLang=='en'">I am  <strong><u>VERY</u></strong><br>wheezy</span>
                  <span *ngIf="currentLang!='en'">{{ 'I am very wheezy' | uppercase | translate }}</span>
                </div>
              </div>
            </div>

          <div
          class="alert alert-danger"
          *ngIf="!wheeze.valid && submitted"
            >
            {{ 'Please select a value on the scale above' | uppercase | translate }}
          </div>
          </div>

          <div class="form-group">
            <label>
              {{ 'Fever' | uppercase | translate }}</label>
            <div class="col-12 mb-2">
              <div class="normal-indicator-container" *ngIf="normalSymptoms">
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.fever == 0"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.fever == 1"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.fever == 2"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.fever == 3"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
                <div><span class="icon-wrap text-secondary-accent" *ngIf="+normalSymptoms.fever == 4"><fa-icon [icon]="['fas', 'caret-down']"></fa-icon></span></div>
              </div>
              <div class="radio-group" [ngClass]="{'chosen':fever.dirty || fever.touched}">
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #fever="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fever"
                    id="fever1"
                    value="0"
                  />
                  <label class="form-check-label" for="fever1"
                    ><span class="visually-hidden">0</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #fever="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fever"
                    id="fever2"
                    value="1"
                  />
                  <label class="form-check-label" for="fever2"
                    ><span class="visually-hidden">1</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #fever="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fever"
                    id="fever3"
                    value="2"
                  />
                  <label class="form-check-label" for="fever3"
                    ><span class="visually-hidden">2</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #fever="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fever"
                    id="fever4"
                    value="3"
                  />
                  <label class="form-check-label" for="fever4"
                    ><span class="visually-hidden">3</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #fever="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fever"
                    id="fever5"
                    value="4"
                  />
                  <label class="form-check-label" for="fever5"
                    ><span class="visually-hidden">4</span><fa-icon [icon]="['fas', 'check']"></fa-icon></label
                  >
                </div>
              </div>
              <div class="scale">
                <div>
                  <span class="arrow-left"></span>
                </div>
                <div><span class="line"></span></div>
                <div><span class="line"></span></div>
                <div><span class="line"></span></div>
                <div class="text-end">
                  <span class="arrow-right"></span>
                </div>
              </div>
              <div class="scale-labels row mt-0">
                <div class="col">
                  <span *ngIf="currentLang=='en'"> I have <br><strong><u>NO</u></strong> fever</span>
                  <span *ngIf="currentLang!='en'">{{ 'I have no fever' | uppercase | translate }}</span>
                </div>
                <div class="col text-end">
                  <span *ngIf="currentLang=='en'">I am   <strong><u>VERY</u></strong><br> feverish</span>
                  <span *ngIf="currentLang!='en'">{{ 'I am very feverish' | uppercase | translate }}</span>
                </div>
              </div>
            </div>

          <div
          class="alert alert-danger"
          *ngIf="!fever.valid && submitted"
            >
              
            {{ 'Please select a value on the scale above' | uppercase | translate }}
          </div>
          </div>

          
          

        <button class="btn btn-secondary rounded-pill w-100">
         
          {{ 'Update symptoms' | uppercase | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
