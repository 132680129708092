<div class="bg-section"></div>
<img
  src="/assets/img/bg-photo.jpg"
  alt="placeholder photo"
  class="img-sticky d-block d-md-none"
/>
<div class="content-box fh">
  <h1>
    <small class="float-end" *ngIf="currentLang == 'en'"
      >{{ currentWeek[0] | localDate: "EEE d MMM" }} -
      {{ currentWeek[6] | localDate: "EEE d MMM" }}</small
    >
    <small class="float-end" *ngIf="currentLang !== 'en'"
      >{{ currentWeek[0] | localDate: "EEE d MMMM" }} -
      {{ currentWeek[6] | localDate: "EEE d MMMM" }}</small
    >
    <span class="icon-wrap">
      <app-icon [icon]="'home'"></app-icon>
    </span>
    
    {{ 'THIS WEEK' | translate }}
  </h1>
  <div class="row">
    <div class="col-6 col-lg-3 mb-4">
      <div class="card bg-primary-accent relative">
        <div class="card-body">
          <h2 class="card-title">
            {{ 'WALKING' | translate }}</h2>
          <div class="widget-content">
            <div class="icon-wrap">
              <app-icon [icon]="'walking'"></app-icon>
            </div>
            <div class="widget-status">
              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>
              <div *ngIf="!isLoading">
                <div class="widget-label">
                  <span class="text-secondary-accent widget"
                    ><fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon
                  ></span>
                  <span class="text-dark" *ngIf="weeklyTargets?.walking == 1"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                  <span
                    class="text-dark not-hit"
                    *ngIf="weeklyTargets?.walking != 1"
                    >-</span
                  >
                </div>
                <div class="widget-label">
                  <span class="text-secondary-accent"
                    ><fa-icon [icon]="['fas', 'star']"></fa-icon
                  ></span>
                  <span
                    class="text-dark not-set"
                    *ngIf="goalStatus?.walking === 0"
                    >
                    {{ 'NOT SET' | translate }}</span
                  >
                  <span
                    class="text-dark not-hit"
                    *ngIf="goalStatus?.walking === 1"
                    >-</span
                  >
                  <span class="text-dark" *ngIf="goalStatus?.walking === 2"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer bg-secondary">
          <a [routerLink]="['/walking']" class="card-link stretched-link"
            >
            {{ 'UPDATE' | translate }}</a
          >
        </div>
      </div>
    </div>

    <div class="col-6 col-lg-3 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title">
            {{ 'STRENGTH' | translate }}</h2>
          <div class="widget-content">
            <div class="icon-wrap">
              <app-icon [icon]="'strength'"></app-icon>
            </div>
            <div class="widget-status">
              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>
              <div *ngIf="!isLoading">
                <div class="widget-label">
                  <span class="text-secondary-accent widget"
                    ><fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon
                  ></span>
                  <span class="text-dark" *ngIf="weeklyTargets?.strength == 1"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                  <span
                    class="text-dark not-hit"
                    *ngIf="weeklyTargets?.strength != 1"
                    >-</span
                  >
                </div>
                <div class="widget-label">
                  <span class="text-secondary-accent"
                    ><fa-icon [icon]="['fas', 'star']"></fa-icon
                  ></span>
                  <span
                    class="text-dark not-set"
                    *ngIf="goalStatus?.strength === 0"
                    >
                    {{ 'NOT SET' | translate }}</span
                  >
                  <span
                    class="text-dark not-hit"
                    *ngIf="goalStatus?.strength === 1"
                    >-</span
                  >
                  <span class="text-dark" *ngIf="goalStatus?.strength === 2"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer bg-secondary">
          <a [routerLink]="['/strength']" class="card-link stretched-link"
            >
            {{ 'UPDATE' | translate }}</a
          >
        </div>
      </div>
    </div>

    <div class="col-6 col-lg-3 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title">
            {{ 'YOGA' | translate }}</h2>
          <div class="widget-content">
            <div class="icon-wrap">
              <app-icon [icon]="'yoga'"></app-icon>
            </div>
            <div class="widget-status">
              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>
              <div *ngIf="!isLoading">
                <div class="widget-label">
                  <span class="text-secondary-accent widget"
                    ><fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon
                  ></span>
                  <span class="text-dark" *ngIf="weeklyTargets?.yoga == 1"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                  <span
                    class="text-dark not-hit"
                    *ngIf="weeklyTargets?.yoga != 1"
                    >-</span
                  >
                </div>
                <div class="widget-label">
                  <span class="text-secondary-accent"
                    ><fa-icon [icon]="['fas', 'star']"></fa-icon
                  ></span>
                  <span class="text-dark not-set" *ngIf="goalStatus?.yoga === 0"
                    >
                    {{ 'NOT SET' | translate }}</span
                  >
                  <span class="text-dark not-hit" *ngIf="goalStatus?.yoga === 1"
                    >-</span
                  >
                  <span class="text-dark" *ngIf="goalStatus?.yoga === 2"
                    ><fa-icon [icon]="['fas', 'check']"></fa-icon
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer bg-secondary">
          <a [routerLink]="['/yoga']" class="card-link stretched-link"
            >
            {{ 'UPDATE' | translate }}</a
          >
        </div>
      </div>
    </div>

    <div class="col-6 col-lg-3 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title">
            {{ 'MANAGING' | translate }}</h2>
          <div class="widget-content">
            <div class="icon-wrap">
              <app-icon [icon]="'symptoms'"></app-icon>
            </div>
            <div class="widget-status">
              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>
              <div *ngIf="!isLoading">
                <div class="widget-label">
                  <span class="symptom-status-icon text-danger">
                    <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
                  </span>
                  <span class="text-primary" *ngIf="normalSymptoms">
                    <strong *ngIf="userSymptoms.length > 0">{{
                      userSymptoms[0].total_up
                    }}</strong>
                    <span *ngIf="userSymptoms.length < 1" class="not-hit"
                      >-</span
                    >
                  </span>
                  <span class="text-primary" *ngIf="!normalSymptoms">
                    <span class="not-hit">-</span>
                  </span>
                </div>
                <div class="widget-label">
                  <span class="symptom-status-icon text-warning">
                    <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
                  </span>
                  <span class="text-primary" *ngIf="normalSymptoms">
                    <strong *ngIf="userSymptoms.length > 0">{{
                      userSymptoms[0].total_down
                    }}</strong>
                    <span *ngIf="userSymptoms.length < 1" class="not-hit"
                      >-</span
                    >
                  </span>
                  <span class="text-primary" *ngIf="!normalSymptoms">
                    <span class="not-hit">-</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer bg-secondary">
          <a
            [routerLink]="['/managing', 'update-symptoms']"
            class="card-link stretched-link"
            *ngIf="normalSymptoms"
            >
            {{ 'UPDATE' | translate }}</a
          ><a
            [routerLink]="['/managing', 'set-normal-symptoms']"
            class="card-link stretched-link"
            *ngIf="!normalSymptoms"
            >
            {{ 'SET USUAL SYMPTOMS' | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col">
      <div class="card bg-light">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'tasks']"></fa-icon>
            </span>
            
            {{ 'TASK LIST' | translate }}
          </h2>

          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
            <tabset [justified]="true">
              <tab>
                <ng-template tabHeading>
                  
                {{ 'ALL' | translate }} <span class="badge">{{ '' + weeklyTasks?.length | translate }}</span>
                </ng-template>
                <ul class="task-list task-list-todo">
                  <li *ngFor="let task of weeklyTasks">
                    <a
                      class="task"
                      [routerLink]="task.url"
                      [ngClass]="{ completed: task.status === 1 }"
                    >
                      <span class="num">
                        <span class="icon-wrap">
                          <app-icon [icon]="task.icon"></app-icon>
                        </span>
                      </span>
                      <span class="title"
                        >{{ task.area | uppercase | translate }}: {{ task.title | uppercase | translate }}</span
                      >
                      <span class="status">
                        <span class="icon-wrap" *ngIf="task.status === 1">
                          <fa-icon [icon]="['fas', 'check']"></fa-icon>
                        </span>
                        <span
                          class="icon-wrap text-secondary-accent"
                          *ngIf="task.status === 0"
                        >
                          <fa-icon
                            [icon]="['fas', 'arrow-alt-right']"
                          ></fa-icon>
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
              </tab>
              <tab>
                <ng-template tabHeading>
                  
                {{ 'OPEN' | translate }}
                  <span class="badge">{{ '' + openTasks().length | translate  }}</span>
                </ng-template>
                <ul class="task-list task-list-todo">
                  <li *ngFor="let task of openTasks()">
                    <a
                      class="task"
                      [routerLink]="task.url"
                      [ngClass]="{ completed: task.status === 1 }"
                    >
                      <span class="num">
                        <span class="icon-wrap">
                          <app-icon [icon]="task.icon"></app-icon>
                        </span>
                      </span>
                      <span class="title"
                        >{{ task.area | uppercase | translate }}: {{ task.title | uppercase | translate }}</span
                      >
                      <span class="status">
                        <span class="icon-wrap" *ngIf="task.status === 1">
                          <fa-icon [icon]="['fas', 'check']"></fa-icon>
                        </span>
                        <span
                          class="icon-wrap text-secondary-accent"
                          *ngIf="task.status === 0"
                        >
                          <fa-icon
                            [icon]="['fas', 'arrow-alt-right']"
                          ></fa-icon>
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
              </tab>
              <tab>
                <ng-template tabHeading>
                  
                {{ 'COMPLETE' | translate }}
                  <span class="badge">{{ '' + completeTasks().length  | translate }}</span>
                </ng-template>
                <ul class="task-list task-list-todo">
                  <li *ngFor="let task of completeTasks()">
                    <a
                      class="task"
                      [routerLink]="task.url"
                      [ngClass]="{ completed: task.status === 1 }"
                    >
                      <span class="num">
                        <span class="icon-wrap">
                          <app-icon [icon]="task.icon"></app-icon>
                        </span>
                      </span>
                      <span class="title"
                        >{{ task.area | uppercase | translate }}: {{ task.title | uppercase | translate }}</span
                      >
                      <span class="status">
                        <span class="icon-wrap" *ngIf="task.status === 1">
                          <fa-icon [icon]="['fas', 'check']"></fa-icon>
                        </span>
                        <span
                          class="icon-wrap text-secondary-accent"
                          *ngIf="task.status === 0"
                        >
                          <fa-icon
                            [icon]="['fas', 'arrow-alt-right']"
                          ></fa-icon>
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
              </tab>
            </tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card bg-primary-accent">
        <a style="text-decoration: none;" [routerLink]="['/achievements']">
          <div class="card-body">
            <h2 class="card-title text-start">
              <span class="icon-wrap text-secondary-accent">
                <fa-icon [icon]="['fas', 'badge-check']"></fa-icon>
              </span>
              <span class="float-end">
                  <span class="icon-wrap text-secondary-accent icon-circle">
                    <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon> </span
                >
              </span>
              
              {{ 'ACHIEVEMENTS' | translate }}
            </h2>
            <div *ngIf="isLoading" class="text-center">
              <app-loading-spinner [inline]="true"></app-loading-spinner>
            </div>
            <div *ngIf="!isLoading">
              <div *ngIf="topAchievements">
                <p *ngIf="topAchievements.length < 1">
                  {{ 'NO ACHIEVEMENTS YET' | translate }}</p>
              </div>
              <div class="achievements">
                <app-achievement-badge
                  *ngFor="let achievement of topAchievements"
                  [achievement]="achievement"
                ></app-achievement-badge>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>