import { DOCUMENT } from "@angular/common";
import { Component, OnInit, Input, Inject } from "@angular/core";

@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
})
export class PageHeaderComponent implements OnInit {
  @Input() title: any;
  @Input() icon: any;
  @Input() backLink: any; 
  currentPosition: any;
  scrollingUp = false;
  stickyHeader = false;

  constructor(@Inject(DOCUMENT) private _document: Document) {
    this._document.addEventListener("scroll", this.onContentScrolled);
  }

  ngOnInit(): void {}

  onContentScrolled = (e: any) => {
    let scroll = window.pageYOffset;
    if(scroll> 1){
      this.stickyHeader = true;
    }
    else{
      this.stickyHeader = false;
    }
    if (scroll > this.currentPosition) {
      this.scrollingUp = false;
    } else {
      this.scrollingUp = true;
    }
    this.currentPosition = scroll;
  };
}
