<div class="bg-section"></div>
<img
  src="/assets/img/bg-walking.jpg"
  alt="placeholder photo"
  class="img-sticky d-block d-md-none"
/>
<div class="content-box fh">
  <h1>
    <span class="icon-wrap icon-heading-walking">
      <app-icon [icon]="'walking'"></app-icon>
    </span>
    {{ 'WALKING' | translate }}
  </h1>

  <div class="row mb-3">
    <div class="col-12 col-md-6 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
            </span>
            <span class="float-end">
              <a [routerLink]="['/walking', 'workouts']">
                <span class="icon-wrap text-secondary-accent icon-circle">
                  <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon> </span
              ></a>
            </span>
            {{ 'WORKOUTS' | translate }}
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
            <div class="swiper-slide" *ngIf="!currentTarget"><span *ngIf="currentLang =='en'">Please set a walking target to begin</span><span *ngIf="currentLang !='en'">{{ 'Set walking target' | uppercase | translate }}</span></div>
            <div *ngIf="currentTarget">
              <p>
                {{ 'WALK FOR X MINUTES ON Y DAYS OF THIS WEEK' | translate: { 'minutes':weeklyTarget.threshold, 'days':weeklyTarget.days} | convertDigits}}
              </p>

              <div class="entry-days mb-4">
                <div class="entry-day" *ngFor="let day of currentWeekData">
                  <div class="entry-day-label">
                    <span *ngIf="currentLang == 'en'">{{ day.date | localDate: "EE" | slice: 0:1 }}</span>
                    <span *ngIf="currentLang !== 'en'">{{ day.date | localDate: "EE" }}</span>
                  </div>
                  <div class="entry-day-data na" *ngIf="day.date > today"></div>
                  <div
                    class="entry-day-data"
                    *ngIf="day.date <= today"
                    [ngClass]="{
                      complete: +day.minutes >= +weeklyTarget.threshold
                    }"
                  >
                    <div class="entry-day-data-value" *ngIf="day.minutes > 0">
                      {{ day.minutes | convertDigits}}
                    </div>
                    <div class="entry-day-data-unit" *ngIf="day.minutes > 0">
                      {{ 'MINS' | translate }}
                    </div>
                    <div
                      class="entry-day-data-value"
                      *ngIf="day.minutes == 0 && day.date !== today"
                      [routerLink]="['/walking', 'add-workout']"
                      [queryParams]="{d: day.date}"
                    >
                      -
                    </div>

                    <div
                      class="entry-day-data-value relative"
                      *ngIf="day.minutes == 0 && day.date === today"
                    >
                      <a
                        [routerLink]="['/walking', 'add-workout']"
                        class="stretched-link"
                        ><span class="icon-wrap text-secondary-accent">
                          <fa-icon
                            [icon]="['fas', 'pencil-alt']"
                          ></fa-icon> </span
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <p class="subtext" *ngIf="+daysHit < weeklyTarget.days">

                {{ 'YOU HAVE COMPLETED X DAYS TOWARDS YOUR TARGET THIS WEEK.' | translate : {days: daysHit}| convertDigits }}
                <!--You have completed
                <strong
                  >{{ daysHit }} day<ng-container *ngIf="daysHit != 1"
                    >s</ng-container
                  ></strong
              
                towards your target this week.-->
              </p>
              <p class="subtext" *ngIf="+daysHit >= weeklyTarget.days">
                {{ 'YOU HAVE HIT YOUR WALKING TARGET THIS WEEK!' | translate }}
              </p>
            </div>
          </div>
        </div>

        <div class="card-footer bg-secondary relative">
          <a
            [routerLink]="['/walking', 'add-workout']"
            class="card-link stretched-link"
            *ngIf="currentTarget"
            >
            {{ 'ADD A WALKING ENTRY' | translate }}</a
          >
          <a
            [routerLink]="['/walking', 'set-target']"
            class="card-link stretched-link"
            *ngIf="!currentTarget"
            >{{ 'SET A WALKING TARGET' | translate }}</a
          >
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'star']"></fa-icon>
            </span>
            <span class="float-end">
              <a [routerLink]="['/walking', 'goals']">
                <span class="icon-wrap text-secondary-accent icon-circle">
                  <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
                </span>
              </a>
            </span>
            {{ 'GOALS' | translate }}
          </h2>

          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
            <swiper
              [config]="config"
              (swiper)="onSwiper($event)"
              (slideChange)="onSlideChange()"
              *ngIf="openGoals.length > 0"
            >
              <ng-template swiperSlide *ngFor="let goal of openGoals">
                <div class="goal-content">
                  <a class="goal-checkbox" (click)="onCompleteGoal(goal)">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </span>
                  </a>
                  <div class="goal-text">
                    {{ goal.goal | uppercase | translate  }}
                  </div>
                </div>
              </ng-template>
            </swiper>
            <div
              class="swiper-slide"
              *ngIf="openGoals.length === 0 && allGoals.length > 0"
            >
            {{ 'NO GOALS CURRENTLY OPEN. PLEASE ADD A NEW ONE.' | translate }}
            </div>
            <div class="swiper-slide" *ngIf="allGoals.length === 0">
            {{ 'PLEASE SET A GOAL TO BEGIN.' | translate }}
            </div>
          </div>
        </div>
        <div class="bg-white text-center" *ngIf="allGoals.length > 0">
          <div class="row goal-status-row">
            <div class="col">
              <div class="goal-status-label">{{ 'OPEN' | translate }}</div>
              <div class="goal-status-value">{{ '' + openGoals.length | translate | convertDigits }}</div>
            </div>
            <div class="col">
              <div class="goal-status-label">{{ 'ACHIEVED' | translate }}</div>
              <div class="goal-status-value">{{ '' + completeGoals.length | translate  | convertDigits }}</div>
            </div>
          </div>
        </div>
        <div class="card-footer bg-secondary relative">
          <a
            [routerLink]="['/walking', 'add-goal']"
            class="card-link stretched-link"
            >{{ 'ADD A GOAL' | translate }}</a
          >
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 mb-4">
      <div class="card bg-light">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'book-reader']"></fa-icon>
            </span>
            {{ 'WALKING GUIDE' | translate }}
          </h2>

          <img
          *ngIf="resource?.image_filename"
          src="{{ resource.image_filename | getThumbnail: 'medium-landscape' }}"
          alt="Placeholder image"
          class="img-fluid rounded-lg mt-2 mb-3"
        />

          <ul class="task-list task-list-todo task-list-bt">
            <li *ngFor="let page of pages">
              <a
                class="task"
                [routerLink]="['/walking', 'guide', page.content_page_id]"
              >
                <span class="num">
                  <span class="icon-wrap">
                    <fa-icon
                      *ngIf="!page.icon"
                      [icon]="['fas', 'align-left']"
                      [fixedWidth]="true"
                    ></fa-icon>
                    <fa-icon
                      *ngIf="page.icon"
                      [icon]="['far', page.icon]"
                      [fixedWidth]="true"
                    ></fa-icon>
                  </span>
                </span>
                <span class="title">{{ page.title }}</span>
                <span class="status">
                  <span class="icon-wrap text-secondary-accent">
                    <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 mb-4">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'badge-check']"></fa-icon>
            </span>
            <span class="float-end">
              <a [routerLink]="['/walking', 'achievements']">
                <span class="icon-wrap text-secondary-accent icon-circle">
                  <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon> </span
              ></a>
            </span>
            {{ 'ACHIEVEMENTS' | translate }}
          </h2>

          <p *ngIf="achievements.length == 0">{{ 'NO ACHIEVEMENTS YET' | translate }}</p>
          <div class="achievements">
            <app-achievement-badge
              *ngFor="let achievement of achievements"
              [achievement]="achievement"
            ></app-achievement-badge>

            <!--
            <div class="achievement">
              <div class="achievement-bg">
                <span class="icon-wrap">
                  <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
                </span>
              </div>
              <div class="achievement-content">
                <div class="achievement-row">
                  <div class="achievement-icon">
                    <app-icon [icon]="'walking'"></app-icon>
                  </div>
                  <div class="achievement-value">7</div>
                </div>
                <div>
                  <div class="achievement-label">DAY<br />STREAK</div>
                </div>
              </div>
            </div>

            <div class="achievement rounded-circle">
              <div class="achievement-bg">
                <span class="icon-wrap">
                  <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
                </span>
              </div>
              <div class="achievement-content">
                <div class="achievement-value">75</div>
                <div class="achievement-label">Workouts</div>
                <div class="achievement-icon">
                  <app-icon [icon]="'walking'"></app-icon>
                </div>
                <div></div>
              </div>
            </div>

            <div class="achievement rounded-circle">
              <div class="achievement-bg">
                <span class="icon-wrap">
                  <fa-icon [icon]="['fas', 'star']"></fa-icon>
                </span>
              </div>
              <div class="achievement-content">
                <div class="achievement-row">
                  <div class="achievement-icon">
                    <app-icon [icon]="'walking'"></app-icon>
                  </div>
                  <div class="achievement-value">10</div>
                </div>
                <div>
                  <div class="achievement-label">Goals</div>
                </div>
              </div>
            </div>

            <div class="achievement">
              <div class="achievement-bg">
                <span class="icon-wrap">
                  <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
                </span>
              </div>
              <div class="achievement-content">
                <div class="achievement-row">
                  <div class="achievement-icon">
                    <app-icon [icon]="'walking'"></app-icon>
                  </div>
                  <div class="achievement-value">5</div>
                </div>
                <div>
                  <div class="achievement-label">DAY<br />STREAK</div>
                </div>
              </div>
            </div>

          --></div>
        </div>
      </div>
    </div>
  </div>
</div>
