import { ComponentCanDeactivate } from './../../guards/pending-changes.guard';
import { Goal } from './../../models/goal.model';
import { forkJoin, Observable } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";
import { GoalService } from "./../../services/goal.service";
import { NgForm } from "@angular/forms";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { AchievementService } from 'src/app/services/achievement.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-edit-goal",
  templateUrl: "./edit-goal.component.html",
  styleUrls: ["./edit-goal.component.scss"],
})
export class EditGoalComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  area = "walking";
  entryId!: number;
  isLoading = false;
  userGoal!:Goal;
  public errors: any = [];

  constructor(
    private goalService: GoalService,
    private router: Router,
    private route: ActivatedRoute,
    private achievementService: AchievementService,
    private translate: TranslateService
  ) {
    if (this.route.snapshot.data["area"]) {
      this.area = this.route.snapshot.data["area"];
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.entryId = params.id;
      this.isLoading = true;
      forkJoin({
        userGoal: this.goalService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.userGoal = responseData.userGoal;
          this.userGoal.goal = this.translate.instant(this.userGoal.goal.toUpperCase());
        },
        (error) => {
          this.errors.push(error.message);
        }
      );
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: this.translate.instant("THIS FORM IS INVALID"),
        text: this.translate.instant("PLEASE CHECK THE FORM FOR ERRORS"),
        showConfirmButton: true,
      });
    } else {
    // create entry
    const resourceIds = [];
    this.goalService.update(this.entryId, this.area, this.entryForm?.value.status, this.entryForm?.value.goal).subscribe(
      (responseData) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: this.translate.instant("GOAL UPDATED"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
        this.router.navigate(["/" + this.area, 'goals']);
        this.entryForm?.reset();
        this.achievementService.refreshNotifications();
      },
      (error) => {
        this.error = error.message;
        this.entryForm?.reset();
      }
    );
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if(this.submitted){
      return true;
    }
  }
}
