import { SiteContentService } from './../../services/site-content.service';
import { environment } from './../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { ResourcesService } from "./../../services/resources.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-site-content-page",
  templateUrl: "./site-content-page.component.html",
  styleUrls: ["./site-content-page.component.scss"],
})
export class SiteContentPageComponent implements OnInit {
  pageId!: number;
  page: any;
  isLoading = false;
  fragment: any;
  moreSection = false;

  constructor(
    private route: ActivatedRoute,
    private siteContentService: SiteContentService,
    private titleService:Title,
    private router:Router
  ) {
    if (this.route.snapshot.data["pageId"]) {
      this.pageId = this.route.snapshot.data["pageId"];
      this.moreSection = this.route.snapshot.data["moreSection"];
    }
  }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
      this.isLoading = true;
      this.siteContentService.fetchPage(this.pageId).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.page = responseData;

          // do we need to scroll?
          setTimeout(() => {
            try {
              if (this.fragment) {
                const element = document.querySelector("#" + this.fragment);
                if (element) {
                  setTimeout(() => {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest",
                    });
                  }, 500);
                }
              }
            } catch (e) {}
          }, 500);
        },
        (error) => {}
      );
  }
  onContentClick(e: MouseEvent) {
    if (
      e.target instanceof HTMLAnchorElement &&
      e.target.host === location.host
    ) {
      e.preventDefault();
      this.router.navigateByUrl(e.target.pathname + e.target.hash);
    }
  }
}
